import { Pagination } from "@ifs/libs/src/shared/models/Pagination";
import axios from "axios";
import { apiUrls } from "shared/apiUrls";

export async function getAdvisorRequest(): Promise<AdvisorResponseModel[]> {
    const response = await axios.get<AdvisorPaginatedResponse>(apiUrls().advisors, {
        params: { limit: 50 }
      });
    return response.data?.results;
}

interface AdvisorPaginatedResponse {
    results: AdvisorResponseModel[];
    pagination: Pagination;
}

export interface AdvisorResponseModel {
    id: string;
    firstName?: string;
    lastName?: string;
}
