import styles from "./MenuButton.module.scss";
import classNames from "classnames";
import { SvgWrapperComponent } from "@ifs/libs";

interface MenuButtonProps {
  text: string;
  asAlert?: boolean;
  icon: any;
  isSelected?: boolean;
  numberOfAppointments?: number;
  onClick: () => void;
}

export default function MenuButton(props: MenuButtonProps) {
  const Icon = props.icon;
  return (
    <div
      className={classNames({
        [styles.MenuButtonSelected]: props.isSelected,
        [styles.MenuButton]: !props.isSelected,
      })}
    >
        <div
          className={styles.MenuButtonGridContainer}
          onClick={() => {
            return props.onClick();
          }}
        >
          <div className={styles.Icon}>
              <SvgWrapperComponent ariaHidden={true}><Icon width="22px" /></SvgWrapperComponent>
          </div>
          <div className={styles.Text}>
            {props.text} 
              <span>&nbsp;</span>
            {props.asAlert && (
              <div className={styles.Alert}>{props.numberOfAppointments}</div>
            )}
          </div>
      </div>
    </div>
  );
}
