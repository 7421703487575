import { TextInput } from '@ifs/libs';
import moment from 'moment';
import styles from './DateAndReason.module.scss';
import { i18n } from '../../../../../i18n';
import { createRef, useEffect, useState } from 'react';
import { EAppointmentMotive } from 'modules/customer-file/enums';
import { handleClickOutsideBox } from 'shared/clickOutsite';

const listOfMotive = Object.keys(EAppointmentMotive).reverse();

interface DateAndReasonProps {
  handleSetDate: (arg0: string) => void;
  valueDate: string;
  handleChangeIsInFuture: (arg0: boolean) => void;

  handleChangeMotive: (arg0: EAppointmentMotive) => void;
  selectedMotive: EAppointmentMotive;

  disabled: boolean;
}

export function DateAndReason(props: DateAndReasonProps) {
  const [selectIsDisplay, changeDisplay] = useState<boolean>(false);

  const theRef: React.RefObject<HTMLDivElement> = createRef();
  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutsideBox(e, theRef, changeDisplay)
    );
    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutsideBox(e, theRef, changeDisplay)
      );
    };
  });

  const notInFuture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentDate = new Date();
    const selectedDate = moment(event.target.value).isAfter(currentDate);

    props.handleSetDate(event.target.value);
    props.handleChangeIsInFuture(selectedDate);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.LabelAndComponent}>
        <span className={styles.Label}>{i18n.customerCRComponent.labels.startMeetingDate}</span>
        <TextInput
          className={styles.DateInput}
          onChange={(e) => {
            notInFuture(e);
          }}
          value={props.valueDate}
          type="date"
          disabled={props.disabled}
          placeholder={i18n.customerCRComponent.labels.start}
        />
      </div>

      <div className={styles.LabelAndComponent} ref={theRef}>
        <span className={styles.Label}>{i18n.customerCRComponent.labels.reasonMeeting}</span>
        <div className={styles.Select}>
          <span
            className={styles.SelectedReason}
            onClick={() => {
              !props.disabled && changeDisplay(!selectIsDisplay);
            }}
          >
            {i18n.customerCRComponent.listOfMotive[props.selectedMotive]}
          </span>
          {selectIsDisplay && (
            <div className={styles.StatutChangeBox}>
              {listOfMotive.map((e: string, index: number) => (
                <span
                  key={index}
                  className={styles.Motive}
                  onClick={() => {
                    props.handleChangeMotive(e as EAppointmentMotive);
                    changeDisplay(!selectIsDisplay);
                  }}
                >
                  {i18n.customerCRComponent.listOfMotive[e as EAppointmentMotive]}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
