import React, { useEffect } from "react";
import { PageWithMenu } from "shared";
import { i18n } from "modules/advisor-course/i18n"; 
import { i18n as sharedI18n } from "shared/i18n";
import styles from "./CustomerCreation.module.scss";
import { CustomerCreationForm } from "./components/CustomerCreationForm/CustomerCreationForm";

export function CustomerCreation() {
  return (
    <PageWithMenu reloadCounterCustomer>
      <div className={styles.Root}>
        <div className={styles.Header}>
            <div>
            <span className={styles.TitleBold}>{sharedI18n.appName}</span>
            <span className={styles.TitleLight}>{i18n.CustomerCreationPage.newCustomer}</span>
            </div>        
        </div>        
        <CustomerCreationForm/>     
      </div>
    </PageWithMenu>
  );
}
