import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  taxationReducer,
  legalPensionReducer,
  pensionNeedReducer,
  dashboardReducer,
  progressReducer,
  patrimonyReducer,
  lifeProjectsReducer,
  recommendationReducer,
  investorProfileReducer,
  tenantReducer,
  loaderReducer
} from "@ifs/libs";
import { RootState } from '@ifs/libs/src/store';
import { authenticationReducer, AuthenticationState } from 'shared/authenticationSlice';

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('advistorState');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('advistorState', serializedState);
  } catch (error) {
    console.log(error)
  }
};

export interface AppRootState extends RootState {
  authentication: AuthenticationState
}

export const store = configureStore<AppRootState>({
  reducer: (state, action) => {
    return combineReducers({
      loader: loaderReducer,
      progress: progressReducer,
      pensionNeed: pensionNeedReducer,
      legalPension: legalPensionReducer,
      taxation: taxationReducer,
      patrimony: patrimonyReducer,
      lifeProjects: lifeProjectsReducer,
      recommendation: recommendationReducer,
      dashboard: dashboardReducer,
      authentication: authenticationReducer,
      tenant: tenantReducer,
      investorProfile: investorProfileReducer
    })(state, action);
  },
  preloadedState: loadState()
});

store.subscribe(() => saveState(store.getState()));

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector;
