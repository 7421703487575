import { useLogoutCommand } from "@ifs/libs";
import { logoutUserRequest } from "../requests/logout";
import { useNavigate } from "react-router-dom";
import { useTenantConfigCommand } from "modules/auth/commands/tenantConfigCommand";

export function useLogoutUserCommand() {
  const navigate = useNavigate();
  const { bffLogoutCommand } = useLogoutCommand();
  const { isTenantWithAuthenticationModeSSO } = useTenantConfigCommand();

  async function handleLogoutCommand(reason: string) {
    if(isTenantWithAuthenticationModeSSO()) {
      bffLogoutCommand();
    } else {
      logoutUserCommand(reason);
    }
  }

  async function logoutUserCommand(reason: string) {

    await logoutUserRequest(reason)
    sessionStorage.removeItem("identifier");
    sessionStorage.removeItem("username");
    navigate("/auth/login");
  }

  return { handleLogoutCommand, logoutUserCommand };
}
