import { Footer , Header as WebAppHeader} from "@ifs/libs/src/shared";
import Header from "modules/advisor-course/components/Header/Header";
import { Outlet } from "react-router-dom";
import styles from "./SimulationFlowLayout.module.scss";

export function SimulationFlowLayout(props: any){

    return (
        <div className={styles.Container}>
            <Header />
            <div className={styles.Container}>

                <WebAppHeader/>
                <div className={styles.RouterOutletContainer}>
                    <Outlet />
                </div>
                <Footer/>
            </div>
        </div>
    )
}