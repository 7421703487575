import { useTenantConfigCommand } from "modules/auth/commands/tenantConfigCommand";
import { AdvisorResponseModel } from "shared/request/getAdvisorRequest";
import { globali18n } from 'i18n';

export function useGetAdvisorNameCommand() {
  const { isTenantWithAuthenticationModeSSO } = useTenantConfigCommand();

  function getAdvisorName(advisor: AdvisorResponseModel): string {
    if(isTenantWithAuthenticationModeSSO()) {
      return `${globali18n.labels.advisor} ${advisor?.id}`
    }
    return `${advisor?.firstName} ${advisor?.lastName}`;
  }

  return {
    getAdvisorName
  }
}