import { useEffect } from 'react';
import styles from './AuthenticatePage.module.scss';
import { Footer } from 'shared/Footer/Footer';
import { useAuthenticateSSOCommand } from '@ifs/libs/src/shared';

export function AuthenticatePage() {  
    const { redirectToAuthenticateSSO } = useAuthenticateSSOCommand();

  useEffect(() => { 
    redirectToAuthenticateSSO();
  }, [])

  return (
    <div className={styles.LoginContainer}>
      <div className={styles.Header}></div>    
      <div className={styles.Body}></div>
      <Footer />
    </div>
  );
}
