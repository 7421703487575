import { GlobalI18nType } from 'i18n';
import { AppointmentConditionType } from 'multi-tenant/Models/TypeofElementCondition/AppointmentCondition.model';
import { CustomerCreationFormConditionType } from 'multi-tenant/Models/TypeofElementCondition/CustomerCreationFormCondition.model';
import { CookiesI18nType } from 'multi-tenant/Models/TypeofI18n/CookiesI18n.model';
import { ClientInformationConditionType } from '../../multi-tenant/Models/TypeofElementCondition/ClientInformationCondition.model';
import { AppointmentI18nType } from '../../multi-tenant/Models/TypeofI18n/AppointmentI18n.model';
import { TenantModel } from '../Models/Tenant.model';
import { LogoIcon as MenuLogoIcon } from './assets';
import { i18n as CookiesI18n } from './cookiesI18n';

export const matmut: TenantModel = {
  name: 'bnppf',
  cssVar: {
    '--advisor-primary-green': '#00539A',
    '--advisor-primary-green-box-shadow': '',
    '--advisor-primary-green_10': '#F4F4F4',
    '--advisor-primary-green_15': '#FFFFFF',
    '--advisor-primary-green_20': '#D2F0C7',
    '--advisor-primary-green_50': '#D2F0C7',
    '--advisor-secondary-orange': '#468230',
    '--advisor-secondary-moutarde': '#468230',
    '--advisor-secondary-moutarde_10': '#FDF7E6',
    '--advisor-secondary-turquoise': '#28C3A9',
    '--advisor-secondary-turquoise_10': '#EAF9F7',
    '--advisor-secondary-aubergine': '#A33467',
    '--advisor-secondary-aubergine_10': '#F6EBF0',
    '--advisor-secondary-ruddy_brown': '#bc5127',
    '--advisor-app-background': '#F4F4F4'
  },
  assets: {
    logo: (assets: any) => {
      return (assets.MenuLogoIcon = MenuLogoIcon);
    }
  },
  i18n: {
    appointment: (i18n: AppointmentI18nType) => {
      return (i18n.customerAppointmentBodyComponent.labels.appointmentDate = 'Demande de rappel');
    },
    global: (i18n: GlobalI18nType) => {
      return (i18n.title = 'Ma vie ma retraite | Espace conseiller');
    },
    customerFile: (i18n: any) => {
      return (i18n.customerInformationCard.labels.historicMeeting =
        'Historique des demandes de rappel',
        i18n.customerAddEventsComponent.title = 'Bloc Note');
    },
    advisorCookies: (i18n: CookiesI18nType) => {
      return (
        (i18n.headerCookies = CookiesI18n.headerCookies),
        (i18n.whatIsACookie = CookiesI18n.whatIsACookie),
        (i18n.typeOfInformationStored = CookiesI18n.typeOfInformationStored),
        (i18n.typeOfUsage = CookiesI18n.typeOfUsage),
        (i18n.hotJar = CookiesI18n.hotJar)
      );
    },
    authModule: (i18n: any) => {
      return (
        (i18n.loginPage.subTitle =
          "Identifiez-vous afin d'accéder à l'espace conseiller Ma vie ma retraite"),
        (i18n.registerPage.subTitle = 'Initiez votre espace conseiller Ma vie ma retraite')
      );
    }
  },
  displayed: {
    appointment: (condition: AppointmentConditionType) => {
      return (
        (condition.displayDesiredSlot = false), (condition.displayIsBnpClientTableHeader = false)
      );
    },
    clientInformation: (condition: ClientInformationConditionType) => {
      return (
        (condition.displayMeetingStatus = false), (condition.displayIsBnpClientInformation = false)
      );
    },
    customerCreationForm: (condition: CustomerCreationFormConditionType) => {
      return (condition.displayIsBnpClientInformation = false);
    }
  }
};
