import { createRef, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StatutButton } from '../StatutButton/StatutButton';
import { AdvisorButton } from '../AdvisorButton/AdvisorButton';
import styles from './CustomerAppointmentTile.module.scss';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { ModalContext } from 'context/modalContext';
import { i18n } from 'modules/appointments/i18n';
import { condition } from 'modules/appointments/Displayed';
import { TimeSlotModel } from 'shared/models/TimeSlot.model';
import { getTimeSlotValue } from 'Utils/GetTimeSlotValue';
import classNames from 'classnames';
import { CustomerStatus } from 'models/customer-status';
import { AssignCustomerModal } from './components/AssignCustomerModal';
import { Button } from '@ifs/libs';
import { EAppointmentStatus } from 'modules/appointments/enums';

export default function CustomerAppointmentTile(props: {
  firstName: string | undefined;
  lastName: string | undefined;
  isBnppClient: string | undefined;
  statut: CustomerStatus;
  monthlyPayment: string | undefined;
  initialValue: string | undefined;
  appointmentDate: string | undefined;
  appointmentHours: TimeSlotModel | undefined;
  advisor: string | undefined;
  advisorLastName: string | undefined;
  advisorFirstName: string | undefined;
  unAssignCustomer: any;
  attributeCustomer: any;
  customerId: string | undefined;
  appointmentId: string | undefined;
  changeStatut: (customerId: string, appointmentId: string, newStatut: EAppointmentStatus) => Promise<any>;
  key: number;
  isLastElement: boolean;
}) {
  let navigate = useNavigate();
  let { handleModal } = useContext(ModalContext);
  // Need this tricky to keep a good table render
  // For some tenants, we dont need all the table header
  // It create some visual shift
  let [nbTableCells, setNbTableCells] = useState<number>(9);
  const tableBodyRef: React.RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    if (tableBodyRef && tableBodyRef.current) {
      setNbTableCells(tableBodyRef.current.children.length - 1);
    }
  }, [tableBodyRef]);

  const handleDisplayModal = (type: string) => {
    const preTitle = i18n.customerAppointmentTileComponent;
    const title = type === 'unassign' ? preTitle.titleUnassign : preTitle.titleAssign;
    const event = type === 'unassign' ? 'unassigned' : 'assigned';
    const action = type === 'unassign' ? props.unAssignCustomer : props.attributeCustomer;
    const data = {
      title: title,
      lastName: props.lastName!,
      name: props.firstName!,
      event: event,
      customerId: props.customerId!,
      action: action,
      advisor: props.advisor!,
      handleModal: handleModal
    };
    return handleModal(<AssignCustomerModal {...data} />);
  };

  return (
    <div
      ref={tableBodyRef}
      style={{ gridTemplateColumns: `repeat(${nbTableCells}, minmax(80px, 1fr)) 150px` }}
      className={styles.CustomerAppointmentTileContainer}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/customer-file?customerid=${props.customerId}`);
      }}
    >
      <div className={styles.CustomerName}>{props.firstName}</div>
      <div className={styles.CustomerName}>{props.lastName}</div>
      {condition.displayIsBnpClientTableHeader && (
        <div className={styles.SimpleText}>{props.isBnppClient}</div>
      )}
      <StatutButton
        statut={props.statut}
        customerId={props.customerId}
        appointmentId={props.appointmentId}
        changeStatut={props.changeStatut}
        name={props.firstName}
        lastname={props.lastName}
        isLastElement={props.isLastElement}
      />
      <div>{props.initialValue}</div>
      <div>{props.monthlyPayment}</div>
      <div>{props.appointmentDate}</div>
      {condition.displayDesiredSlot && props.appointmentHours && (
        <div>{getTimeSlotValue(props.appointmentHours)}</div>
      )}

      {props.advisor !== undefined ? (
        <AdvisorButton
          advisorFirstName={props.advisorFirstName}
          advisorLastName={props.advisorLastName}
          unAssignCustomer={() => handleDisplayModal('unassign')}
        />
      ) : (
        <Button
          onClick={(e: any) => {
            e.stopPropagation();
            handleDisplayModal('assign');
          }}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
        >
          {i18n.customerAppointmentTileComponent.labels.customerAssignment}
        </Button>
      )}
    </div>
  );
}
