import { axiosRequestValidateStatusConfig } from '@ifs/libs/src/shared/axiosValidateStatusConfig';
import axios from 'axios';
import { apiUrls } from 'shared/apiUrls';
import {
  AdvisorCustomerActionParams,
  AdvisorCustomerHistoryPagination
} from '../models/advisorCustomerAction';

export async function getAdvisorCustomerHistoryRequest(
  params: AdvisorCustomerActionParams
): Promise<AdvisorCustomerHistoryPagination| null> {
  const response = await axios.get(apiUrls(params.customerId).advisorCustomerActions.get, {
    params: {
      ...params,
      sortBy: 'CreatedAt Desc'
    },
    ...axiosRequestValidateStatusConfig
  });

  if ((response.status === 204, response.status === 404)) {
    return null;
  }

  return response.data;
}
