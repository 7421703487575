import { MeetingIcon } from 'shared/assets';
import styles from './EventBloc.module.scss';
import { i18n } from '../../../../i18n';
import moment from 'moment';
import { ReactComponent as LaunchJourneyIcon } from '../../assets/LaunchJourney.svg';
import { EAdvisorCustomerActiontype } from '../../AdvisorCustomerActionType.enum';

interface EventBlocProps {
  event: EAdvisorCustomerActiontype;
  advisorName: string;
  eventDate: Date | string;
}

const t = i18n.customerHistoryEvents.labels;

export default function EventBloc(props: EventBlocProps) {
  const eventDate = `${moment(props.eventDate).format('DD/MM/YYYY')} ${moment(props.eventDate)
    .format('HH:mm')
    .replace(':', 'H')}`;

  const event = {
    [EAdvisorCustomerActiontype.Assignment]: {
      icon: MeetingIcon,
      eventLabel: t.assigned,
      textType: t.customer
    },
    [EAdvisorCustomerActiontype.Unassignment]: {
      icon: MeetingIcon,
      eventLabel: t.unassigned,
      textType: t.customer
    },
    [EAdvisorCustomerActiontype.LaunchJourney]: {
      icon: LaunchJourneyIcon,
      eventLabel: t.startA,
      textType: t.customerFlow
    },
    [EAdvisorCustomerActiontype.AppointmentReport]: {
      icon: LaunchJourneyIcon,
      eventLabel: t.unassigned,
      textType: t.customerFlow
    },
    [EAdvisorCustomerActiontype.Remark]: {
      icon: LaunchJourneyIcon,
      eventLabel: t.unassigned,
      textType: t.customerFlow
    }
  };

  const renderPrefixEventLabel = props.event !== EAdvisorCustomerActiontype.LaunchJourney;

  const toDisplay =
    props.event === EAdvisorCustomerActiontype.AppointmentReport ||
    props.event === EAdvisorCustomerActiontype.Remark
      ? false
      : true;

  const Icon = event[props.event].icon;
  return (
    toDisplay ? (
      <div>
        <div className={styles.AssignCustomer}>
          <Icon />
          <span>
            <span className={styles.AdvisorName}>{props.advisorName}</span> {renderPrefixEventLabel && t.its}{' '}
            <span className={styles.BoldGreen}>{event[props.event].eventLabel}</span>{' '}
            {event[props.event].textType}
            <br />
            <span className={styles.Date}>{eventDate}</span>
          </span>
        </div>
        <div className={styles.Divider}></div>
      </div>
    ) : <></>
  );
}
