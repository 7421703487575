import { GlobalI18nType } from 'i18n';
import { AppointmentConditionType } from 'multi-tenant/Models/TypeofElementCondition/AppointmentCondition.model';
import { ClientInformationConditionType } from 'multi-tenant/Models/TypeofElementCondition/ClientInformationCondition.model';
import { CustomerCreationFormConditionType } from 'multi-tenant/Models/TypeofElementCondition/CustomerCreationFormCondition.model';
import { AppointmentI18nType } from 'multi-tenant/Models/TypeofI18n/AppointmentI18n.model';
import { CookiesI18nType } from 'multi-tenant/Models/TypeofI18n/CookiesI18n.model';
import { TenantModel } from '../Models/Tenant.model';
import { LogoIcon as MenuLogoIcon } from './assets';
import { i18n as CookiesI18n } from './cookiesI18n';

export const bcefRetail: TenantModel = {
  name: 'bnppf',
  cssVar: {
    '--advisor-primary-green': '#00965D',
    '--advisor-primary-green-box-shadow': '',
    '--advisor-primary-green_10': '#F4F4F4',
    '--advisor-primary-green_15': '#FFFFFF',
    '--advisor-primary-green_20': '#DEEDFD',
    '--advisor-primary-green_50': '#77B69E',
    '--advisor-secondary-orange': '#0E6ECE',
    '--advisor-secondary-moutarde': '#0E6ECE',
    '--advisor-secondary-moutarde_10': '#FDF7E6',
    '--advisor-secondary-turquoise': '#28C3A9',
    '--advisor-secondary-turquoise_10': '#EAF9F7',
    '--advisor-secondary-aubergine': '#A33467',
    '--advisor-secondary-aubergine_10': '#F6EBF0',
    '--advisor-secondary-ruddy_brown': '#bc5127',
    '--advisor-app-background': '#F4F4F4'
  },
  assets: {
    logo: (assets: any) => {
      return (assets.MenuLogoIcon = MenuLogoIcon);
    }
  },
  i18n: {
    appointment: (i18n: AppointmentI18nType) => i18n,
    global: (i18n: GlobalI18nType) => i18n,
    customerFile: (i18n: any) => {
      return (
        i18n.customerFilePage.labels.advisorFlow = "Diagnostic retraite",
        i18n.customerFilePage.labels.customerCard = "Fiche Client MonDemain"
      )
    },
    advisorCookies: (i18n: CookiesI18nType) => {
      return (
        (i18n.headerCookies = CookiesI18n.headerCookies),
        (i18n.whatIsACookie = CookiesI18n.whatIsACookie),
        (i18n.typeOfInformationStored = CookiesI18n.typeOfInformationStored),
        (i18n.typeOfUsage = CookiesI18n.typeOfUsage),
        (i18n.hotJar = CookiesI18n.hotJar)
      );
    },
    authModule: (i18n: any) => i18n
  },
  displayed: {
    appointment: (condition: AppointmentConditionType) => {
      return (
        (condition.displayDesiredSlot = true), (condition.displayIsBnpClientTableHeader = false)
      );
    },
    clientInformation: (condition: ClientInformationConditionType) => {
      return (
        (condition.displayMeetingStatus = true), (condition.displayIsBnpClientInformation = false)
      );
    },
    customerCreationForm: (condition: CustomerCreationFormConditionType) => {
      return (condition.displayIsBnpClientInformation = false);
    }
  }
};
