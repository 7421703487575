import { Tenant } from '@ifs/libs/src/shared/models/TenantModel';
import { assets as MenuAssets } from './shared/Menu/assets/Assets';
import { bcef } from './multi-tenant/BCEF/Bcef';
import { bcefRetail } from './multi-tenant/BCEFRetail/BcefRetail';
import { bcefPrivateBank } from './multi-tenant/BCEFPrivateBank/BcefPrivateBank';
import { matmut } from './multi-tenant/Matmut/Matmut';
import { i18n as CustomerFileI18n } from './modules/customer-file/pages/CustomerFilePage/i18n';
import { i18n as AppointmentI18n } from './modules/appointments/i18n';
import { condition as CustomerFileDisplayedCondition } from './modules/customer-file/Displayed';
import { condition as AppointmentDisplayedCondition } from './modules/appointments/Displayed';
import { condition as CustomerFormDisplayedCondition } from './modules/advisor-course/pages/CustomerCreation/components/CustomerCreationForm/Displayed';
import { globali18n } from 'i18n';
import { i18n as cookiesI18n } from './modules/cookies/i18n';
import { cssVar } from './cssVar';
import { i18n as authI18n } from './modules/auth/i18n';

export function TenantLoader(tenantIdentifier: Tenant) {
  if (tenantIdentifier !== Tenant.Internaute) {
    let tenant = matmut;

    // toDelete when backend env are OK
    const devTenant = sessionStorage.getItem('tenant');
    // toDelete when backend env are OK
    if (devTenant && devTenant === Tenant.BCEFPrivateBank) {
      // Manually add config of Authentification mode to OIDCAuthCode value
      // Must be delete when backend env is OK

      tenant = bcefPrivateBank;
    } else {
      switch (tenantIdentifier) {
        case Tenant.Matmut:
          tenant = matmut;
          break;
        case Tenant.BCEF:
          tenant = bcef;
          break;
        case Tenant.BCEFPrivateBank:
          tenant = bcefPrivateBank;
          break;
        case Tenant.BCEFRetail:
          tenant = bcefRetail;
          break;
        default:
          break;
      }
    }
    tenant.assets.logo(MenuAssets);

    tenant.displayed.appointment(AppointmentDisplayedCondition);
    tenant.displayed.clientInformation(CustomerFileDisplayedCondition);
    tenant.displayed.customerCreationForm(CustomerFormDisplayedCondition);

    tenant.i18n.advisorCookies(cookiesI18n);
    tenant.i18n.appointment(AppointmentI18n);
    tenant.i18n.customerFile(CustomerFileI18n);
    tenant.i18n.global(globali18n);
    tenant.i18n.authModule(authI18n);
    document.title = globali18n.title;

    Object.keys(tenant.cssVar).map((e) =>
      document.documentElement.style.setProperty(e, (tenant.cssVar as any)[e])
    );
  } else {
    document.title = globali18n.title;
    Object.keys(cssVar).map((e) =>
      document.documentElement.style.setProperty(e, (cssVar as any)[e])
    );
  }
}
