import React, { useState, useEffect } from 'react';
import styles from './CustomerNoteComponent.module.scss';
import noteIcon from '../assets/note.svg';
import editIcon from '../assets/edit.svg';
import moment from 'moment';
import classNames from 'classnames';
import { i18n } from '../../../i18n';
import { globali18n } from 'i18n';
import { useCreateRemarkCommand } from 'modules/customer-file/commands/addRemarkCommand';
import { updateRemarkRequest } from 'modules/customer-file/requests/updateRemarkRequest';
import { deleteRemarkRequest } from 'modules/customer-file/requests/deleteRemarkRequest';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import { Button, TextInput } from '@ifs/libs';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { EAdvisorCustomerActiontype } from '../AdvisorCustomerActionType.enum';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';

export function CustomerNoteComponent(props: {
  remark: string | undefined;
  addRemark: any;
  advisorName: string;
  date: Date | string | null | undefined;
  identifier?: string;
  onValidate?: any;
  cancelAddNote?: any;
  advisorIdentifier: string;
  creationDate: string;
}) {
  const { simpleEvent } = useAnalytics();
  const { getAdvisorId } = useTenantConfigCommand();
  const [remarkValue, setRemarkValue] = useState<string>('');
  const [currentAdvisorId, setCurrentAdvisorId] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [inputError, setInputError] = useState<boolean>(false);
  const { createRemarkCommand } = useCreateRemarkCommand();

  const [countChars, setCountChars] = useState<number>(0);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);

  const handleAddRemark = async () => {
    await createRemarkCommand({ $type: EAdvisorCustomerActiontype.Remark, customerId, advisorId: currentAdvisorId ,text: remarkValue });
    props.onValidate();
    props.cancelAddNote();
  };

  const handleEditRemark = async () => {
    if (!props.identifier) return;
    await updateRemarkRequest({ remarkIdentifier: props.identifier }, { $type: EAdvisorCustomerActiontype.Remark, customerId, advisorId: currentAdvisorId ,text: remarkValue });
    setEdit(false);
    await props.onValidate();
  };

  const handleDeleteRemark = async () => {
    if (!props.identifier) return;
    await deleteRemarkRequest({ remarkIdentifier: props.identifier });

    setEdit(false);
    await props.onValidate();
  };

  useEffect(() => {
    const advisorId = getAdvisorId();
    setCurrentAdvisorId(advisorId || '');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setCustomerId(urlParams.get('customerid') || '')
    setInputError(true);
    if (advisorId === props.advisorIdentifier) {
      const now = moment(new Date());
      const time = moment(props.date);
      const minutes = now.diff(time, 'minutes');

      if (minutes >= 10) {
        setIsEditable(false);
        setEdit(false);
      } else {
        setIsEditable(true);
      }

      let isEdit = setInterval(() => {
        const now = moment(new Date());
        const time = moment(props.date);
        const minutes = now.diff(time, 'minutes');
        if (minutes >= 10) {
          setIsEditable(false);
          setEdit(false);
          clearTimeout(isEdit);
        } else {
          setIsEditable(true);
        }

        if (isNaN(minutes)) {
          setEdit(false);
          clearTimeout(isEdit);
        }
      }, 5000);
    }
  }, []);

  const handleCheckError = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemarkValue(e.target.value);
    setCountChars(e.target.value.length);
    if (e.target.value.length <= 1) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  return (
    <div className={styles.Container}>
      {props.addRemark === true ? (
        <>
          <div>
            <div>
              {i18n.customerNoteComponent.labels.addA}{' '}
              <span className={styles.BoldGreen}>{i18n.customerNoteComponent.labels.note}</span>{' '}
              {i18n.customerNoteComponent.labels.customer}
            </div>
            <div className={styles.BoxCounter}>
              <span>{i18n.customerNoteComponent.labels.enterAtLeast}</span>
              <span>{countChars}/2000</span>
            </div>
          </div>
          <TextInput
            type={'text'}
            value={remarkValue}
            onChange={handleCheckError}
            maxLength={2000}
            minLength={2}
            className={styles.Input}
            placeholder={i18n.customerNoteComponent.labels.addANote}
          />
        </>
      ) : (
        <div>
          <div>
            <img src={noteIcon} alt="" aria-hidden="true" />
            <span className={styles.AdvisorName}>{props.advisorName}</span>{' '}
            {i18n.customerNoteComponent.labels.wroteA}&nbsp;
            <span className={styles.BoldGreen}>{i18n.customerNoteComponent.labels.note}</span>{' '}
            {i18n.customerNoteComponent.labels.customer}
          </div>
          <span className={styles.Date}>{props.creationDate}</span>
          <div className={styles.NoteBloc}>
            <div>
              {isEditable && (
                <img
                  src={editIcon}
                  alt="edit"
                  className={styles.EditIcon}
                  onClick={() => {
                    setEdit(!isEdit);
                    setRemarkValue(props.remark!)
                  }}
                />
              )}
            </div>
            <div>
              {isEdit ? (
                <div>
                  <div className={styles.BoxCounter}>
                    <span>{i18n.customerNoteComponent.labels.enterAtLeast}</span>
                    <span>{countChars}/2000</span>
                  </div>
                  <TextInput
                    type={'text'}
                    value={remarkValue}
                    onChange={handleCheckError}
                    maxLength={2000}
                    minLength={2}
                    className={styles.InputEdit}
                    placeholder={i18n.customerNoteComponent.labels.addANote}
                  />
                </div>
              ) : (
                <div>{props.remark}</div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={styles.ActionBox}>
        {isEdit && props.addRemark !== true && (
          <Button
            onClick={() => {
              simpleEvent('delete_note');
              handleDeleteRemark();
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          >
            {globali18n.labels.delete}
          </Button>
        )}
        {(props.addRemark === true || isEdit) && (
          <>
            <Button
              onClick={() => {
                if(isEdit) {
                  setEdit(false)
                }
                simpleEvent('cancel_note');
                props.cancelAddNote();
              }}
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            >
              {globali18n.labels.cancel}
            </Button>
            <Button
              onClick={() => {
                if (props.addRemark) {
                  simpleEvent('add_note');
                  handleAddRemark();
                } else {
                  handleEditRemark();
                }
              }}
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
              disabled={inputError}
            >
              {globali18n.labels.validate}
            </Button>
          </>
        )}
      </div>
      <div className={styles.Divider}></div>
    </div>
  );
}
