
export const i18n = {
    AuthPage: {        
        pageTitle: 'Authentification',
        register: "Inscription",
        accountActivated: 'Félicitations, votre compte est activé.',
        signin: "Connexion",
        passwordReset: "Réinitialisation MDP"
    },
    loginPage: {
        pageTitle: 'Connexion',
        title: "Connectez-vous",
        subTitle: "Identifiez-vous afin d'accéder à l'espace conseiller Mondemain",
        labels: {
            password: 'Mot de passe',
            connect: 'Se connecter',
            passwordReset: 'Mot de passe oublié\u00A0?',
        },
        errors: {
            incorrectCredentials:
              "Il n'existe aucun utilisateur qui coïncide avec les e-mail et mot de passe fournis.",
            userLockedOut:
              "Ce compte est temporairement suspendu à cause d'un nombre trop important de tentatives de connexion."
          },
    },
    registerPage : {
        title:"Créez votre compte",
        subTitle:"Initiez votre espace conseiller Mondemain",
        labels: {
            inscriptionPending: `Votre demande d'inscription a bien été transmise et est en attente
            d'approbation`,
            firstName: 'Votre prénom',
            lastName: 'Votre nom',
            confirmPassword: 'Confirmation Mot de passe',
            createAccount: 'Créer mon compte',
            alreadyAnAccount: 'Vous avez déjà un compte ?'
        },
        passwordValidation : {
            atLeast8Characters: 'Au moins 8 caractères',
            atLeastOneUpperCase: 'Au moins 1 majuscule',
            atLeastOneToLowerCase: 'Au moins 1 minuscule',
            atLeastOneNumber: 'Au moins 1 nombre',
            atLeastOneSpecialCharacter: 'Au moins un caractère spécial',
            noOtherSpecialCharacter: 'Pas d’autre type de caractères',
        },
        errors : {
            passwordsDifferents : 'Les mots de passe ne sont pas identiques.',
            emailDomain: 'Le domaine de l’adresse e-mail est invalide.'
        }
    },
    registrationEmailPage: {
      pageTitle: 'Email envoyé',
      title: 'Un lien d’activation vous a été envoyé (valable 48h)',
      subtitle: '',
      resend: 'Renvoyer un email d’activation',
      goToLogin: 'Se connecter'
    },
    registrationResendPage: {
      pageTitle: 'Lien expiré',
      title: "Votre lien d'activation a expiré",
      subtitle: 'Cliquez ci-dessous pour recevoir un nouvel email de validation',
      resend: 'Renvoyer un email d’activation'
    },
    passwordResendPage: {
        pageTitle: 'Lien expiré',
        title: "Votre lien de réinitialisation de mot de passe a expiré",
        subtitle: 'Cliquez ci-dessous pour recevoir un nouvel email de réinitialisation de mot de passe',
        resend: 'Renvoyer un email de réinitialisation de mot de passe'
    },
    passwordResetEmailPage: {
      pageTitle: 'Email envoyé',
      labels: {
        goToLogin: 'Se connecter'
      },
      title: 'Un lien de réinitialisation de mot de passe vous a été envoyé (valable 48h)'
    },
    resetPasswordPage : {        
        pageTitle: 'Mot de passe oublié',
        title: 'Vous avez oublié votre mot de passe ?',
        subTitle: 'Pas de panique, il suffit de nous indiquer votre adresse mail',
        labels: {
            newResetLink: 'Demander un nouveau lien'
        },
        errors: {
            mail: "E-mail incorrect",
            resetLink: 'Lien de réinitialiser de mot de pass incorrect ou expiré'
        }
    },
    passwordResetResetPage: {
        pageTitle: 'Réinitialisation du mot de passe'
    },
    resetPasswordResetPage : {
        title: "Votre lien d'activation a expiré",
        subTitle: "Cliquez ci-dessous pour recevoir un nouvel email d'activation",
        labels : {
            email: 'Adresse e-mail'
        },
        cta: {
            resend : "Renvoyer un email d'activation"
        }
    }
};
