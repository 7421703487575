import { Button } from '@ifs/libs';
import classNames from 'classnames';
import { globali18n } from 'i18n';
import { CustomerStatus } from 'models/customer-status';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import { i18n } from './i18n';
import styles from './StatutModal.module.scss';
import buttonStyles from 'styles/ButtonStyle.module.scss';

interface StatutModalProps {
  name: string;
  lastname: string;
  newStatut: CustomerStatus;
  currentStatut: string;
  handleModal: (e: string) => void;
  changeCustomerStatut: (e: CustomerStatus) => void;
}

export function StatutModal(props: StatutModalProps) {
  const { changeStatusEvent } = useAnalytics();

  return (
    <div>
      <section>
        {i18n.statutButtonComponent.labels.updateStatusMeeting}{' '}
        <span className={styles.ModalBodyName}>{`${props.lastname} ${props.name}`}</span>{' '}
        {globali18n.labels.of} <span className={styles.ModalBodyName}>{props.currentStatut}</span>{' '}
        {globali18n.labels.to}{' '}
        <span className={styles.ModalBodyName}>{globali18n.status[props.newStatut]}</span> ?
        <br />
        <br />
        {props.currentStatut === 'Honored' && (
          <span>{i18n.statutButtonComponent.labels.honoredDisclaimer}</span>
        )}
        {props.currentStatut === 'Canceled' && (
          <span>{i18n.statutButtonComponent.labels.canceledDisclaimer}</span>
        )}
      </section>

      <div className={styles.ModalActions}>
        <Button
          onClick={() => props.handleModal('close')}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
        >
          {globali18n.labels.cancel}
        </Button>
        <Button
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
          onClick={() => {
            changeStatusEvent({
              new_status: globali18n.status[props.newStatut],
              previous_status: props.currentStatut
            });
            props.changeCustomerStatut(props.newStatut);
          }}
        >
          {globali18n.labels.confirm}
        </Button>
      </div>
    </div>
  );
}
