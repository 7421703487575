import axios, { AxiosResponse } from 'axios';
import { RequestResetPasswordParams, ResetPasswordBodyModel } from '../models/auth.model';

export interface ResetPasswordOutcome {
  InvalidSession : boolean,
  Success : boolean
}

export async function resetPasswordRequest(
  model: ResetPasswordBodyModel,
  params: RequestResetPasswordParams
): Promise<AxiosResponse> {
  return await axios.post(`/api/auth/reset_password`, model, { params });
}
