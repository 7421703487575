import { useEffect, useState } from 'react';
import { useLoader } from "@ifs/libs";
import { useCheckSSOAuthenticationCommand } from 'modules/auth/commands/checkSSOAuthenticationCommand';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';

export function useResolver() {
    const [checkConnectionDone, setCheckConnectionDone] = useState<boolean>();
    const { checkSSOAuthentication } = useCheckSSOAuthenticationCommand();
    const { isTenantWithAuthenticationModeSSO } = useTenantConfigCommand();
    const { showLoaderWhile } = useLoader();

    useEffect(() => {
        showLoaderWhile(async () => {    
        if(isTenantWithAuthenticationModeSSO())  {
            await checkSSOAuthentication();  
        }
        setCheckConnectionDone(true);     
        }, {key: "CustoemrFileResolver", value:"API GET - /bff/user"});
    }, []);

    return { checkConnectionDone };
}
