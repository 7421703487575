import { ProfileScale, Scale } from '@ifs/libs/src/modules/investor-profile/models/ProfileScaleModel';
import { EProfileType } from '@ifs/libs/src/modules/investor-profile/models/ProfileTypeEnum';
import { EProfileStatus } from '@ifs/libs/src/modules/investor-profile/models/QuestionnaireSessionModel';
import { GetRiskProfiles } from '@ifs/libs/src/modules/investor-profile/models/RiskProfileModel';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import axios from 'axios';

export async function getSearchRiskProfilesRequest(params: string): Promise<SearchRiskProfiles> {
  const data = await axios.get<GetRiskProfiles>(apiUrls(params).investorProfile.riskProfile.searchRiskProfile, {
    params: {limit: 1, sortBy: "ValidatedAt Desc, CreatedAt Desc", statuses: EProfileStatus.Validated}
  });
  const version = (data && data.data.results[0]?.configurationVersion) || 0;
  const scale = await axios.get<ProfileScale>(apiUrls(params).investorProfile.profileScale, {
    params: { profileType: EProfileType.Risk, version }
  });

  const finalData = {
    ...data.data,
    ...scale.data
  };
  return finalData;
}

export interface SearchRiskProfiles extends GetRiskProfiles {
  scale: Scale[];
}
