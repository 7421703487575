import styles from './CustomerAppointmentBody.module.scss';
import CustomerAppointmentTile from '../AppointmentTile/CustomerAppointmentTile';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
import { HouseIncome } from 'models/house-hold-income';
import { globali18n } from 'i18n';
import { i18n } from 'modules/appointments/i18n';
import { condition } from 'modules/appointments/Displayed';
import { createRef, useEffect, useState } from 'react';
import { EAppointmentStatus } from 'modules/appointments/enums';
import { AppointmentResult, CustomerAppointmentBodyResult } from 'modules/appointments/models';

export function CustomerAppointmentBody(props: {
  listOfAppointments: CustomerAppointmentBodyResult[];
  assignCustomer: (arg0: any) => void;
  unAssignCustomer: (arg0: any) => any;
  changeAppointmentStatut:  (customerId: string, appointmentId: string, newStatut: EAppointmentStatus) => Promise<any>;
}) {
  // Need this tricky to keep a good table render
  // For some tenants, we dont need all the table header
  // It create some visual shift
  let [nbTableCells, setNbTableCells] = useState<number>(9);
  const tableHeaderRef: React.RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    if (tableHeaderRef && tableHeaderRef.current) {
      setNbTableCells(tableHeaderRef.current.children.length - 1);
    }
  }, [tableHeaderRef]);

  const renderAppointments = () => {
    const listOfAppointmentsTiles = [];

    function formatIncome(income: number | undefined): string {
      if (!income) return '';

      if (income <= 50000) return HouseIncome.ZeroToFifty;
      if (income <= 100000) return HouseIncome.FiftyToHundred;
      if (income <= 150000) return HouseIncome.HundredToHundredFifty;
      if (income <= 200000) return HouseIncome.HundredFiftyToTwoHundred;

      return HouseIncome.TwoHundredToPlus;
    }

    for (let index = 0; index < props.listOfAppointments.length; index++) {
      const element = props.listOfAppointments[index];
      let isLastElement = false;

      const {
        customer,
        desiredDate,
        createdAt,
        desiredTimeSlot,
        initialMonthlyTransactionAmount,
        initialTransactionAmount,
        status,
        id,
      } = element as AppointmentResult;

      const desiredDateUsed = desiredDate ? desiredDate : createdAt;
      const appointmentDate = moment(new Date(desiredDateUsed)).format('DD-MM-YYYY');

      if (element.assignedAdvisor) {
        if (
          element.assignedAdvisor.firstName !== null &&
          element.assignedAdvisor.lastName !== null
        ) {
          element.advisor = `${element.assignedAdvisor.firstName!.charAt(0).toUpperCase()}. ${
            element.assignedAdvisor.lastName
          }`;
        } else {
          element.advisor = i18n.customerAppointmentBodyComponent.errors.advisor;
        }
      } else {
        element.advisor = undefined;
      }

      if (index === props.listOfAppointments.length - 1) {
        isLastElement = true;
      } else {
        isLastElement = false;
      }

      listOfAppointmentsTiles.push(
        <CustomerAppointmentTile
          key={+id}
          firstName={customer.firstName}
          lastName={customer.lastName}
          isBnppClient={customer.isBankClient ? globali18n.labels.yes : globali18n.labels.no}
          statut={status}
          initialValue={`${initialTransactionAmount ? initialTransactionAmount : '-'} ${
            globali18n.labels.euros
          }`}
          monthlyPayment={`${
            initialMonthlyTransactionAmount ? initialMonthlyTransactionAmount : '-'
          } ${globali18n.labels.eurosPerMonth}`}
          appointmentDate={appointmentDate}
          appointmentHours={desiredTimeSlot}
          advisor={element.advisor}
          advisorFirstName={element?.assignedAdvisor?.firstName}
          advisorLastName={element?.assignedAdvisor?.lastName}
          attributeCustomer={() => props.assignCustomer(customer.id)}
          unAssignCustomer={() => props.unAssignCustomer(customer.id)}
          changeStatut={props.changeAppointmentStatut}
          customerId={customer.id}
          appointmentId={id}
          isLastElement={isLastElement}
        />
      );
    }

    return listOfAppointmentsTiles;
  };

  return (
    <div className={styles.CustomerAppointmentBodyBox}>
      <div
        className={styles.CustomerAppointmentBodyTitle}
        ref={tableHeaderRef}
        style={{ gridTemplateColumns: `repeat(${nbTableCells}, minmax(80px, 1fr)) 150px` }}
      >
        <span>{globali18n.labels.firstName}</span>
        <span>{globali18n.labels.lastName}</span>
        {condition.displayIsBnpClientTableHeader && (
          <span className={styles.BirthDate}>{globali18n.labels.clientBNPP}</span>
        )}
        <span className={styles.Status}>{globali18n.labels.statut}</span>
        <span>{i18n.customerAppointmentBodyComponent.labels.initialPayment}</span>
        <span>{i18n.customerAppointmentBodyComponent.labels.monthlyPayment}</span>
        <span>{i18n.customerAppointmentBodyComponent.labels.appointmentDate}</span>
        {condition.displayDesiredSlot && (
          <span>{i18n.customerAppointmentBodyComponent.labels.desiredSlot}</span>
        )}
        <span>{globali18n.labels.advisor}</span>
      </div>
      {renderAppointments()}
    </div>
  );
}
