import { Route, Routes } from 'react-router-dom';
import { AuthPage } from './pages/AuthPage/AuthPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { RegisterPage } from './pages/RegisterPage/RegisterPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage/ResetPasswordPage';
import { RegistrationPasswordEmailPage } from './pages/ResetPasswordEmailPage/ResetPasswordEmailPage';
import { ResetPasswordResetPage } from './pages/ResetPasswordResetPage/ResetPasswordResetPage';
import { PasswordResendPage } from './pages/PasswordResendPage/PasswordResendPage';
import { RegistrationEmailPage } from './pages/RegistrationEmailPage/RegistrationEmailPage';
import { AuthenticatePage } from './pages/AuthenticatePage/AuthenticatePage';
import { AnalyticsComponent } from '@ifs/libs/src/shared/components/datalayer/AnalyticsComponent';
import { i18n } from './i18n';

const t = i18n;

export function AuthenticationModule() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AnalyticsComponent
            page_title={t.AuthPage.pageTitle}
            page_path={'/auth'}
            page_type={'login'}
            simulator_screen={'login'}
            simulator_step={''}
            simulator_type={'login'}
          >
            <AuthPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/authenticate"
        element={
          <AnalyticsComponent
            page_title={t.loginPage.pageTitle}
            page_path={'/auth/authenticate'}
            page_type={'login'}
            simulator_screen={'login'}
            simulator_step={''}
            simulator_type={'login'}
          >
            <AuthenticatePage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/login"
        element={
          <AnalyticsComponent
            page_title={t.loginPage.pageTitle}
            page_path={'/auth/login'}
            page_type={'login'}
            simulator_screen={'login'}
            simulator_step={''}
            simulator_type={'login'}
          >
            <LoginPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/register"
        element={
          <AnalyticsComponent
            page_title={t.registerPage.title}
            page_path={'/auth/register'}
            page_type={'register'}
            simulator_screen={'register'}
            simulator_step={''}
            simulator_type={'register'}
          >
            <RegisterPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/password-reset"
        element={
          <AnalyticsComponent
            page_title={t.resetPasswordPage.pageTitle}
            page_path={'/auth/password-reset'}
            page_type={'password-reset'}
            simulator_screen={'password-reset'}
            simulator_step={''}
            simulator_type={'password-reset'}
          >
            <ResetPasswordPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/password-reset/email"
        element={
          <AnalyticsComponent
            page_title={t.passwordResetEmailPage.pageTitle}
            page_path={'/auth/password-reset/email'}
            page_type={'password-reset'}
            simulator_screen={'password-reset'}
            simulator_step={''}
            simulator_type={'password-reset'}
          >
            <RegistrationPasswordEmailPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/password-reset/reset"
        element={
          <AnalyticsComponent
            page_title={t.passwordResetResetPage.pageTitle}
            page_path={'/auth/password-reset/reset'}
            page_type={'password-reset'}
            simulator_screen={'password-reset'}
            simulator_step={''}
            simulator_type={'password-reset'}
          >
            <ResetPasswordResetPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/password-reset/resend"
        element={
          <AnalyticsComponent
            page_title={t.passwordResendPage.pageTitle}
            page_path={'/auth/password-reset/resend'}
            page_type={'password-reset'}
            simulator_screen={'password-reset'}
            simulator_step={''}
            simulator_type={'password-reset'}
          >
            <PasswordResendPage />
          </AnalyticsComponent>
        }
      />
      <Route
        path="/register/email"
        element={
          <AnalyticsComponent
            page_title={t.registrationEmailPage.pageTitle}
            page_path={'/auth/register/email'}
            page_type={'register'}
            simulator_screen={'register'}
            simulator_step={''}
            simulator_type={'register'}
          >
            <RegistrationEmailPage />
          </AnalyticsComponent>
        }
      />
    </Routes>
  );
}
