import { SvgWrapperComponent } from '@ifs/libs';
import { CrossRoundedIcon } from 'modules/appointments/assets';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import styles from './AdvisorButton.module.scss';

export function AdvisorButton(props: {
  advisorFirstName: string | undefined | null;
  advisorLastName: string | undefined | null;
  unAssignCustomer: () => void;
}) {
  const { simpleEvent } = useAnalytics();

  const getAdvisorName = () => {
    if (!props.advisorFirstName || !props.advisorLastName) return '';
    return `${props.advisorFirstName.charAt(0).toUpperCase()}. ${props.advisorLastName
      .replace(/\s/g, '')
      .slice(0, 15)}`;
  };

  return (
    <div
      className={styles.AdvisorSelected}
      onClick={(e) => {
        e.stopPropagation();
        props.unAssignCustomer();
        simpleEvent('unassign');
      }}
    >
      <SvgWrapperComponent ariaHidden={true}>
        <CrossRoundedIcon className={styles.Icon} />
      </SvgWrapperComponent>
      <span>{getAdvisorName()}</span>
    </div>
  );
}
