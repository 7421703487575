import { SvgWrapperComponent } from '@ifs/libs';
import { HouseIcon, PigIcon, RemoteIcon } from 'modules/customer-file/assets';
import { i18n } from '../i18n';
import styles from './PatrimonyCard.module.scss';
import { ModuleCard } from 'shared/ModuleCard/ModuleCard';
import { PatrimonTile } from './components/PatrimonTile';
import { GetSavingAccountResponseModel } from '@ifs/libs/src/modules/patrimony/models/SavingsAccountModel';
import { GetRealEstateResponseModel } from '@ifs/libs/src/modules/patrimony/models/RealEstateModel';
import { ProductType } from 'modules/customer-file/models/Customer';
import classNames from 'classnames';

interface PatrimonyCardProps {
  savingAccounts: GetSavingAccountResponseModel[];
  realEstates: GetRealEstateResponseModel[];
}

const t = i18n.PatrimonyCard;

export function PatrimonyCard(props: PatrimonyCardProps) {
  const patrimonyFilledAndCompleted =  props.savingAccounts.length > 0 || props.realEstates.length > 0;
  return (
    <ModuleCard moduleIcon={RemoteIcon} title={t.title} headingContent={patrimonyFilledAndCompleted ? (<></>) : (<>
        <div className={styles.MediumPrimaryColor}> 
           {i18n.monthlyAmount(0)} 
        </div>
          </>)}>
      <div className={classNames({[styles.GreenSubCard]: patrimonyFilledAndCompleted})}>
        <div>
          {props.savingAccounts.length > 0 && (
            <div className={styles.Title}>
              <SvgWrapperComponent ariaHidden={true}>
                <PigIcon className={styles.Icon} />
              </SvgWrapperComponent>
              <span>{t.savingAccount}</span>
            </div>
          )}
          <div className={classNames({[styles.SavingAccount]: props.savingAccounts.length > 0})}>
            {props.savingAccounts.map((item, index) => (
              <PatrimonTile
                key={`${item}_${index}`}
                productName={t.productType[item.productTypeId as ProductType]}
                currentAmount={item.currentAmount}
                monthlySavings={item.monthlySavings}
                type="savingAccount"
              />
            ))}
          </div>
        </div>
        <div>
          {props.realEstates.length > 0 && (
            <div className={styles.Title}>
              <SvgWrapperComponent ariaHidden={true}>
                <HouseIcon className={styles.Icon} />
              </SvgWrapperComponent>
              <span>{t.realEstate}</span>
            </div>
          )}
          <div>
            {props.realEstates.map((item, index) => (
              <PatrimonTile
                key={`${item}_${index}`}
                currentAmount={item.currentAmount}
                isMainResidence={item.isMainResidence}
                productName={item.name}
                type="realEstates"
              />
            ))}
          </div>
        </div>
      </div>
    </ModuleCard>
  );
}

