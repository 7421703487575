import { i18n } from '../../i18n';
import styles from './RegistrationResendPage.module.scss';
import classNames from 'classnames';
import { Container, PageTitle, SvgWrapperComponent } from '@ifs/libs';
import { ModuleStyles } from 'modules/auth/styles/ModuleStyles';
import { useResendValidationEmail } from 'modules/auth/commands/resendValidationEmail.command';
import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from '@ifs/libs/src/shared';
import { Button } from 'shared';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import buttonStyles from 'styles/ButtonStyle.module.scss';

export function RegistrationResendPage() {
  const [email, setEmail] = useState<string>();
  const searchParams = useSearchParams();
  const { resendValidationEmailCommand } = useResendValidationEmail();

  useEffect(() => {
    setEmail(searchParams.get('email')!);
  }, []);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    resendValidationEmailCommand(email!);
  }

  return (
    <div className={styles.RegisterContainer}>
      <div className={styles.Header}>
        <PageTitle>{i18n.registrationResendPage.title}</PageTitle>
        <h6 className={styles.Subtitle}>{i18n.registrationResendPage.subtitle}</h6>
      </div>
      <Container className={ModuleStyles.Grid}>

        <SvgWrapperComponent ariaHidden={true}>
          <SharedAssets.Mailbox className={ModuleStyles.Mailbox} />
        </SvgWrapperComponent>

        <form className={ModuleStyles.FormGridCentered} noValidate onSubmit={onSubmit}>
          <input
            className={styles.Input}
            type="email"
            disabled={true}
            value={email ? email : ''}
            onChange={(e) => {}}
          />

          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            onClick={() => {}}
          >
            {i18n.registrationResendPage.resend}
          </Button>
        </form>
      </Container>
    </div>
  );
}
