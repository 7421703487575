import { i18n } from 'modules/customer-file/pages/CustomerFilePage/i18n';
import styles from './CustomerSSOInformationsData.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, TextInput } from '@ifs/libs';
import { CustomerInformationResponse } from 'modules/customer-file/models/Customer';
import classNames from 'classnames';
import { DownloadReportIcon } from 'modules/customer-file/assets';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { usePutCustomerCommand } from 'modules/advisor-course/commands/putCustomerCommand';
import { nameRegex } from '@ifs/libs/src/shared/utilities/validation';
import { globali18n } from '@ifs/libs/src/i18n';

interface props {
  customerInformations: CustomerInformationResponse;
  hasReport: boolean;
  openReport: () => void;
}

export function CustomerSSOInformationsData({
  customerInformations,
  hasReport,
  openReport
}: props) {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [uid, setUID] = useState<string>('');
  const { putCustomerCommand } = usePutCustomerCommand();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const customerid = urlParams.get('customerid');

  useEffect(() => {
    setUID(customerid || '');
    customerInformations?.lastName && setLastName(customerInformations.lastName);
    customerInformations?.firstName && setFirstName(customerInformations.firstName);
  }, []);

  function checkNameValid(name: string): boolean {
    return !!name.match(nameRegex);
  } 

  function isSubmitDisable(): boolean {
    return !firstName || !lastName || !checkNameValid(firstName) || !checkNameValid(lastName);
  }

  function onKeyDown(event: any) {
    if ((event as KeyboardEvent).key.length === 1 && !/^[A-zÀ-ž\u0370-\u03FF\u0400-\u04FF- ']/.test((event as KeyboardEvent).key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function saveUserInformation() {
    // IF LastName or FirstName has been updated, we will send a backend request to update the value
    if(customerInformations?.lastName !== lastName.trim() || customerInformations?.firstName !== firstName.trim()) {
      putCustomerCommand(customerid!, {...customerInformations, lastName, firstName})
      return;      
    }
  }

  return (
    <>
      <div className={styles.CustomerInformationsContainer}>
        <div className={styles.UserDataContainer}>
          <div>
            <label className={styles.Label}>{i18n.customerFilePage.labels.uid}</label>
            <div className={styles.DataValue}>{uid}</div>
          </div>
          <div className={styles.Input}>
            <label className={styles.Label}>{i18n.customerFilePage.labels.lastName}</label>
            <TextInput
              type={'text'}
              className={styles.Input}
              value={lastName}
              onKeyDown={onKeyDown}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                setLastName(event.target.value);
              }}
              placeholder={i18n.customerFilePage.labels.lastName}
            />
            { !checkNameValid(lastName) && (
              <div className={styles.PatternError}>{globali18n.errors.pattern}</div>
            )}
          </div>
          <div className={styles.Input}>
            <label className={styles.Label}>{i18n.customerFilePage.labels.firstName}</label>
            <TextInput
              type={'text'}
              className={styles.Input}
              value={firstName}
              onKeyDown={onKeyDown}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                setFirstName(event.target.value);
              }}
              placeholder={i18n.customerFilePage.labels.firstName}
            />
            { !checkNameValid(firstName)  && (
              <div className={styles.PatternError}>{globali18n.errors.pattern}</div>
            )}
          </div>
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            disabled={!hasReport}
            onClick={openReport}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
          >
            <span className={styles.ButtonReportWrapper}>
              <DownloadReportIcon />
              <span>Rapport</span>
            </span>
          </Button>
          <Button
            onClick={saveUserInformation}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            disabled={isSubmitDisable()}
          >
            {i18n.customerFilePage.labels.save}
          </Button>
        </div>
      </div>
    </>
  );
}
