import { Button } from '@ifs/libs';
import classNames from 'classnames';
import { globali18n } from 'i18n';
import { useEffect } from 'react';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import styles from '../../../../../../../styles/ModalStyle.module.scss';

interface AssignCustomerModalProps {
  title: string;
  lastName: string;
  name: string;
  event: string;
  customerId: string;
  action: () => void;
  advisor: string;
  handleModal: (e: string) => void;
}

export function AssignCustomerModal(props: AssignCustomerModalProps) {
  const { simpleEvent, pageView } = useAnalytics();
  useEffect(() => {
    pageView({
      page_title: `popin_${props.event}`,
      page_path: '/',
      page_type: 'popin',
      user_id: props.customerId as string,
      visitor_mode: '%visitor_mode'
    });
  }, []);

  return (
    <div>
      <div className={styles.ModalBody}>
        {props.title}{' '}
        <span className={styles.ModalBodyName}>{`${props.lastName} ${props.name}`}</span>{' '}
        {props.event === 'assigned' && <>?</>}
        {props.event === 'unassigned' && (
          <span>
            {globali18n.labels.of} <span className={styles.ModalBodyName}>{props.advisor}</span> ?
          </span>
        )}
      </div>

      <div className={styles.ModalActions}>
        <Button
          onClick={() => {
            simpleEvent(`back_${props.event}`);
            props.handleModal('close');
          }}
          className={classNames(styles.BaseButton, styles.FilledButtonSecondary)}
        >
          {globali18n.labels.cancel}
        </Button>
        <Button
          className={classNames(styles.BaseButton, styles.FilledButtonPrimary)}
          onClick={() => {
            props.action();
            simpleEvent(props.event);
            props.handleModal('close');
          }}
        >
          {globali18n.labels.confirm}
        </Button>
      </div>
    </div>
  );
}
