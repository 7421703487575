import classNames from 'classnames';
import { EPersonalizedFollowup, ESubscriptionDecision } from 'modules/customer-file/enums';
import { PropsWithChildren, useEffect, useState } from 'react';
import { i18n } from './i18n';
import styles from './MultiChoisesButton.module.scss';

interface MultiChoisesButtonProps {
  title: string;
  handleChange: (arg0: ESubscriptionDecision | EPersonalizedFollowup) => void;
  selectedElement: ESubscriptionDecision | EPersonalizedFollowup;
  dafaultValue: ESubscriptionDecision | EPersonalizedFollowup;
  disabled: boolean;
}

export function MultiChoisesButton(props: MultiChoisesButtonProps) {
  const [listOfButtons, setLisOfButtons] = useState<JSX.Element[]>([]);

  function targetedElements() {
    if (props.dafaultValue === ESubscriptionDecision.No) {
      const itemList = Object.keys(ESubscriptionDecision);
      const listOfButtons = itemList.map((e, index) => (
        <ChoiseItem
          disabled={props.disabled}
          onClick={() => props.handleChange(e as ESubscriptionDecision)}
          key={`${e}-${Math.random()}_${index}`}
          isSelected={(e as ESubscriptionDecision) === props.selectedElement}
        >
          {i18n[e as ESubscriptionDecision]}
        </ChoiseItem>
      ));
      return listOfButtons;
    }

    const itemList = Object.keys(EPersonalizedFollowup);

    const listOfButtons = itemList.map((e, index) => (
      <ChoiseItem
        disabled={props.disabled}
        onClick={() => props.handleChange(e as EPersonalizedFollowup)}
        key={`${e}-${Math.random()}_${index}`}
        isSelected={(e as EPersonalizedFollowup) === props.selectedElement}
      >
        {i18n[e as EPersonalizedFollowup]}
      </ChoiseItem>
    ));
    return listOfButtons;
  }

  useEffect(() => {
    const buttons = targetedElements();
    setLisOfButtons(buttons);
  }, [props.selectedElement, props.disabled]);

  return (
    <div className={styles.ChoisesContainer}>
      <div>
        <span className={styles.Title}>{props.title}</span>
      </div>
      <div className={styles.ButtonContainer}>{listOfButtons}</div>
    </div>
  );
}

interface ChoiseItemProps {
  isSelected: boolean;
  onClick: () => void;
  disabled: boolean;
}

function ChoiseItem(props: PropsWithChildren<ChoiseItemProps>) {
  return !props.disabled ? (
    <button
      className={classNames(styles.Button, { [styles.ButtonSelected]: props.isSelected })}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  ) : (
    <div
      className={classNames(styles.Button, styles.ButtonDisabled, { [styles.ButtonSelected]: props.isSelected })}
    >
      {props.children}
    </div>
  );
}
