import { useNavigate } from 'react-router-dom';
import { ModalOutcome } from "@ifs/libs";
import { usePutCustomerCommand } from "modules/advisor-course/commands/putCustomerCommand";
import { useCreateNewCustomerCommand } from "modules/customer-file/commands/createNewCustomerCommand";
import { useGetCustomerInfoCommand } from "modules/customer-file/commands/getCustomerInfoCommand";

export function useSearchCustomerCommand() {
    const navigate = useNavigate();
  const { getCustomerInformationCommand } = useGetCustomerInfoCommand();
  const { renderModalCustomerUndefinedCommand } = useCreateNewCustomerCommand();
  const { putCustomerCommand } = usePutCustomerCommand();
  
  async function searchCustomer(customerId: string): Promise<any> {
        const customer = await getCustomerInformationCommand({ customerId });
        if(customer === null) {
            const result = await renderModalCustomerUndefinedCommand(customerId);
            if (result === ModalOutcome.Ok) {
                await putCustomerCommand(customerId, {
                    lastName: null, 
                    firstName: null,  
                    email: null,
                    phoneNumber: null,
                    isBankClient: null,
                    canReceiveCommercialContent: null,
                    canReceiveInformativeContent: null
                });
                navigate(`/customer-file?customerid=${customerId}`);
                return;
            }
            return;
        }
        navigate(`/customer-file?customerid=${customerId}`);
        return;
  }

  return { searchCustomer };
}
