import { useEffect, useState } from 'react';
import styles from './PropositionCard.module.scss';
import { number } from 'Utils/Splitter';
import { i18n } from './i18n';
import { globali18n } from 'i18n';
import { Transaction } from './components/Transactions/Transaction';
import {
  RecommendationOutputModel
} from '@ifs/libs/src/modules/recommendation/models/RecommendationModel';
import { RecommendationProductInformation } from '@ifs/libs/src/modules/recommendation/components/ProductsTable/ProductsTable';
import {
  getFullLockCell,
  getPartialLock
} from '@ifs/libs/src/modules/recommendation/utilities/productCellGenerators';
import { ProductTypeIdentifier } from '@ifs/libs/src/modules/recommendation/models/product-type-identifier.enum';
import { ExclusionReason } from '@ifs/libs/src/modules/recommendation/models/exclusion-reason.enum';
import { InvestmentScope } from '@ifs/libs/src/modules/recommendation/models/investment-scope.enum';
import { getProducts } from 'modules/customer-file/requests/getProductsRequest';
import { defaultData } from '@ifs/libs/src/modules/recommendation/Envelop';

const t = i18n;
interface DisplayedPropositionCardProps {
  customerData: RecommendationOutputModel;
  age: { month: number; year: number };
}

export function PropositionCard(props: DisplayedPropositionCardProps) {
  function retirementAge() {
    return (
      <span className={styles.SubtitleBlack}>
        {props.age.year} {globali18n.labels.years}{' '}
        {props.age.month > 0 ? `et ${props.age.month} mois` : ''}
      </span>
    );
  }

  const age = retirementAge();

  const [productsTable, setProductTable] = useState<RecommendationProductInformation[]>([]);

  async function createRecommendationProductsTable() {
    const products = await getProducts();
    const mapProducts = products.map((product) => { return {
      productTypeIdentifier: product.productTypeIdentifier as ProductTypeIdentifier,
      ...defaultData
    }});
    let tempListOfProducts: any[] = mapProducts;

    tempListOfProducts.map((e) => {
      const isExcludedProductTypes =
        props.customerData.excludedProductInvestment.excludedProductTypes.find(
          (z) => z.productTypeId === e.productTypeIdentifier
        );
      const isExcludedScopedInvestments =
        props.customerData.excludedProductInvestment.excludedScopedInvestments.find(
          (z) => z.productTypeId === e.productTypeIdentifier
        );
      const isPresent = props.customerData.transactions.productTypeTransactions.find(
        (z) => z.productTypeId === e.productTypeIdentifier
      );

      if (!isExcludedProductTypes && !isExcludedScopedInvestments && !isPresent) {
        e.fullExclude = {
          reason: ExclusionReason.InsufficientProductKnowledge,
          isExclude: true
        };
      } else {
        e.fullExclude = getFullLockCell(
          e.productTypeIdentifier as ProductTypeIdentifier,
          props.customerData.excludedProductInvestment
        );
      }
      e.parcialExclude = getPartialLock(
        e.productTypeIdentifier as ProductTypeIdentifier,
        props.customerData.excludedProductInvestment
      ) as any;
    });

    setProductTable(tempListOfProducts);
  }

  useEffect(() => {
    createRecommendationProductsTable();
  }, []);

  const riskProfile = t.riskProfile[props.customerData.riskProfileId];

  function finData(identifier: ProductTypeIdentifier) {
    const item = props.customerData.transactions.productTypeTransactions.find(
      (e) => e.productTypeId === identifier
    );

    if (!item) {
      return {
        transactionAmount: 0,
        monthlyTransactionAmount: 0
      };
    }
    return item;
  }

  const hasInvesmentCellExclude = props.customerData.excludedProductInvestment.excludedScopes.find(
    (e) => e.investmentScope === InvestmentScope.InitialInvestment
  );
  const hasMonthlyCellExclude = props.customerData.excludedProductInvestment.excludedScopes.find(
    (e) => e.investmentScope === InvestmentScope.PeriodicInvestment
  );

  function excludeMessage(identifier: ProductTypeIdentifier, returnMessage: boolean) {
    const findElement = props.customerData.excludedProductInvestment.excludedProductTypes.find(
      (e) => e.productTypeId === identifier
    );

    if (returnMessage) {
      if (findElement!.exclusionReason === ExclusionReason.InsufficientProductKnowledge) {
        return 'Enveloppe non adaptée à votre profil investisseur.';
      } else {
        return 'Enveloppe non adaptée à votre projet.';
      }
    } else {
      return '';
    }
  }

  return (
    <div className={styles.PropositionCardBox}>
      <div className={styles.LeftBloc}>
        <div className={styles.FlexSplit}>
          <span className={styles.SubtitleBlack}>{i18n.labels.additionalIncome} :</span>
          <span className={styles.BolderPrimary}>
            {number(props.customerData.additionalRetirementIncome.neutral.monthlyAmount)}{' '}
            {globali18n.labels.eurosPerMonth}
          </span>
        </div>
        <div className={styles.FlexSplit}>
          <span className={styles.SubtitleBlack}>{i18n.labels.retirementCapital} :</span>
          <span className={styles.BolderPrimary}>
            {number(
              props.customerData.additionalRetirementIncome.neutral.capitalAmountAtRetirement
            )}{' '}
            {globali18n.labels.euros}
          </span>
        </div>
        <div className={styles.FlexSplit}>
          <span className={styles.SubtitleBlack}>{i18n.labels.taxSavings} :</span>
        </div>

        <div className={styles.FlexSplit}>
          <span className={styles.SubtitleLight}>{i18n.labels.firstMonth}</span>
          <span className={styles.BolderPrimary}>
            {number(props.customerData.taxSavings.taxSavingsOnMonthlyInvestment)} €
          </span>
        </div>

        <div className={styles.FlexSplit}>
          <span className={styles.SubtitleLight}>{i18n.labels.nextMonths}</span>
          <span className={styles.BolderPrimary}>
            {number(props.customerData.taxSavings.taxSavingsOnCapitalInvestment)} €
          </span>
        </div>

        <div className={styles.FlexSplit}>
          <span className={styles.SubtitleLight}>{globali18n.labels.tmi}</span>
          <span className={styles.BolderPrimary}>{props.customerData.taxSavings.tmi * 100} %</span>
        </div>

        <div className={styles.FlexSplit}>
          <span className={styles.BolderPrimary}>{i18n.labels.riskPreference} :</span>
          <span className={styles.SubtitleBlack}>{riskProfile}</span>
        </div>

        <div className={styles.FlexSplit}>
          <span className={styles.BolderPrimary}>{i18n.labels.retirementAge} :</span>
          {age}
        </div>
      </div>
      <div className={styles.RightBloc}>
        {productsTable.map((e: RecommendationProductInformation, i: number) => (
          <Transaction
            isMonthlyLock={e.parcialExclude.monthlyLock.isExclude}
            isInitialLock={e.parcialExclude.initialLock.isExclude}
            monthlyTransactionAmount={finData(e.productTypeIdentifier).monthlyTransactionAmount}
            transactionAmount={finData(e.productTypeIdentifier).transactionAmount}
            isFullExclude={e.fullExclude.isExclude}
            key={i}
            noAccessMessage={excludeMessage(e.productTypeIdentifier, e.fullExclude.isExclude)}
            productTypeIdentifier={e.productTypeIdentifier}
          />
        ))}

        <div className={styles.TotalAmountContainer}>
          <div className={styles.AmountTotal}>
            <div className={styles.SubtitleBlack}>1 er versement</div>
            <div className={styles.BolderPrimary}>
              {hasInvesmentCellExclude
                ? 'Minimum non atteint'
                : number(props.customerData.transactions.totalTransactionAmount) + '€'}
            </div>
          </div>
          <div className={styles.AmountTotal}>
            <div className={styles.SubtitleBlack}>Mensualité</div>
            <div className={styles.BolderPrimary}>
              {hasMonthlyCellExclude
                ? 'Minimum non atteint'
                : number(props.customerData.transactions.totalMonthlyTransactionAmount) + '€'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
