import axios from 'axios';
import { LifeProjectsModel } from 'models/customer-situation';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import { axiosRequestValidateStatusConfig } from '@ifs/libs/src/shared/axiosValidateStatusConfig';

export async function getCustomerLifeProjectsRequest(
  params: string
): Promise<LifeProjectsModel | null> {
  const response = await axios.get(apiUrls(params).lifeProjects.get, {...axiosRequestValidateStatusConfig});

  if (response.status === 204 || response.status === 404) {
    return null;
  }

  return response.data;
}

export async function getCustomerLifeMonthly(
  params: string,
  sum: number
): Promise<{monthlyAnnuityAmount: number}> {
  const response = await axios.post(apiUrls(params).lifeProjects.simulation, {capitalAmount: sum});
  return response.data;
}
 