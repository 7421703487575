import { useGlobalCommand } from "@ifs/libs";
import { useAppSelector } from "store";

export function useTenantConfigCommand() {
    const { isAuthenticationModeSSO } = useGlobalCommand();
    const advisorId = useAppSelector((s) => s.authentication.customerId);
    const renderTenantSelectionPage = useAppSelector(
        (s) => s.authentication.renderTenantSelectionPage
    );  

    // We consider a tenant no defined as a SSO Tenant
    // In the actual flow, if back dont return any tenant at the service /api/tenants
    // on Frontend side, we will render a tennat sso page selection
    // So in the current case, the only time we will receive an undefined tenant is to deal the SSO connection for BCEF project
    function isTenantWithAuthenticationModeSSO(): boolean {
      return  renderTenantSelectionPage || isAuthenticationModeSSO()
    }

    function getAdvisorId(): string {
      return (isTenantWithAuthenticationModeSSO() ? advisorId : sessionStorage.getItem('identifier'))|| '';
    }

    return { isTenantWithAuthenticationModeSSO, getAdvisorId};
}