
import { useLoader } from "@ifs/libs";
import { apiUrls } from "shared/apiUrls";
import { SearchAppointmentParams } from "../models/searchAppointmentModel";
import { searchAppointmentRequest } from "../requests/searchAppointmentRequest";
import { AppointmentPaginatedResponse } from "../models";

export function useSearchAppointmentCommand() {
    const { showLoaderWhile } = useLoader();

    async function searchAppointmentCommand(params: SearchAppointmentParams): Promise<AppointmentPaginatedResponse> {
        return await showLoaderWhile(async () => {
            return await searchAppointmentRequest(params);
        }, {key: "searchAppointmentCommand", value:`API GET - ${apiUrls().appointments.get}`})
    }
    return { searchAppointmentCommand };
}

