import { useState, useEffect } from 'react';
import style from './CustomerHistoryEvents.module.scss';
import { MinusGreenIcon } from 'modules/customer-file/assets';
import { i18n } from '../../i18n';
import { getAdvisorCustomerHistoryRequest } from 'modules/customer-file/requests/getAdvisorCustomerHistoryRequest';
import { AdvisorCustomerHistory } from 'modules/customer-file/models/advisorCustomerAction';
import { CustomerInformationResponse } from 'modules/customer-file/models/Customer';
import { EventHistories } from './Components/EventHistories/EventHistories';
import { useLoader } from '@ifs/libs';
import Pagination from "react-responsive-pagination";

interface CustomerHistoryEventsProps {
  addCrDisplayed: boolean;
  addRemark: boolean;
  cancelAddNote: () => void;
  cancelAddCR: () => void;
  customerInformation: CustomerInformationResponse | null;
  refetchValue: () => void;
  reloadCustomerInformations: () => void;
}

export default function CustomerHistoryEvents(props: CustomerHistoryEventsProps) {
  const [displayHistoryClient, setDisplayHistoryClient] = useState(true);
  const [historyEvents, setHistoryEvents] = useState<AdvisorCustomerHistory[]>();
  const [historyEventsLoaded, setHistoryEventsLoaded] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { showLoaderWhile } = useLoader();


  const fetchHistories = async (offset: number = 0) => {
    const customerIdentifier = urlParams.get('customerid');
    if (!customerIdentifier) return;
    return await showLoaderWhile(async () => {
      const getHistoriesPagination  = (await getAdvisorCustomerHistoryRequest({ customerId:customerIdentifier, limit:paginationLimit, offset }));
      setTotalPages(Math.ceil(getHistoriesPagination!.pagination!.totalResultsCount/getHistoriesPagination!.pagination!.resultsCount));
      const getHistories = getHistoriesPagination!.results;
      setHistoryEventsLoaded(false);
      setHistoryEvents([]);
      if (getHistories) {
        setHistoryEvents(getHistories);
        setHistoryEventsLoaded(true);
      } else {
        setHistoryEventsLoaded(false);
      }
    }, {key: "accountDisableCommand", value:"API GET - /api/auth/accounts/disable"})
  };

  const changePaginationIndex = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const offset = paginationLimit * (pageNumber - 1);
    await fetchHistories(offset);
  };

  useEffect(() => {
    fetchHistories();
  }, [props.customerInformation]);

  return (
    <div className={style.HistoryContainer}>
      <div
        className={style.HistoryTitle}
        onClick={() => {
          setDisplayHistoryClient((state) => !state);
        }}
      >
        <span className={style.HistoryTitleText}>
          {i18n.customerHistoryEvents.labels.clientHistoric}
        </span>
          <MinusGreenIcon />
      </div>
      <div className={style.Divider}></div>

      {displayHistoryClient && (
        <div className={style.HistoryClientBody}>
          {historyEventsLoaded && (
            <>
              <EventHistories
                listOfEvents={historyEvents!}
                addRemark={props.addRemark}
                addCrDisplayed={props.addCrDisplayed}
                cancelAddCR={() => props.cancelAddCR()}
                reloadEvents={async () => await fetchHistories()}
                cancelAddNote={() => props.cancelAddNote()}
                reloadCustomerInformations={() => props.reloadCustomerInformations()}
              />             
             { totalPages > 1 &&  (<Pagination
                current={currentPage}
                maxWidth={120}
                total={totalPages}
                onPageChange={changePaginationIndex}
              />)}
            </>
          )}
        </div>
      )}
    </div>
  );
}
