import styles from './Menu.module.scss';
import MenuButton from './Components/MenuButton/MenuButton';
import { useLocation, useNavigate } from 'react-router-dom';
import packageInfo from '../../../package.json';
import { showVersion } from 'Utils/Debug';
import { ClientSearchIcon, ExitIcon, MeetingIcon } from './assets';
import { assets as Assets } from './assets/Assets';
import { globali18n } from 'i18n';
import { i18n } from 'shared/i18n';
import { Button } from 'shared';
import axios from 'axios';
import classNames from 'classnames';
import { SvgWrapperComponent, useGlobalCommand } from '@ifs/libs';
import { globali18n as libsGlobali18n } from '@ifs/libs/src/i18n';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';

interface MenuProps {
  numberOfAppointments?: number;
  userName?: string;
  logout?: any;
  isAdmin?: boolean;
}

const t = i18n.Menu;

enum MenuNavigationUrl {
  SearchClient = '/advisor-course',
  CustomerFile = '/customer-file'
}

const isCookiesPage = window.location.pathname === '/cookies';

export function Menu(props: MenuProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { simpleEvent } = useAnalytics();
  const { isTenantWithAuthenticationModeSSO } = useTenantConfigCommand();


  async function getUrlsRequest() {
    const response = await axios.get(apiUrls().tenants);
    return response.data.Urls;
  }

  function isMenuSelected(localtionUrl?: MenuNavigationUrl): boolean {
    if (localtionUrl) {
      return location.pathname.includes(localtionUrl);
    }
    // For RDV Clients not specific routes is defined
    // We consider that the associated menu is selected
    // when path doesnt includes other specific menu routes
    return !([MenuNavigationUrl.SearchClient, MenuNavigationUrl.CustomerFile] as string[]).some(
      (url) => (location.pathname === '/' ? false : location.pathname.includes(url))
    );
  }

  return (
    <div className={styles.Container}>
      <div className={styles.LogoBox}>
        {showVersion() && <div className={styles.version}>v{packageInfo.version}</div>}
        <SvgWrapperComponent ariaLabel={libsGlobali18n.ariaLabel.homeRedirect}>
          <Assets.MenuLogoIcon
            className={styles.BnpLogo}
            onClick={() => {
              if (props.isAdmin) {
                navigate('/admin');
              } else {
                navigate('/');
              }
            }}
          />
        </SvgWrapperComponent>
      </div>
      {!isCookiesPage &&
        (props.isAdmin ? (
          <>
            <div className={styles.NameBox}>{globali18n.labels.superAdmin}</div>
            <MenuButton text={t.Buttons.admin} icon={MeetingIcon} onClick={() => null} />
            <MenuButton
              text={t.Buttons.logout}
              icon={ExitIcon}
              onClick={() => {
                simpleEvent('sign_out');
                props.logout();
              }}
            />
            <Button
              onClick={async () => {
                simpleEvent('help');
                const urls = await getUrlsRequest();
                if (urls) {
                  window.open(urls.AdvisorTutorialPage);
                }
              }}
              className={classNames(
                styles.Button,
                buttonStyles.BaseButton,
                buttonStyles.FilledButtonSecondary
              )}
            >
              {i18n.Menu.Buttons.needHelp}
            </Button>
          </>
        ) : (
          <>
            <div className={styles.NameBox}>{props.userName}</div>
            {!isTenantWithAuthenticationModeSSO() && (
              <MenuButton
                text={t.Buttons.appointment}
                icon={MeetingIcon}
                asAlert
                isSelected={isMenuSelected()}
                numberOfAppointments={Number(props.numberOfAppointments) | 0}
                onClick={() => navigate('/')}
              />
            )}
            <MenuButton
              text={t.Buttons.clientLookup}
              icon={ClientSearchIcon}
              isSelected={isMenuSelected(MenuNavigationUrl.SearchClient)}
              onClick={() => navigate(isTenantWithAuthenticationModeSSO() ? "/tenant-selection" : '/advisor-course/search-client')}
            />
            <Button
              onClick={async () => {
                simpleEvent('help');
                const urls = await getUrlsRequest();
                if (urls) {
                  window.open(urls.AdvisorTutorialPage);
                }
              }}
              className={classNames(
                styles.Button,
                buttonStyles.BaseButton,
                buttonStyles.FilledButtonSecondary
              )}
            >
              {i18n.Menu.Buttons.needHelp}
            </Button>
            <div className={styles.LogoutButton}>
              <MenuButton
                text={t.Buttons.logout}
                icon={ExitIcon}
                onClick={() => {
                  simpleEvent('sign_out');
                  props.logout();
                }}
              />
            </div>
          </>
        ))}
    </div>
  );
}
