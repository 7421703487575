import axios from "axios";
import { AxiosOutcome } from "shared/enums/axiosOutcome.enum";
import { RequestResetPasswordBodyModel } from "../models/auth.model";

export async function requestResetPasswordRequest(body: RequestResetPasswordBodyModel): Promise<AxiosOutcome> {
    try{
        await axios.post(`/api/auth/request_reset_password`, body);
        return AxiosOutcome.Success;
    }   
    catch(error){
        return AxiosOutcome.Error
    }
}