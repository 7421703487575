import axios from 'axios';
import { AxiosOutcome } from 'shared/enums/axiosOutcome.enum';

export type AccountEnableModel = {
  email: string;
}

export async function accountEnableRequest(model: AccountEnableModel): Promise<AxiosOutcome> {
  try {
    await axios.post(`/api/auth/accounts/enable`, model);
    return AxiosOutcome.Success;
  } catch (err) {
    return AxiosOutcome.Error;
  }
}
