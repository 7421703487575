import { globali18n } from '../../../i18n';
import { UseFormRegister } from 'react-hook-form';
import { emailRegex } from '../../../shared/utilities/validation';

export function getEmailConfig(register: UseFormRegister<any>) {
  const formConfig = {
    email: {
      field: register('email', { required: true, pattern: emailRegex , maxLength: 50}),
      errors: {
        required: globali18n.errors.required,
        pattern: globali18n.errors.email,
        maxLength: globali18n.errors.email,
        loginAlreadyExists: "L’e-mail est déjà associé à un compte."
      }
    }
  };

  return formConfig;
}
