import axios, { AxiosError } from 'axios';
import { AxiosOutcome } from 'shared/enums/axiosOutcome.enum';

export type AccountDisableModel = {
  email: string;
}

export async function accountDisableRequest(model: AccountDisableModel): Promise<AxiosOutcome> {
  try {
    await axios.post(`/api/auth/accounts/disable`, model);
    return AxiosOutcome.Success;
  } catch (err) {
    return AxiosOutcome.Error;
  }
}
