export enum EAppointmentMotive {
    Other = "Other",
    SavingsSolutionDetail = "SavingsSolutionDetail",
    FiscalOptimizationDetail = "FiscalOptimizationDetail",
}

export enum EPersonalizedFollowup {
    Yes = "Yes",
    No = "No",
    Partially = "Partially"
}

export enum ESubscriptionDecision {
    Yes = "Yes",
    No = "No",
    NotYet = "NotYet"
}
