import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import axios, { AxiosError } from 'axios';

export async function loadTenantRequest(): Promise<any> {
    try {
        return await axios.get(apiUrls().tenants);
    }
    catch(err) {
        const error = err as AxiosError;
        // Specific case for Advisor
        // With the release BPF SSO login, we could now get a 400 
        if (error.response?.status === 400) {
            if(error.response.data === "No cookie with name 'IfsTenant' was found.") {
                // Check with back if can handle a particular error
                return "NoTenantNeedTenantSelection"
            }
            return null;
        }    
        throw err;
    }
}