import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ResetPasswordResetPage.module.scss';
import classNames from 'classnames';
import { AuthIcon, CheckmarkIcon, RedCrossIcon } from 'modules/auth/assets';
import { globali18n } from 'i18n';
import { i18n } from 'modules/auth/i18n';
import { useResetPasswordCommand } from 'modules/auth/commands/resetPasswordCommand';
import { ResetPasswordOutcome } from 'modules/auth/enum/auth.enum';
import { Button, SvgWrapperComponent, TextInput } from '@ifs/libs';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import registerStyles from '../RegisterPage/RegisterPage.module.scss';
import { useAnalytics } from 'shared/Analytics/useAnalytics';

export function ResetPasswordResetPage() {
  let navigate = useNavigate();
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [secondPasswordValue, setSecondPasswordValue] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [resetButtonActive, setResetButtonActive] = useState<boolean>(false);
  const [lengthOfPassword, setLengthOfPassword] = useState<boolean>(false);
  const [asOneCaps, setAsOneCaps] = useState<boolean>(false);
  const [asLowerCase, setAsLowerCase] = useState<boolean>(false);
  const [asNumber, setAsNumber] = useState<boolean>(false);
  const [asSpecialChar, setAsSpecialChar] = useState<boolean>(false);
  const [asNoSpecial, setAsNoSpecial] = useState<boolean>(false);
  const { resetPasswordCommand } = useResetPasswordCommand();

  const handleResetPassword = async () => {
    const response = await resetPasswordCommand(
      { password: passwordValue },
      { email: email, sessionId: sessionId }
    );
    if (response === ResetPasswordOutcome.Success) {
      navigate('/auth/login');
    }
    if (response === ResetPasswordOutcome.InvalidSession) {
      navigate({
        pathname: '/auth/password-reset/resend',
        search: new URLSearchParams({ email }).toString()
      });
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionid = urlParams.get('sessionId');
    const email = urlParams.get('email');

    if (!email || !sessionid) {
      setErrorDisplayed(true);
    } else {
      setEmail(email);
      setSessionId(sessionid);
    }
  }, []);

  const handleRegExPassword = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const symbolRegex = /[ !"#$%&'()*+,-./:;<=>?@\\\[\]^_`{|}~]/;

    const allRegexes = [uppercaseRegex, lowercaseRegex, numberRegex, symbolRegex];

    function noOther(value: string, regexes: RegExp[]) {
      const chars = Array.from(value);

      // Are there caracters such that no regex matches them?
      const hasOthers = chars.some((c) => !regexes.some((r) => r.test(c)));

      return !hasOthers;
    }

    if (noOther(value, allRegexes)) {
      setAsNoSpecial(true);
    } else {
      setAsNoSpecial(false);
    }

    if (value.length >= 8) {
      setLengthOfPassword(true);
    } else {
      setLengthOfPassword(false);
    }

    if (value.match(uppercaseRegex)) {
      setAsOneCaps(true);
    } else {
      setAsOneCaps(false);
    }

    if (value.match(lowercaseRegex)) {
      setAsLowerCase(true);
    } else {
      setAsLowerCase(false);
    }

    if (value.match(numberRegex)) {
      setAsNumber(true);
    } else {
      setAsNumber(false);
    }

    if (value.match(symbolRegex)) {
      setAsSpecialChar(true);
    } else {
      setAsSpecialChar(false);
    }

    setPasswordValue(event.target.value);
  };

  useEffect(() => {
    if (
      asOneCaps &&
      asLowerCase &&
      asNumber &&
      asSpecialChar &&
      asNoSpecial &&
      passwordValue === secondPasswordValue
    ) {
      setResetButtonActive(false);
    } else {
      setResetButtonActive(true);
    }
  }, [passwordValue, secondPasswordValue]);

  const returnIcon = (condition: boolean) =>
    condition ? <CheckmarkIcon width={'25px'} /> : <RedCrossIcon width={'15px'} />;

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.Header}>
        {errorDisplayed ? (
          <>
            <h1>{i18n.resetPasswordResetPage.title}</h1>
            <span>{i18n.resetPasswordResetPage.subTitle}</span>
          </>
        ) : (
          <h1>{globali18n.labels.resetPassword}</h1>
        )}
      </div>

      <div className={styles.Body}>
        <div>
          <div>
            <div className={registerStyles.Input}>
              <label>{globali18n.labels.password}</label>
              <TextInput
                title={globali18n.labels.password}
                type={'password'}
                value={passwordValue}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  handleRegExPassword(event);
                }}
                placeholder={globali18n.labels.password}
                name={'password'}
              />
            </div>
            <div className={styles.ValidationBox}>
              <div
                className={classNames({
                  [styles.PasswordCheckSuccess]: lengthOfPassword,
                  [styles.PasswordCheckError]: !lengthOfPassword
                })}
              >
                {returnIcon(lengthOfPassword)}
                <span>{i18n.registerPage.passwordValidation.atLeast8Characters}</span>
              </div>
              <div
                className={classNames({
                  [styles.PasswordCheckSuccess]: asOneCaps,
                  [styles.PasswordCheckError]: !asOneCaps
                })}
              >
                {returnIcon(asOneCaps)}
                <span>{i18n.registerPage.passwordValidation.atLeastOneUpperCase}</span>
              </div>
              <div
                className={classNames({
                  [styles.PasswordCheckSuccess]: asLowerCase,
                  [styles.PasswordCheckError]: !asLowerCase
                })}
              >
                {returnIcon(asLowerCase)}
                <span>{i18n.registerPage.passwordValidation.atLeastOneToLowerCase}</span>
              </div>
              <div
                className={classNames({
                  [styles.PasswordCheckSuccess]: asNumber,
                  [styles.PasswordCheckError]: !asNumber
                })}
              >
                {returnIcon(asNumber)}
                <span>{i18n.registerPage.passwordValidation.atLeastOneNumber}</span>
              </div>
              <div
                className={classNames({
                  [styles.PasswordCheckSuccess]: asSpecialChar,
                  [styles.PasswordCheckError]: !asSpecialChar
                })}
              >
                {returnIcon(asSpecialChar)}
                <span>{i18n.registerPage.passwordValidation.atLeastOneSpecialCharacter}</span>
              </div>
              <div
                className={classNames({
                  [styles.PasswordCheckSuccess]: asNoSpecial,
                  [styles.PasswordCheckError]: !asNoSpecial
                })}
              >
                {returnIcon(asNoSpecial)}
                <span>{i18n.registerPage.passwordValidation.noOtherSpecialCharacter}</span>
              </div>
            </div>
            <div className={registerStyles.Input}>
              <label>{i18n.registerPage.labels.confirmPassword}</label>
              <TextInput
                title={i18n.registerPage.labels.confirmPassword}
                type={'password'}
                value={secondPasswordValue}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  setSecondPasswordValue(event.target.value);
                  if (passwordValue !== event.target.value) {
                    setPasswordError(true);
                  } else {
                    setPasswordError(false);
                  }
                }}
                placeholder={i18n.registerPage.labels.confirmPassword}
                name={'repeatPassword'}
              />
            </div>
          </div>
          <div className={styles.ActionBox}>
            <Button
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
              onClick={(): void => {
                handleResetPassword();
              }}
              disabled={resetButtonActive}
            >
              {globali18n.labels.resetPassword}
            </Button>
            <Button
              onClick={() => {
                navigate('/auth');
              }}
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
            >
              {globali18n.labels.back}
            </Button>
          </div>
        </div>

        <div className={styles.BackgroundIcon}>
          <SvgWrapperComponent ariaHidden={true}>
            <AuthIcon />
          </SvgWrapperComponent>
        </div>
      </div>
    </div>
  );
}
