
import { AxiosError } from "axios";
import { useLoader } from "@ifs/libs";
import { ResetPasswordOutcome } from "../enum/auth.enum";
import { RequestResetPasswordParams, ResetPasswordBodyModel } from "../models/auth.model";
import { resetPasswordRequest } from "../requests/resetPasswordRequest";

export function useResetPasswordCommand() {
    const { showLoaderWhile } = useLoader();

    async function resetPasswordCommand(model: ResetPasswordBodyModel, params: RequestResetPasswordParams): Promise<ResetPasswordOutcome> {
        return await showLoaderWhile(async () => {
            try {
                await resetPasswordRequest(model, params);
                return ResetPasswordOutcome.Success;
            } catch (err) {
                // IF specific error are return, need add a new entrance on specificErrors map object defined on file use401Interceptor
                const error = err as AxiosError;
                const body = error.response?.data as any;
        
                if (error.response?.status === 401 && body.error === "InvalidSession") {
                    return ResetPasswordOutcome.InvalidSession;
                }
        
                return ResetPasswordOutcome.Error;
            }
        }, {key: "resendValidationEmailCommand", value:"API POST - /api/auth/reset_password"})
    }

    return { resetPasswordCommand };
}