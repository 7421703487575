export function apiUrls(customerId?: string) {
  const urls = {
    customerFile: {
      patrimony: `/api/customer/${customerId}`,
      reportUrl: `/api/report/customer/${customerId}/generate`,
    },
    customers: {
      getById: `/api/customers/${customerId}`,
      search: `/api/customers`,
      put: `/api/customers/${customerId}`,
      assign: `/api/customers/${customerId}/assign`,
      deassign: `/api/customers/${customerId}/deassign`,
    },
    advisors: `/api/advisors`,
    appointments: {
      get: `/api/appointments`,
      put: (appointmentIdentifier:string)=> `/api/appointments/${appointmentIdentifier}/status`
    },
    advisorCustomerActions : {
      get: `/api/advisor-customer-actions`,
      post: `/api/advisor-customer-actions`,
      put: (actionId: string) => `/api/advisor-customer-actions/${actionId}`,
      delete: (actionId: string) => `/api/advisor-customer-actions/${actionId}`
    }
  };

  return urls;
}
