import { useState, useEffect, PropsWithChildren } from 'react';
import { Menu } from '../../shared';
import styles from './PageWithMenu.module.scss';
import { useLogoutUserCommand } from './commands/logoutUser';
import { useCountRequestedCommand } from './commands/countRequested';
import { Footer } from 'shared/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';
import { globali18n } from 'i18n';

export function PageWithMenu(
  props: PropsWithChildren<{
    reloadCounterCustomer?: boolean | null;
    isAdmin?: boolean;
  }>
) {
  const { handleLogoutCommand } = useLogoutUserCommand();
  const { countRequestedCommand } = useCountRequestedCommand();
  const [totalRequested, setTotalRequested] = useState(0);
  const [userFirstAndLastName, setUserFirstAndLastName] = useState('Chargement ...');
  
  const { isTenantWithAuthenticationModeSSO, getAdvisorId } = useTenantConfigCommand();
  const location = useLocation();
  const isTenantSSO = isTenantWithAuthenticationModeSSO();
  useEffect(() => {
    if (!props.isAdmin) {
      if (props.reloadCounterCustomer === true && !isTenantSSO) {
        // SSO Tenant dont have access to 
        countAppointment();
      }
    }
  }, [props.reloadCounterCustomer]);

  useEffect(() => {
    if (!location.pathname.includes('cookies')) {
      appointmentCount();
    }
  }, []);

  async function countAppointment() {
    const response = await countRequestedCommand();
    if (response) {
      setTotalRequested(response);
    }
  }

  const appointmentCount = async () => {
    let userIdentifier = sessionStorage.getItem('username');
    if(isTenantSSO) {
      const advisorId = getAdvisorId();
      setUserFirstAndLastName(`${globali18n.labels.advisor} ${advisorId}`);
      return;
    }
    if (userIdentifier) {
      setUserFirstAndLastName(userIdentifier);
    } else {
      handleLogoutCommand('expire');
      if(!isTenantSSO) {
        window.location.href = '/auth/login';
      }
    }
  };

  return (
    <div className={styles.Container}>
      <div className={styles.GridContainer}>
        <div></div>
        <div className={styles.GridboxMenu}>
            <Menu
              numberOfAppointments={totalRequested}
              userName={userFirstAndLastName}
              logout={() => handleLogoutCommand('logout_session')}
              isAdmin={props.isAdmin}
            />
        </div>
        <div>
          <div className={styles.GridboxRight}>{props.children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
