import { SvgWrapperComponent } from '@ifs/libs';
import { ChosenScenario } from '@ifs/libs/src/shared/models/ChosenScenario';
import { ComputationAgeResultModel, LegalPensionModel } from 'models/customer-situation';
import { MinusGreenIcon } from 'modules/customer-file/assets';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FileIcon } from 'shared/assets';
import { number } from 'Utils/Splitter';
import { i18n } from '../i18n';
import { NaturalLanguage } from '../NaturalLanguage/NaturalLanguage';
import styles from './LegalPensionCard.module.scss';

interface LegalPensionCardProps {
  legalPension: LegalPensionModel;
}

const t = i18n.LegalPensionCard;

export function LegalPensionCard({ legalPension }: LegalPensionCardProps) {
  const legalPensionInputs = legalPension.legalPensionComputation.inputs;
  const legalPensionComputationResults = legalPension.legalPensionComputation.results;
  const validatedLegalPension = legalPension.validatedLegalPension;
  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  const birthDate = moment(new Date(legalPensionInputs.birthDate)).format('DD/MM/YYYY');
  const startDateOfActivity = moment(new Date(legalPensionInputs.startDateOfActivity)).format('DD/MM/YYYY');

  const naturalLangage = ( <NaturalLanguage
    birthDate={birthDate}
    startDateOfActivity={startDateOfActivity}
    children={t.children(legalPensionInputs.numberOfChildren)}
    affiliate={t.professionalStatus[legalPensionInputs.professionalStatus]}
    salary={t.salaryFrequency[legalPensionInputs.salary.frequency](`${number(legalPensionInputs.salary.amount)}€`)}
    naturalLangage={t.naturalLangage.careerProgression(
      t.evolution[legalPensionInputs.salary.evolutionBeforeSimulation],
      t.evolution[legalPensionInputs.salary.evolutionAfterSimulation]
    )}
    gender={legalPensionInputs.gender}
  />)


  function chosenScenario(): ComputationAgeResultModel | null {
    if (validatedLegalPension.chosenScenario === ChosenScenario.MaxAge) {
      return legalPensionComputationResults.maxAgeResult;
    }
    if (validatedLegalPension.chosenScenario  === ChosenScenario.LegalAge) {
      return legalPensionComputationResults.legalAgeResult;
    }
    if (validatedLegalPension.chosenScenario  === ChosenScenario.FullRateAge) {
      return legalPensionComputationResults.fullRateAgeResult;
    }

    return legalPensionComputationResults.legalAgeResult;
  }

  const scenario = chosenScenario();

  function handleIsDisplay() {
    setIsDisplay(!isDisplay);
  }

  return (
    <div className={styles.Card}>
      <div
        aria-controls="collapsable-element"
        aria-expanded={isDisplay}
        className={styles.NeedsRetirement}
        onClick={handleIsDisplay}
      >
        <SvgWrapperComponent ariaHidden={true}>
          <FileIcon />
        </SvgWrapperComponent>
        <div className={styles.BolderPrimaryColor}>{t.title}</div>
        <div className={styles.MediumPrimaryColor}>
          {i18n.monthlyAmount(scenario!.pensionAmount)}
        </div>
        <div className={styles.ItemMonthly}>{t.chosenScenario[validatedLegalPension.chosenScenario]}</div>
        <SvgWrapperComponent ariaHidden={true}>
          <MinusGreenIcon className={styles.MinusIcon}/>
        </SvgWrapperComponent>
      </div>

      {isDisplay && (
        <>
          <div className={styles.NaturalLangage}>{naturalLangage}</div>

          <div id="collapsable-element" role="region" tabIndex={-1} className={styles.Summary}>
            <div className={styles.Title}>{t.summary.title}</div>
            <div className={styles.SummaryGrid}>
              {t.summary.retirementAge(
                scenario!.age.year,
                scenario!.age.month
              )}
              {t.summary.lossPercentage(scenario!.lossPercentage)}
              {t.summary.lastSalaryAmount(scenario!.lastSalaryAmount)}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
