import { EAppointmentMotive } from "modules/customer-file/enums";

export const i18n = {
  customerFilePage: {
    labels: {
      uid: "Identifiant unique du client",
      firstName: "Prénom",
      lastName: "Nom",
      titleUnassign: "Voulez-vous désattribuer le client",
      titleAssign: "Voulez-vous vous attribuer le client",
      customerCard: "Fiche client",
      customerAssignment: "Attribution Client",
      advisorFlow: "Parcours de simulation",
      save: "Sauvegarder",
      report: "Rapport",
    },
  },
  CollectionCard: {
    title: "Synthèse diagnostic client",
  },
  customerAddEventsComponent : {
    title: "Bloc Note MonDemain",
    note: "Pour le suivi client, ajouter un compte-rendu détaillé de l’entretien ou une note complémentaire.",
    labels: {
      addClientEvent: 'Ajouter un CR',
      addNote: 'Ajouter une note'
    }
  },
  customerHistoryEvents: {
    labels: {
      clientHistoric: "Historique",
      its: "s'est",
      startA: "a démarré un",
      customerFlow: "parcours de simulation client",
      assigned: "attribué",
      unassigned: "désattribué",
      customer: "le client"
    },
  },
  customerCRComponent: {
    listOfMotive: {
      [EAppointmentMotive.SavingsSolutionDetail]: "Détail sol. épargne",
      [EAppointmentMotive.FiscalOptimizationDetail]: "Détail optim. fiscale",
      [EAppointmentMotive.Other]: 'Autre'
    },
    labels: {
      reasonMeeting: 'MOTIF DU RDV',
      startMeetingDate:"DATE DE RDV",
      start: "Début",
      endMeetingDate:"DATE DE RDV",
      end: "Début",
      subscription: "SOUSCRIPTION",
      yes: "OUI",
      no: "NON",
      notYet: "PAS ENCORE",
      personalizedSolution: "SUIVI SOLUTION PERSONNALISÉE",
      addAComment: "Ajouter un commentaire",
      comment: "COMMENTAIRES",
      toComplete: "a complété",
      report: "un compte rendu",
      meeting: "de RDV",
    }
  },
  customerNoteComponent: {
    labels: {
      addA: "Ajouter une",
      note: "note",
      customer: "client",
      addANote: "Ajouter une note",
      wroteA: "a écrit une",
      enterAtLeast: "Veuillez saisir au minimum 2 caractères",
    }
  },
  customerInformationCard: {
    labels: {
      customerInformation: "Informations client",
      informativeContent: "Contenu informatif",
      commercialContent: "Offres et actions commerciales",
      historicMeeting: "Historique des RDV"
    }
  }
};
