import { PropsWithChildren, useState } from 'react';
import styles from './CollectionCard.module.scss';
import { MinusIcon } from 'shared/assets';
import { i18n } from '../../i18n';
import { SvgWrapperComponent } from '@ifs/libs';
import { globali18n } from '@ifs/libs/src/i18n';

const t = i18n.CollectionCard;

export default function CollectionCard(props: PropsWithChildren<{title?: boolean}>) {
  const [isDisplay, setIsDisplay] = useState(true);
  function handleDisplay(){
    setIsDisplay((state) => !state)
  }

  return (
    <div className={styles.ReceuilCardContainer}>
      <div className={styles.ReceuilCardHeader} onClick={handleDisplay}>
        <span className={styles.Title}>{!props.title ? t.title : "Proposition"}</span>
        <div className={styles.HeaderImage}>
          <SvgWrapperComponent ariaLabel={`${globali18n.ariaLabel.toggleMenu} ${t.title}`}>
            <MinusIcon />
          </SvgWrapperComponent>
        </div>
      </div>
      {isDisplay && props.children}
    </div>
  );
}