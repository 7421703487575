import { i18n } from '../../i18n';
import styles from './PasswordResendPage.module.scss';
import classNames from 'classnames';
import { Container, PageTitle, SvgWrapperComponent } from '@ifs/libs';
import { ModuleStyles } from 'modules/auth/styles/ModuleStyles';
import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from '@ifs/libs/src/shared';
import { Button } from 'shared';
import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import { useRequestResetPasswordCommand } from 'modules/auth/commands/requestResetPasswordCommand';
import { useNavigate } from 'react-router-dom';
import { AxiosOutcome } from 'shared/enums/axiosOutcome.enum';
import buttonStyles from 'styles/ButtonStyle.module.scss';

export function PasswordResendPage() {
  const [email, setEmail] = useState<string>();
  const searchParams = useSearchParams();
  let navigate = useNavigate();
  const { requestResetPasswordCommand } = useRequestResetPasswordCommand();
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);

  useEffect(() => {
    setEmail(searchParams.get('email')!);
  }, []);

  async function  onSubmit (e: FormEvent) {
    e.preventDefault();
    const body = {
      email: email || '',
    };

    const response = await requestResetPasswordCommand(body);

    if (response === AxiosOutcome.Success) {
      navigate('/auth/password-reset/email');
    } else {
      setErrorDisplayed(true);
    }
  }

  return (
    <div className={styles.RegisterContainer}>
      <div className={styles.Header}>
        <PageTitle>{i18n.passwordResendPage.title}</PageTitle>
        <h6 className={styles.SubTitle}>{i18n.passwordResendPage.subtitle}</h6>
      </div>
      <Container className={ModuleStyles.Grid}>
        {errorDisplayed ? <span>{i18n.resetPasswordPage.errors.mail}</span> : ''}

        <SvgWrapperComponent ariaHidden={true}>
          <SharedAssets.Mailbox className={ModuleStyles.Mailbox} />
        </SvgWrapperComponent>

        <form className={ModuleStyles.FormGridCentered} noValidate onSubmit={onSubmit}>
          <input
            className={styles.Input}
            type="email"
            disabled={true}
            value={email ? email : ''}
            onChange={(e) => {}}
          />

          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            onClick={() => {}}
          >
            {i18n.passwordResendPage.resend}
          </Button>
        </form>
      </Container>
    </div>
  );
}
