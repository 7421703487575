import React, { PropsWithChildren } from 'react';
import { ModalContext } from '../../context/modalContext';
import { Dialog } from '@progress/kendo-react-dialogs';
import classNames, { Argument } from 'classnames';
import styled from 'styled-components';
import { primarygreen } from 'styles/colors';

interface KendoModalProps extends PropsWithChildren<{}> {
  title: string;
  onClose: any;
  width?: string;
  height?: string;
  className?: Argument;
  closeIcon?: boolean;
}

export function StyledKendoModal(props: KendoModalProps) {
  const StyledGrid = styled(Dialog)`
    .k-dialog {
      max-width: ${props.width};
      width: 100%;
      max-height: ${props.height};
    }
    ,
    .k-window-titlebar {
      background-color: ${primarygreen};
    }
  `;

  return (
    <StyledGrid title={props.title} onClose={props.onClose} className={classNames(props.className)}>
      {props.children}
    </StyledGrid>
  );
}

export const Modal = () => {
  let { modalContent, modalTitle, handleModal, isDisplay } = React.useContext(ModalContext);
  return isDisplay ? (
    <StyledKendoModal title={modalTitle} onClose={() => handleModal('close')} width={'410px'} closeIcon={false}>
      {modalContent}
    </StyledKendoModal>
  ) : null;
};
