import { i18n } from 'modules/advisor-course/i18n';
import { Button, Input } from 'shared';
import styles from './Filter.module.scss';
import { i18n as sharedI18n } from 'shared/i18n';
import { useEffect, useState } from 'react';
import { FormModel } from 'modules/advisor-course/models/formModel';
import { MultiSelect, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { useNavigate } from 'react-router-dom';
import { AdvisorResponseModel, getAdvisorRequest } from 'shared/request/getAdvisorRequest';
import classNames from 'classnames';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { handleChangeList } from 'shared/IsCustom';
const t = i18n.Filter;

export function Filter(props: FilterProps) {
  const { simpleEvent } = useAnalytics();
  const [selectedAdvisor, setSelectedAdvisor] = useState([]);
  const [advisorsList, setAdvisorsList] = useState([] as MultiSelectValueType[]);
  const [advisorsFilteredList, setAdvisorsFilteredList] = useState([] as MultiSelectValueType[]);
  const navigate = useNavigate();
  const [form, setForm] = useState<FormModel>({
    firstName: '',
    lastName: '',
    emailAdress: ''
  });

  const handleChange = (event: { target: { value: string; name: string } }) => {
    let { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleResetForm = () => {
    setForm((prevState) => ({
      ...prevState,
      firstName: '',
      lastName: '',
      emailAdress: ''
    }));
    setSelectedAdvisor([]);
    // SetForm works like set state value it take times to see the value change
    form['firstName'] = '';
    form['lastName'] = '';
    form['emailAdress'] = '';
    props.applySearch({ ...form, advisors: [] });
    simpleEvent('reset_filters');
  };

  const handleSearchCustomer = async () => {
    props.applySearch({ ...form, advisors: selectedAdvisor });
  };

  useEffect(() => {
    (async () => {
      const advisor = await getAdvisorRequest();
      fetchAdvisors(advisor, setAdvisorsList, setAdvisorsFilteredList);
    })();
  }, []);

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    const filterValue = event.filter.value;
    const dataToFilter = advisorsList.filter(
      ({ label, value }) =>
        value === 'none' || label.toLowerCase().startsWith(filterValue.toLowerCase())
    );
    setAdvisorsFilteredList(dataToFilter);
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div>
          <span className={styles.TitleBold}>{sharedI18n.appName}</span>
          <span className={styles.TitleLight}>{i18n.moduleName}</span>
        </div>
        <div className={styles.TopRightContainer}>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
            onClick={() => {
              simpleEvent('new_client');
              navigate('/advisor-course/client-creation');
            }}
          >
            {t.newCustomer}
          </Button>
          <div className={styles.TopRightFilter}>
            <div className={styles.FilterContent}>
              <Button
                fillMode={'flat'}
                className={classNames(buttonStyles.BaseButton, buttonStyles.CleanFilter)}
                onClick={handleResetForm}
              >
                {t.clearFilters}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Body}>
        <Input
          title={t.firstName.toUpperCase()}
          onChange={handleChange}
          value={form.firstName}
          type="text"
          name={'firstName'}
          customInputStyle={{ marginLeft: '10px', width: '190px' }}
          customLabelStyle={{ marginLeft: '10px' }}
        />
        <Input
          title={t.lastName.toUpperCase()}
          onChange={handleChange}
          value={form.lastName}
          type="text"
          name={'lastName'}
          customInputStyle={{ width: '190px' }}
        />
        <Input
          title={t.email.toUpperCase()}
          onChange={handleChange}
          value={form.emailAdress}
          type="text"
          name={'emailAdress'}
          customInputStyle={{ width: '190px' }}
        />

        <div className={styles.SelectAdvisor}>
          <span className={styles.SelectAdvisorTitle}>{t.advisor.toUpperCase()}</span>
          <MultiSelect
            className={styles.MultiSelectStatut}
            data={advisorsFilteredList}
            value={selectedAdvisor}
            onChange={(e) => handleChangeList(e, setSelectedAdvisor)}
            textField="label"
            dataItemKey="value"
            placeholder={t.advisorPlhd}
            filterable={true}
            onFilterChange={filterChange}
          />
        </div>
        <div className={styles.SearchButton}>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            onClick={handleSearchCustomer}
          >
            {sharedI18n.Menu.Buttons.search}
          </Button>
        </div>
      </div>
    </div>
  );
}

async function fetchAdvisors(
  listOfAdvisors: AdvisorResponseModel[],
  setAdvisorsList: any,
  setAdvisorsFilteredList: any
) {
  const tempAdvisorArray: { label: string; value: string }[] = [];

  const advisors = await listOfAdvisors;
  tempAdvisorArray.push({
    label: 'Aucun',
    value: 'none'
  });
  advisors.map((advisor: { id: string; lastName?: string; firstName?: string }) => {
    let obj = {
      label: `${advisor.lastName} ${advisor.firstName}`,
      value: advisor.id
    };

    return tempAdvisorArray.push(obj);
  });

  setAdvisorsList(tempAdvisorArray);
  setAdvisorsFilteredList(tempAdvisorArray);
}

export type FilterProps = {
  applySearch: (params: SearchParams) => any;
};

export type SearchParams = {
  firstName: string;
  lastName: string;
  emailAdress: string;
  advisors: { label: string; value: string }[];
};

type MultiSelectValueType = {
  value: any;
  label: string;
};
