import axios from 'axios';
import { LegalPensionModel } from 'models/customer-situation';
import { axiosRequestValidateStatusConfig } from '@ifs/libs/src/shared/axiosValidateStatusConfig';
import { ValidatedLegalPensionPaginatedModel } from '@ifs/libs/src/modules/legal-pension/models/validation/ValidatedLegalPensionModel';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import { ComputationOutputModel } from '@ifs/libs/src/modules/legal-pension/models/ComputationOutputModel';

export async function getCustomerLegalPensionRequest(
  params: string
): Promise<LegalPensionModel | null> {
  const response = await axios.get<ValidatedLegalPensionPaginatedModel | null>(
    apiUrls(params).legalPension.validations,
    {
      params: { limit: 1, sortBy: 'CreatedAt Desc' },
      ...axiosRequestValidateStatusConfig
    }
  );
  const lastValidateLegalPensionData = (response.data?.results && response.data?.results[0]) || null;
  if (lastValidateLegalPensionData) {
    const apiUrl = apiUrls(params).legalPension.validationComputation(lastValidateLegalPensionData.id);
    const fullResponse = await axios.get<ComputationOutputModel>(apiUrl, {
      ...axiosRequestValidateStatusConfig
    });
    return {
      validatedLegalPension: lastValidateLegalPensionData,
      legalPensionComputation: fullResponse.data
    };
  }

  return null;
}
