import { countRequested } from "../requests/count-requested";

export function useCountRequestedCommand() {

  async function countRequestedCommand() : Promise<number> {

    const count = await countRequested();
    return count;
  }

  return { countRequestedCommand };
}
