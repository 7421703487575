// Primary
export const primarygreen = 'var(--advisor-primary-green)';
export const primarygreen_10 = 'var(--advisor-primary-green_10)'; 
export const primarygreen_15 = 'var(--advisor-primary-green_15)'; 
export const primarygreen_20 = 'var(--advisor-primary-green_20)'; 
export const primarygreen_50 = 'var(--advisor-primary-green_50)';  

// Secondary

export const secondaryorange = 'var(--advisor-secondary-orange)'; 
export const secondarymoutarde = 'var(--advisor-secondary-moutarde)'; 
export const secondarymoutarde_10 = 'var(--advisor-secondary-moutarde_10)'; 
export const secondaryturquoise = 'var(--advisor-secondary-turquoise)'; 
export const secondaryturquoise_10 = 'var(--advisor-secondary-turquoise_10)'; 
export const secondaryaubergine = 'var(--advisor-secondary-aubergine)'; 
export const secondaryaubergine_10 = 'var(--advisor-secondary-aubergine_10)'; 
export const rudybrown = 'var(--advisor-secondary-ruddy_brown)'; 


// UI

export const background = 'var(--advisor-app-background)'; 
export const label = "#515E63";
export const grey100 = '#212121'; 
export const grey80 = '#404040'; 
export const grey50 = '#767676'; 
export const grey20 = '#C9C9C9'; 
export const grey10 = '#EBEBEB'; 
export const white = '#FFFFFF'; 

// State
export const error = '#E64444'; 
export const success= "#008000";
export const pending = '#5A89CF';
export const pending_10 = '#ECF2FF';