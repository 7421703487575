declare global {
  interface Window {
    dataLayer?: object[];
  }
}

interface PageViewType {
  page_title: string;
  page_path: string;
  page_type: string;
  user_id?: string;
  visitor_mode?: string;
}

interface FilterEventType {
  status?: string;
  beg_meeting_date: string;
  end_meeting_date: string;
}

interface ChangeStatusEventType {
  new_status: string;
  previous_status: string;
}

interface CustomerEventType {
  event: string;
  bnp_customer: boolean;
}

interface CreateCREventType {
  date: string;
  meeting_purpose: string;
  subscription: string;
  personnalized_follow_up: string;
  comments: string;
}

const debug = window.localStorage.getItem('debug-datalayer') === 'true';

export function useAnalytics() {
  // Make sure that the analytics never crash the program
  function noThrow(fn: Function) {
    try {
      fn();
    } catch (err) {
      console.error(err);
    }
  }

  function push(value: any) {
    if (!window.dataLayer) window.dataLayer = [];

    window.dataLayer?.push(value);

    if (debug) {
      console.log('Event added', value);
      console.log('DataLayer : ', window.dataLayer);
    }
  }

  function pageView(pageInformation: PageViewType) {
    noThrow(() => {
      push({
        event: 'page_view',
        page_title: pageInformation.page_title,
        page_path: pageInformation.page_path,
        page_type: pageInformation.page_type,
        user_id: pageInformation.user_id,
        visitor_mode: pageInformation.visitor_mode
      });
    });
  }

  function simpleEvent(event: string) {
    noThrow(() => {
      push({
        event: event
      });
    });
  }

  function filterEvent(filterEvent: FilterEventType) {
    noThrow(() => {
      push({
        event: 'search',
        status: filterEvent.status,
        beg_meeting_date: filterEvent.beg_meeting_date,
        end_meeting_date: filterEvent.end_meeting_date
      });
    });
  }

  function noResultFilterEvent(filterEvent: FilterEventType) {
    noThrow(() => {
      push({
        event: 'no_results',
        status: filterEvent.status,
        beg_meeting_date: filterEvent.beg_meeting_date,
        end_meeting_date: filterEvent.end_meeting_date
      });
    });
  }

  function changeStatusEvent(changeStatusEvent: ChangeStatusEventType) {
    noThrow(() => {
      push({
        event: 'change_status',
        new_status: changeStatusEvent.new_status,
        previous_status: changeStatusEvent.previous_status
      });
    });
  }

  function globalStatisticEvent(filterEvent: FilterEventType) {
    noThrow(() => {
      push({
        event: 'validate',
        beg_meeting_date: filterEvent.beg_meeting_date,
        end_meeting_date: filterEvent.end_meeting_date
      });
    });
  }

  function createCustomerEvent(customerEvent: CustomerEventType) {
    noThrow(() => {
      push({
        event: customerEvent.event,
        bnp_customer: customerEvent.bnp_customer
      });
    });
  }

  function createCREvent(customerEvent: CreateCREventType) {
    noThrow(() => {
      push({
        event: "report_added",
        date: customerEvent.date,
        meeting_purpose: customerEvent.meeting_purpose,
        subscription: customerEvent.subscription,
        personnalized_follow_up: customerEvent.personnalized_follow_up,
        comments: customerEvent.comments
      });
    });
  }

  return {
    pageView,
    simpleEvent,
    filterEvent,
    changeStatusEvent,
    globalStatisticEvent,
    createCustomerEvent,
    createCREvent,
    noResultFilterEvent
  };
}
