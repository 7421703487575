import axios from "axios";
import { apiUrls } from "shared/apiUrls";
import { SearchAppointmentParams } from "../models/searchAppointmentModel";
import { AppointmentPaginatedResponse } from "../models";
import { EAppointmentStatus } from "../enums";

export async function searchAppointmentRequest(params: SearchAppointmentParams): Promise<AppointmentPaginatedResponse> {    
    const response = await axios.get(apiUrls().appointments.get, { paramsSerializer:queryParamsSerializer, params });
    return response.data;
}

// Need this serialize cause of array properties
// Without the serializer the array porperties of the queryParams will be sent like this :
//  => AdvisorIdentifierList[]=value1&AdvisorIdentifierList[]=value2
// Backend doesnt handle this format. They handle array like this : 
//  => AdvisorIdentifierList=value1&AdvisorIdentifierList=value2

const queryParamsSerializer = (searchParams: Record<string, number|EAppointmentStatus[]|string|string[]> ) => {
    let queryParams = ``;
    const params = [''];
    Object.entries(searchParams).forEach(([key, value])=> {
        if(key === "advisorId" || key === "status"){
            for (const id of value as string[]){
              params.push(`${key}=${id}`);
            }
        } else {
            params.push(`${key}=${value}`) 
        }

    })
    // check remove first character
    let paramsJoin = params.join("&");
    if(paramsJoin.charAt(0) === '&'){
        paramsJoin = paramsJoin.substring(1);
    }
    return queryParams + paramsJoin;
  };

