export const i18n = {
    appName: "Espace Conseiller",
    Menu : {
        Buttons : {
            admin: "Administration",
            logout: "Déconnexion",
            appointment: "RDV clients",
            clientLookup: "Recherche client",
            search: "Rechercher",
            attributeCustomer: "Attribution Client",
            needHelp: "Besoin d'aide ?"
        }
    }
};