export const i18n = {
    customerAppointmentPage: {
        labels:{
            notFound: "Aucun résultat trouvé"
        }
    },
    customerAppointmentTileComponent: {
        titleUnassign: "Voulez-vous désattribuer le client",
        titleAssign: "Voulez-vous vous attribuer le client",
        labels:{
            cancel: "Annuler",
            confirm: "Confirmer",
            customerAssignment: "Attribution Client",
            of: "de"
        }
    },
    customerAppointmentBodyComponent: {
        labels: {
            initialPayment: 'Versement initial',
            monthlyPayment: 'Versement mensuel',
            appointmentDate: 'Date de rdv souhaité',            
            desiredSlot: 'Créneau souhaité'
        },
        errors: {
            advisor: "Erreur conseiller"
        }
    },
    filterComponent : {
        labels: {
            clearFilters: "Effacer les filtres",
            customerMeeting: "Les rendez-vous clients",
            meeting: "Mes RDVs",
            meetingUnassigned: "RDVs non attribués",
            startMeetingDate:"DÉBUT - DATE DE RDV",
            endMeetingDate:"FIN - DATE DE RDV",
        },
        placeholder: {
            start: "Début",
            end: "Fin",
            all: "(Tous)"
        }
    }
};
