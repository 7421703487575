import { useEffect, useState } from 'react';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { useCheckSSOAuthenticationCommand } from 'modules/auth/commands/checkSSOAuthenticationCommand';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';

export function PrivateGuardedRoute({ children }: any) {
  const renderTenantSelectionPage = useAppSelector(
    (s) => s.authentication.renderTenantSelectionPage
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isTenantWithAuthenticationModeSSO } = useTenantConfigCommand();  
  const { checkSSOAuthentication } = useCheckSSOAuthenticationCommand(); 
  const [renderChildren, setRenderChildren] = useState(false);
  const [lastPathNameCheck, setLastPathNameCheck] = useState<string>("");

  const routeONLYAllowForSSO = ['/customer-search', '/tenant-selection'];
  const routeNOTAllowForSSO = ['/admin', '/advisor-course/search-client', "/advisor-course/client-creation", "/", ""];
  async function checkCanAccessRoute() {
    
    const isTenantSSO = isTenantWithAuthenticationModeSSO();

    // CASE 1 check if tenant is SSO and route not allow
    if(isTenantSSO && routeNOTAllowForSSO.includes(pathname)) {
      checkSSOAuthentication();
      const redirectUri = renderTenantSelectionPage ? "/tenant-selection" : "/customer-search";
      navigate({
        pathname: redirectUri
      });
      return;
    } 
    

    // CASE 2 check if tenant is NOT SSO and route not allow
    if(!isTenantSSO && routeONLYAllowForSSO.includes(pathname)) {
        navigate({
          pathname: "/"
        });
        return;
    }

    // Specific Case for BCEF application, we could have no tenant defined
    // And offer the possibility to the user to choose one
    if (isTenantSSO && renderTenantSelectionPage) {
        if(pathname.includes('/tenant-selection')) {
          // No redirection necessary, only render the current page because we actually render the tennat selection page    
          setRenderChildren(true);
          return;
        }
        navigate({
          pathname: internalUrls.tenantSelection
        });
        return;
    }

    // Route OK => Right User and right page selected
    setRenderChildren(true);     
  }

  useEffect(() => {
    setLastPathNameCheck(pathname);
    checkCanAccessRoute();
  }, [pathname]);

  if (renderChildren && lastPathNameCheck === pathname) {
    return children;
  }

  return null;
}
