
import { useEffect, useState } from 'react';
import { useLoader } from "@ifs/libs";
import { getAcceptedEmailDomainsRequest } from '../requests/getAcceptedEmailDomains.request';

export function useResolver() {
  const [acceptedEmailDomains, setAcceptedEmailDomains] = useState<string[]>();
  const { showLoaderWhile } = useLoader();

  useEffect(() => {
    showLoaderWhile(async () => {
      const emailDomains = await getAcceptedEmailDomainsRequest();
      setAcceptedEmailDomains(emailDomains);
    }, {key: "authResolver", value:"API GET - /api/auth/accepted_email_domains"});
  }, []);

  return { acceptedEmailDomains };
}
