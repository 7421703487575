import { SvgWrapperComponent } from '@ifs/libs';
import { globali18n } from '@ifs/libs/src/i18n';
import { GetFinancialProfiles } from '@ifs/libs/src/modules/investor-profile/models/FinancialProfileModel';
import { Scenario } from '@ifs/libs/src/modules/investor-profile/models/RiskProfileEnum';
import { format } from 'date-fns';
import { MinusGreenIcon } from 'modules/customer-file/assets';
import { SearchRiskProfiles } from 'modules/customer-file/requests/getSearchRiskProfiles';
import moment from 'moment';
import { useState } from 'react';
import { ProductsType } from '../../../PropositionCard/ProductsType';
import { i18n } from '../i18n';
import { RiskIcon } from './images';
import styles from './InvestorProfileCard.module.scss';

const t = i18n.RiskProfileCard;

interface InvestorProfileCardProps {
  riskProfile: SearchRiskProfiles | undefined;
  financialProfile: GetFinancialProfiles | undefined;
}

export function InvestorProfileCard(props: InvestorProfileCardProps) {
  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  function handleIsDisplay() {
    setIsDisplay(!isDisplay);
  }

  const financialProfileResult = props.financialProfile!.results[0];

  let computedProfileIdentifier = props.riskProfile!.results[0].computedProfileId!;

  if (props.riskProfile!.results[0].preferredProfileId!) {
    computedProfileIdentifier = props.riskProfile!.results[0].preferredProfileId!;
  }

  //Retrieve the profile scale base on the computedProfileIdentifier
  const scale = props.riskProfile?.scale.filter((e) => e.textId === computedProfileIdentifier)[0]
    .srri;

  const profileDate =
    props.financialProfile!.results[0].validatedAt &&
    moment(new Date(props.financialProfile!.results[0].validatedAt)).format('YYYY-MM-DD');

  const listOfRestrictedEnvelop = props.financialProfile?.results[0].productTypeProfiles.filter(
    (e) => e.hasKAndE === false
  );

  return (
    <div className={styles.InvestorProfileCard}>
      <div onClick={handleIsDisplay}>
        <div className={styles.NeedsRetirement}>
          <SvgWrapperComponent ariaHidden={true}>
            <RiskIcon />
          </SvgWrapperComponent>
          <div className={styles.BolderPrimaryColor}>{t.title}</div>
          <div className={styles.Minus}>
            <SvgWrapperComponent ariaLabel={`${globali18n.ariaLabel.toggleMenu} ${t.title}`}>
              <MinusGreenIcon />
            </SvgWrapperComponent>
          </div>
        </div>
        {isDisplay && (
          <>
            <div className={styles.RiskProfileContainer}>
              <div className={styles.AmountTile}>
                <div>Profil de risque</div>
                <div className={styles.ItemMonthly}>
                  {t.scenario[computedProfileIdentifier as Scenario].toUpperCase()} {scale}/7
                </div>
              </div>

              <div className={styles.AmountTile}>
                <div>Profil financier</div>
                <div className={styles.ItemMonthly}>
                  {(financialProfileResult.computedProfileId || '').toLocaleUpperCase()}
                </div>
              </div>

              <div className={styles.AmountTile}>
                <div>Enveloppes interdites</div>
                <div className={styles.RestrictedEnvelop}>
                  {listOfRestrictedEnvelop?.map((e) => (
                    <div key={e.productTypeId} className={styles.EnvelopItem}>
                      {t.productType[
                        e.productTypeId as ProductsType
                      ].restricted.toUpperCase()}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {profileDate && (
              <div className={styles.ProfileDate}>
                Date du dernier profil : {format(new Date(profileDate), 'dd/MM/yyyy')}{' '}
                {financialProfileResult.isExpired && (
                  <span className={styles.ExpiredProfile}>(profile expiré)</span>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
