
import { useLoader } from "@ifs/libs";
import { apiUrls } from "shared/apiUrls";
import { AxiosResponse } from "axios";
import { SetCustomerInformationModel, putCustomer } from "../requests/putCustomerRequest";

export function usePutCustomerCommand() {
    const { showLoaderWhile } = useLoader();

    async function putCustomerCommand(customerId: string, params: SetCustomerInformationModel): Promise<AxiosResponse> {
        return await showLoaderWhile(async () => {
            return await putCustomer(customerId, params);
        }, {key: "putCustomerCommand", value:`API GET - ${apiUrls().customers.search}`})
    }

    return { putCustomerCommand };
}
