import { PropsWithChildren } from 'react';
import backArrow from './assets/back-arrow.svg';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { ButtonProps } from './Button.type';
import { Button as KendoButton } from '@progress/kendo-react-buttons';

export function Button(props: PropsWithChildren<ButtonProps>) {
  return (
    <KendoButton
      className={classNames(props.className)}
      onClick={props.onClick}
      disabled={props.disabled}
      imageAlt=""
      fillMode={props.fillMode}
    >
      <div className={styles.Button}>
        {props.backButton && <img src={backArrow} alt="back button" className={styles.BackIcon} />}
        <div>{props.children}</div>
      </div>
    </KendoButton>
  );
}