import { Button } from "shared";
import styles from "./CustomerAddEvents.module.scss";
import {i18n} from "../../i18n";
import classNames from "classnames";
import { useAnalytics } from "shared/Analytics/useAnalytics";
import buttonStyles from 'styles/ButtonStyle.module.scss';

export default function CustomerAddEvents(props: any) {
  const { simpleEvent } = useAnalytics();
  return (
    <div className={styles.AddEvents}>
      <div className={styles.AddEventActionsTitle}>
        {i18n.customerAddEventsComponent.title}
      </div>
      <div className={styles.AddEventNote}>
        {i18n.customerAddEventsComponent.note}
      </div>
      <div className={styles.AddEventActions}>
        <Button
          onClick={() => {
            simpleEvent("add_report")
            props.handleAddCR()}}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
        >{i18n.customerAddEventsComponent.labels.addClientEvent}</Button>
        <Button
          onClick={() => {
            simpleEvent("add_note")
            props.handleAddRemark()}}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
        >{i18n.customerAddEventsComponent.labels.addNote}</Button>
      </div>
    </div>
  );
}
