import { FooterLink } from '@ifs/libs/src/shared/models/TenantModel';
import { useExternalUrls } from '@ifs/libs/src/shared/utilities/externalUrls';
import { useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { i18n } from './i18n';
import classNames from 'classnames';

export function Footer(props: {customClass?:string}) {
  const { externalUrls } = useExternalUrls();
  const [dataProtectionUrl, setDataProtectionUrl] = useState<{ i18n: string; link: string }>({
    i18n: '',
    link: ''
  });

  const [cookiesPageUrl, setCookiesPageUrl] = useState<{ i18n: string; link: string }>({
    i18n: '',
    link: ''
  });

  useEffect(() => {
    (async () => {
      const urls = await externalUrls();
      setDataProtectionUrl({
        link: urls.AdvisorPrivacyPage,
        i18n: i18n.footerLink[FooterLink.PrivacyPage]
      });

      setCookiesPageUrl({
        link: '/cookies',
        i18n: i18n.footerLink[FooterLink.CookiesPage]
      });
    })();
  }, []);

  return (
    <div className={classNames(styles.Container, props.customClass)}>
      <div className={classNames(styles.LinkContainer, props.customClass)}>
        <a
          className={styles.Link}
          href={dataProtectionUrl.link}
          title={dataProtectionUrl.i18n}
          target="_blank"
          rel="noreferrer"
        >
          <span>{dataProtectionUrl.i18n}</span>
        </a>

        <a
          className={styles.Link}
          href={cookiesPageUrl.link}
          title={cookiesPageUrl.i18n}
          target="_blank"
          rel="noreferrer"
        >
          <span>{cookiesPageUrl.i18n}</span>
        </a>
      </div>
    </div>
  );
}
