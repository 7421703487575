export const cssVar = {
  '--advisor-primary-green': '#00915a',
  '--advisor-primary-green-box-shadow': '#009159ab',
  '--advisor-primary-green_10': '#EBF7F2',
  '--advisor-primary-green_15': '#D9EFE6',
  '--advisor-primary-green_20': '#CCE9DE',
  '--advisor-primary-green_50': '#80C8AD',
  '--advisor-secondary-orange': '#EE9100',
  '--advisor-secondary-moutarde': '#E7A600',
  '--advisor-secondary-moutarde_10': '#FDF7E6',
  '--advisor-secondary-turquoise': '#28C3A9',
  '--advisor-secondary-turquoise_10': '#EAF9F7',
  '--advisor-secondary-aubergine': '#A33467',
  '--advisor-secondary-aubergine_10': '#F6EBF0',
  '--advisor-secondary-ruddy_brown': '#bc5127',
  '--advisor-app-background': '#ebf7f2'
};
