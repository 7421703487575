import { useState, useEffect, FormEvent } from 'react';
import Cookies from "js-cookie";
import {
  Button,
  ButtonStyles,
  Container,
  SvgWrapperComponent,
  useLoader
} from '@ifs/libs';
import styles from './TenantSelectionPage.module.scss';
import {LeftIcon, CheckedIcon, UncheckedIcon }  from 'shared/assets';
import { globali18n } from 'i18n';
import classNames from 'classnames';
import { Footer } from 'shared/Footer/Footer';
import { logo } from './images';
import { ModuleStyles } from 'modules/auth/styles/ModuleStyles';
import { i18n } from './i18n';
import { useNavigate } from 'react-router-dom';
import { useLoadTenant } from 'shared/commands/loadTenant.command';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';

enum EnvironnementBCEF {
  BCEFRetail = 'BCEFRetail',
  BCEFPrivateBank = 'BCEFPrivateBank'
}


const t = i18n.TenantSelection;
export function TenantSelectionPage() {
  const [answerList, setAnswerList] = useState<{ label: string; value: EnvironnementBCEF }[]>([]);
  const [environnementBCEF, setEnvironnementBCEF] = useState<EnvironnementBCEF>();
  const { loadTenantCommand } = useLoadTenant();
  const { showLoaderWhile } = useLoader();
  let navigate = useNavigate();

  function createAnswerList() {
    const answers = [];
    answers.push({
      label: t.Environment[EnvironnementBCEF.BCEFRetail],
      value: EnvironnementBCEF.BCEFRetail
    });
    answers.push({
      label: t.Environment[EnvironnementBCEF.BCEFPrivateBank],
      value: EnvironnementBCEF.BCEFPrivateBank
    });
    setAnswerList(answers);
  }

  useEffect(() => {
    createAnswerList();
      const tenant =  Cookies.get('IfsTenant');
      //if We receive a tenant value throught the URL as uriParams
      if(tenant && [EnvironnementBCEF.BCEFPrivateBank, EnvironnementBCEF.BCEFRetail].includes(tenant as EnvironnementBCEF)) {
        setEnvironnementBCEF(tenant as EnvironnementBCEF);
      }
  }, []);

  function selectAnswer(value: EnvironnementBCEF) {
    setEnvironnementBCEF(value);
  }

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    showLoaderWhile(async () => {
    Cookies.set('IfsTenant', environnementBCEF || '', { expires: 1, path: '/', secure: true });
    await loadTenantCommand();
    navigate("/customer-search");
  }, {key: "AdvisorAppResolver", value:`API GET - ${apiUrls().tenants}}`});
  }


  return (
    <div className={styles.SearchClientContainer}>
      <header className={styles.Header}>
        <img src={logo} alt="logo" />
      </header>
      <div className={styles.Body}>
        <Container className={classNames(ModuleStyles.Grid, styles.BodyGrid)}>
          <div className={styles.FormContainer}>
            <h1> {t.title} </h1>
            <form className={styles.Form} noValidate onSubmit={onSubmit}>             
              <div className={styles.AnswerGrid}>
                <label className={styles.Label}>{t.labels.env}</label>
                <div className={styles.AnswerGrid}>
                  {answerList.map((a, i) => (
                    <Button
                      type="button"
                      className={classNames(
                        ButtonStyles.Answer,
                        styles.Answer,
                        a.value === environnementBCEF && styles.AnswerSelected
                      )}
                      key={a.value}
                      onClick={() => selectAnswer(a.value)}
                      selected={a.value === environnementBCEF}
                      autoFocus={a.value === environnementBCEF}
                    >
                      {a.label}
                      <span>
                        {a.value === environnementBCEF ? (
                          <SvgWrapperComponent ariaHidden={true}>
                            <CheckedIcon className={styles.CheckboxIcon} />
                          </SvgWrapperComponent>
                        ) : (
                          <SvgWrapperComponent ariaHidden={true}>
                            <UncheckedIcon className={styles.CheckboxIcon} />
                          </SvgWrapperComponent>
                        )}
                      </span>
                    </Button>
                  ))}
                </div>
              </div>

              <div className={styles.SubmitFormActionBox}>
                <Button disabled={!environnementBCEF} className={classNames(ButtonStyles.PrimaryAction)}>
                  {globali18n.labels.next}
                </Button>
              </div>
            </form>
          </div>
          <div className={styles.SVGContainer}>
            <SvgWrapperComponent ariaHidden={true}>
              <LeftIcon className={styles.Icon} />
            </SvgWrapperComponent>
          </div>
        </Container>
      </div>
      <Footer customClass={styles.FooterCustom} />
    </div>
  );
}
