import { ChangeEvent, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './RegisterPage.module.scss';
import classNames from 'classnames';
import { ModalContext } from 'context/modalContext';
import { Button } from 'shared';
import { AuthIcon, CheckmarkIcon, RedCrossIcon } from 'modules/auth/assets';
import { i18n } from 'modules/auth/i18n';
import { globali18n } from 'i18n';
import { useResolver } from 'modules/auth/resolver/resolver';
import { useRegisterUserCommand } from 'modules/auth/commands/registerUserCommand';
import { SvgWrapperComponent, TextInput } from '@ifs/libs';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import { Footer } from 'shared/Footer/Footer';
import buttonStyles from 'styles/ButtonStyle.module.scss';

export function RegisterPage() {
  let { handleModal } = useContext(ModalContext);
  const { simpleEvent, pageView } = useAnalytics();
  let navigate = useNavigate();
  const { acceptedEmailDomains } = useResolver();

  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [secondPassword, setSecondPassword] = useState<string>('');
  const [lengthOfPassword, setLengthOfPassword] = useState<boolean>(false);
  const [asOneCaps, setAsOneCaps] = useState<boolean>(false);
  const [asLowerCase, setAsLowerCase] = useState<boolean>(false);
  const [asNumber, setAsNumber] = useState<boolean>(false);
  const [asSpecialChar, setAsSpecialChar] = useState<boolean>(false);
  const [asNoSpecial, setAsNoSpecial] = useState<boolean>(false);
  const [createAccountButton, setCreateAccountButton] = useState<boolean>(true);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [emailDomainIsValid, setEmailDomainIsValid] = useState<boolean>(false);
  const [passwordIsCorrect, setPasswordIsCorrect] = useState<boolean>(false);
  const { registerUserCommand } = useRegisterUserCommand();

  const handleRegister = async () => {
    try {
      const body = {
        firstName: firstname,
        lastName: lastname,
        email: email,
        password: password
      };
      await registerUserCommand(body);
      handleModalCtx();
    } catch (error) {
      return error;
    }
  };

  const isConfirmPasswordIncorrect = (): boolean => {
    return !!secondPassword && password !== secondPassword;
  };

  const handleRegExPassword = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const symbolRegex = /[ !"#$%&'()*+,-./:;<=>?@\\\[\]^_`{|}~]/;

    const allRegexes = [uppercaseRegex, lowercaseRegex, numberRegex, symbolRegex];

    function noOther(value: string, regexes: RegExp[]) {
      const chars = Array.from(value);

      // Are there caracters such that no regex matches them?
      const hasOthers = chars.some((c) => !regexes.some((r) => r.test(c)));

      return !hasOthers;
    }

    if (noOther(value, allRegexes)) {
      setAsNoSpecial(true);
    } else {
      setAsNoSpecial(false);
    }

    if (value.length >= 8) {
      setLengthOfPassword(true);
    } else {
      setLengthOfPassword(false);
    }

    if (value.match(uppercaseRegex)) {
      setAsOneCaps(true);
    } else {
      setAsOneCaps(false);
    }

    if (value.match(lowercaseRegex)) {
      setAsLowerCase(true);
    } else {
      setAsLowerCase(false);
    }

    if (value.match(numberRegex)) {
      setAsNumber(true);
    } else {
      setAsNumber(false);
    }

    if (value.match(symbolRegex)) {
      setAsSpecialChar(true);
    } else {
      setAsSpecialChar(false);
    }

    setPassword(event.target.value);
  };

  const handleRegExEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    setEmail(value);

    if (value.match(/^\S+@\S+\.\S+$/)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
    setEmailDomainIsValid(isRighDomainEntered(value));
  };

  useEffect(() => {
    if (
      asOneCaps &&
      asLowerCase &&
      asNumber &&
      asSpecialChar &&
      asNoSpecial &&
      emailIsValid &&
      emailDomainIsValid &&
      firstname.length >= 2 &&
      lastname.length >= 2 &&
      password === secondPassword
    ) {
      setCreateAccountButton(false);
    } else {
      setCreateAccountButton(true);
    }

    if (asOneCaps && asLowerCase && asNumber && asSpecialChar && asNoSpecial) {
      setPasswordIsCorrect(true);
    } else {
      setPasswordIsCorrect(false);
    }
  }, [
    asOneCaps,
    asLowerCase,
    asNumber,
    asSpecialChar,
    asNoSpecial,
    emailDomainIsValid,
    emailIsValid,
    firstname,
    lastname,
    password,
    secondPassword
  ]);

  const isRighDomainEntered = (emailValue: string) => {
    if (!acceptedEmailDomains || !acceptedEmailDomains.length) return true;
    return !!acceptedEmailDomains.some((url) => emailValue.includes(url));
  };

  const handleModalCtx = () => {
    pageView({
      page_title: i18n.registerPage.title,
      page_path: '/auth/register',
      page_type: 'popin'
    });
    return handleModal(
      <div>
        <div className={styles.ModalBody}>{i18n.registerPage.labels.inscriptionPending}</div>

        <div className={styles.ModalActions}>
          <Button
            className={classNames(buttonStyles.BaseButton, styles.FilledButtonPrimary)}
            onClick={() => {
              handleModal('close');
              navigate({
                pathname: '/auth/login',
                search: new URLSearchParams({ email }).toString()
              });
            }}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const returnIcon = (condition: boolean) =>
    condition ? <CheckmarkIcon width={'25px'} /> : <RedCrossIcon width={'15px'} />;

  return (
    <div className={styles.RegisterContainer}>
      <div className={styles.Header}>
        <h1>{i18n.registerPage.title}</h1>
        <span>{i18n.registerPage.subTitle}</span>
      </div>
      <div className={styles.Body}>
        <div>
        <div className={styles.Input}>
            <label>{globali18n.labels.addressMail}</label>
            <TextInput
              type={'email'}
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                handleRegExEmail(event);
              }}
              placeholder={globali18n.labels.addressMail}
            />
          </div>
          {emailIsValid && !emailDomainIsValid && (
            <div className={styles.EmailDomainError}>{i18n.registerPage.errors.emailDomain}</div>
          )}

          <div className={styles.Input}>
            <label>{i18n.registerPage.labels.firstName}</label>
            <TextInput
              type={'text'}
              value={firstname}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                setFirstname(event.target.value);
              }}
              placeholder={i18n.registerPage.labels.firstName}
            />
          </div>

          <div className={styles.Input}>
            <label>{i18n.registerPage.labels.lastName}</label>
            <TextInput
              type={'text'}
              value={lastname}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                setLastname(event.target.value);
              }}
              placeholder={i18n.registerPage.labels.lastName}
            />
          </div>

          <div className={styles.Input}>
            <label>{globali18n.labels.password}</label>
            <TextInput
              title={globali18n.labels.password}
              type={'password'}
              value={password}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                handleRegExPassword(event);
              }}
              placeholder={globali18n.labels.password}
              name={'password'}
            />
          </div>
          <div className={styles.ValidationBox}>
            <div
              className={classNames({
                [styles.PasswordCheckSuccess]: lengthOfPassword,
                [styles.PasswordCheckError]: !lengthOfPassword
              })}
            >
              {returnIcon(lengthOfPassword)}
              <span>{i18n.registerPage.passwordValidation.atLeast8Characters}</span>
            </div>
            <div
              className={classNames({
                [styles.PasswordCheckSuccess]: asOneCaps,
                [styles.PasswordCheckError]: !asOneCaps
              })}
            >
              {returnIcon(asOneCaps)}
              <span>{i18n.registerPage.passwordValidation.atLeastOneUpperCase}</span>
            </div>
            <div
              className={classNames({
                [styles.PasswordCheckSuccess]: asLowerCase,
                [styles.PasswordCheckError]: !asLowerCase
              })}
            >
              {returnIcon(asLowerCase)}
              <span>{i18n.registerPage.passwordValidation.atLeastOneToLowerCase}</span>
            </div>
            <div
              className={classNames({
                [styles.PasswordCheckSuccess]: asNumber,
                [styles.PasswordCheckError]: !asNumber
              })}
            >
              {returnIcon(asNumber)}
              <span>{i18n.registerPage.passwordValidation.atLeastOneNumber}</span>
            </div>
            <div
              className={classNames({
                [styles.PasswordCheckSuccess]: asSpecialChar,
                [styles.PasswordCheckError]: !asSpecialChar
              })}
            >
              {returnIcon(asSpecialChar)}
              <span>{i18n.registerPage.passwordValidation.atLeastOneSpecialCharacter}</span>
            </div>
            <div
              className={classNames({
                [styles.PasswordCheckSuccess]: asNoSpecial,
                [styles.PasswordCheckError]: !asNoSpecial
              })}
            >
              {returnIcon(asNoSpecial)}
              <span>{i18n.registerPage.passwordValidation.noOtherSpecialCharacter}</span>
            </div>
          </div>
          <div className={styles.Input}>
            <label>{i18n.registerPage.labels.confirmPassword}</label>
            <TextInput
              title={i18n.registerPage.labels.confirmPassword}
              type={'password'}
              value={secondPassword}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                setSecondPassword(event.target.value);
              }}
              placeholder={i18n.registerPage.labels.confirmPassword}
              name={'repeatPassword'}
            />
          </div>
          {isConfirmPasswordIncorrect() && (
            <div className={styles.PasswordConfirmError}>
              {i18n.registerPage.errors.passwordsDifferents}
            </div>
          )}

          <div className={styles.ActionBox}>
            <Button
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
              onClick={(): void => {
                if (!createAccountButton) {
                  handleRegister();
                }
              }}
              disabled={createAccountButton}
            >
              {i18n.registerPage.labels.createAccount}
            </Button>
            <Button
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
              onClick={() => {
                simpleEvent('back_account');
                navigate('/auth');
              }}
            >
              {globali18n.labels.back}
            </Button>

            <a
              href="#"
              onClick={() => {
                simpleEvent('already_registered');
                navigate('/auth/login');
              }}
            >
              {i18n.registerPage.labels.alreadyAnAccount}
            </a>
          </div>
        </div>
        <div className={styles.RegisterImage}>
          <SvgWrapperComponent ariaHidden={true}>
            <AuthIcon />
          </SvgWrapperComponent>
        </div>
      </div>
      <Footer />
    </div>
  );
}
