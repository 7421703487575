export const i18n = {
    CustomerSearch: {
        title: "Recherche client",
        labels: {
            uid: "Identifiant unique du client :",
        },
    },
    noSearchModal: {
        title: "Aucun résultat ne correspond à votre recherche",
        body: (
            <>
                <p>Il semblerait que le client MonDemain recherché n'existe pas.</p>
                <p>Souhaitez-vous créer un nouveau client MonDemain associé à cet identifiant unique ?</p>
            </>
        ),
        okText: "Créer"

    }
};
