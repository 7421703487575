import { useState, useEffect } from 'react';
import { Button } from '@ifs/libs';
import { AppointmentReportActionPayload } from 'modules/customer-file/models/advisorCustomerAction';
import { createAppointmentReportRequest } from 'modules/customer-file/requests/createAppointmentReportRequest';
import moment from 'moment';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import styles from './CRActionBox.module.scss';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import classNames from 'classnames';
import { globali18n } from 'i18n';
import {
  EAppointmentMotive,
  EPersonalizedFollowup,
  ESubscriptionDecision
} from 'modules/customer-file/enums';
import { updateAppointmentReportRequest } from 'modules/customer-file/requests/updateAppointmentReportRequest';
import { deleteAppointmentReportRequest } from 'modules/customer-file/requests/deleteAppointmentReportRequest';
import { EAdvisorCustomerActiontype } from '../AdvisorCustomerActionType.enum';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';

interface CRActionBoxProps {
  crDate: string;
  selectedMotive: EAppointmentMotive;
  selectedSubscribe: ESubscriptionDecision;
  personalizedFollowup: EPersonalizedFollowup;
  crRemark: string;
  onValidate: () => void;
  cancelAddCR: () => void;
  setCountChars: (arg0: number) => void;
  setCrRemark: (arg0: string) => void;
  isDateInFuture: boolean;
  editMode: boolean;
  identifier: string | null | undefined;
  crData: any;
}

export function CRActionBox(props: CRActionBoxProps) {
  const { simpleEvent, createCREvent } = useAnalytics();
  const [currentAdvisorId, setCurrentAdvisorId] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const { getAdvisorId } = useTenantConfigCommand();

  useEffect(()=> {    
    const advisorId = getAdvisorId();
    setCurrentAdvisorId(advisorId || '');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setCustomerId(urlParams.get('customerid') || '');
  }, [])

  const postAppointmentRequest = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const customerid = urlParams.get('customerid');
    if (!customerid) return;

    await createAppointmentReportRequest({ customerId: customerid }, createReportPayload());
    props.onValidate();
  };

  const createReportPayload = (): AppointmentReportActionPayload => {
    createCREvent({
      date: moment(props.crDate).format('YYYY-MM-DD'),
      meeting_purpose: props.selectedMotive,
      subscription: props.selectedSubscribe,
      personnalized_follow_up: props.personalizedFollowup,
      comments: props.crRemark
    });
    return {
      $type: EAdvisorCustomerActiontype.AppointmentReport,
      advisorId: currentAdvisorId,
      customerId,
      motive: props.selectedMotive,
      subscription: props.selectedSubscribe,
      personalizedFollowup: props.personalizedFollowup,
      remark: props.crRemark || null,
      appointmentDate: moment(props.crDate).format('YYYY-MM-DD')
    };
  };

  const editCR = async () => {
    await updateAppointmentReportRequest(
      { appointmentReportIdentifier: props.identifier! },
      createReportPayload()
    );
    props.onValidate();
  };

  const deleteCR = async () => {
    await deleteAppointmentReportRequest({ appointmentReportIdentifier: props.identifier! });
    props.onValidate();
  };


  return (
    <>
      {props.crData !== undefined && props.editMode ? (
        <EditActionBox
          cancelAddCR={props.cancelAddCR}
          deleteCR={deleteCR}
          isDateInFuture={props.isDateInFuture}
          editCR={editCR}
        />
      ) : <></>}

      {props.crData === undefined && (
        <div className={styles.ActionBox}>
          <Button
            onClick={() => {
              simpleEvent('cancel_report');

              props.setCountChars(0);
              props.cancelAddCR();
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          >
            {globali18n.labels.back}
          </Button>

          <Button
            onClick={() => {
              if (!props.isDateInFuture) {
                postAppointmentRequest();
                props.cancelAddCR();
                props.setCrRemark('');
              }
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
            disabled={props.isDateInFuture}
          >
            {globali18n.labels.validate}
          </Button>
        </div>
      )}
    </>
  );
}

interface EditActionBoxProps {
  cancelAddCR: () => void;
  deleteCR: () => void;
  isDateInFuture: boolean;
  editCR: () => void;
}

function EditActionBox(props: EditActionBoxProps) {
  const { simpleEvent } = useAnalytics();

  return (
    <div className={styles.ActionBox}>
      <Button
        onClick={() => {
          simpleEvent('cancel_report');
          props.deleteCR();
        }}
        className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
      >
        {globali18n.labels.delete}
      </Button>

      <Button
        onClick={() => {
          props.cancelAddCR();
        }}
        className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
        disabled={props.isDateInFuture}
      >
        {globali18n.labels.cancel}
      </Button>

      <Button
        onClick={() => {
          if (!props.isDateInFuture) {
            props.editCR();
          }
        }}
        className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
        disabled={props.isDateInFuture}
      >
        {globali18n.labels.validate}
      </Button>
    </div>
  );
}
