import { useEffect, useState } from 'react';
import { Filter } from './Component/Filter/Filter';
import { CustomerAppointmentBody } from './Component/CustomerAppointmentBody/CustomerAppointmentBody';
import Pagination from 'react-responsive-pagination';
import 'bootstrap/dist/css/bootstrap.css';
import styles from './CustomerAppointmentPage.module.scss';
import moment from 'moment';
import { FilterInterface } from 'models/filter';
import { PageWithMenu } from 'shared';
import { i18n } from 'modules/appointments/i18n';
import { useSearchAppointmentCommand } from 'modules/appointments/commands/searchAppointmentCommand';
import { EAppointmentStatus } from 'modules/appointments/enums';
import { AdvisorResponseModel, getAdvisorRequest } from 'shared/request/getAdvisorRequest';
import { searchAppointmentRequest } from 'modules/appointments/requests/searchAppointmentRequest';
import { assignCustomerToAdvisorRequest } from 'shared/request/assignCustomerToAdvisorRequest';
import { deassignCustomerToAdvisorRequest } from 'shared/request/deassignCustomerToAdvisorRequest';
import { useUpdateAppointmentStatusCommand } from 'modules/appointments/commands/updateAppointmentStatusCommand';
import { AppointmentResult } from 'modules/appointments/models';

let activeSearch: FilterInterface = {} as FilterInterface;
export function CustomerAppointmentPage() {
  const [appointmentsList, setAppointmentsList] = useState<AppointmentResult[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [params, setParams] = useState<CustomerAppointmentPageSearchParams>();
  const [isReloadCustomerCount, setIsReloadCustomerCount] = useState<boolean>(false);
  const { searchAppointmentCommand } = useSearchAppointmentCommand();
  const { updateAppointmentStatusCommand } = useUpdateAppointmentStatusCommand();

  const handleSearch = async (form: FilterInterface) => {
    activeSearch = { ...form };
    if (form.isReset) {
      setParams({});
      const response = await searchAppointmentCommand({ offset: 0, limit: 10 });
      if (response) {
        setAppointmentsList(response.results);
        setCurrentPage(1);
        setTotalPages(Math.ceil(response.pagination!.totalResultsCount/response.pagination!.resultsCount));
      }
    } else {
      const searchParams = createSearchParamsRequest(form);
      setParams(searchParams);

      const response = await searchAppointmentCommand({
        offset: 0,
        limit: 10,
        ...searchParams
      });
      if (response) {
        setAppointmentsList(response.results);
        setCurrentPage(1);
        setTotalPages(Math.ceil(response.pagination!.totalResultsCount/response.pagination!.resultsCount));
      }
    }
  };

  const fetchListOfAdvisors = async () : Promise<AdvisorResponseModel[]> =>  {
    const response = await getAdvisorRequest();
    return response;
  };

  // get appointment list without loader
  const fetchAppointmentsList = async (customParams?: CustomerAppointmentPageSearchParams) => {
    const defaultParams = { offset: 0, limit: 10, ...params };
    const requestParams = !!customParams ? { ...defaultParams, ...customParams } : defaultParams;
    const response = await searchAppointmentRequest(requestParams);
    return response;
  };

  const assignCustomerToAdvisor = async (customerIdentifier: string) => {
    await assignCustomerToAdvisorRequest({ customerId:customerIdentifier });
    const response = await fetchAppointmentsList();
    if (response) {
      setAppointmentsList(response.results);
    }
  };

  const unAssignCustomerFromAdvisor = async (customerIdentifier: string) => {
    await deassignCustomerToAdvisorRequest({ customerId:customerIdentifier });
    const response = await fetchAppointmentsList();
    if (response) {
      setAppointmentsList(response.results);
    }
  };

  const changeAppointmentStatut = async (
    customerIdentifier: string,
    appointmentIdentifier: string,
    newStatut: EAppointmentStatus
  ) => {
    await updateAppointmentStatusCommand(appointmentIdentifier, {status: newStatut});
    if (activeSearch) {
      return handleSearch(activeSearch);
    }
    const response = await fetchAppointmentsList();
    if (response) {
      setAppointmentsList(response.results);
      setIsReloadCustomerCount(false);
      setIsReloadCustomerCount(true);
    }
  };

  const changePaginationIndex = async (index: number) => {
    setCurrentPage(index);
    const limit = 10;
    const offset = (index - 1 )* limit;
    const response = await searchAppointmentCommand({ ...params, offset, limit });
    if (response) {
      setAppointmentsList(response.results);
    }
  };

  const fetchUnttributeAppointment = async () => {
    const response = await fetchAppointmentsList({
      advisorAssignmentFilterType: 'WithoutAdvisorOnly'
    });
    if (response) {
      setAppointmentsList(response.results);
      setCurrentPage(1);
    }
  };

  const getMyAppointments = async () => {
    let userIdentifier = sessionStorage.getItem('identifier');

    const response = await fetchAppointmentsList({
      advisorAssignmentFilterType: 'Selection',
      advisorId: [userIdentifier as string]
    });
    if (response) {
      setAppointmentsList(response.results);
      setTotalPages(Math.ceil(response.pagination!.totalResultsCount/response.pagination!.resultsCount));      
    }
  };

  const createSearchParamsRequest = (form: FilterInterface): CustomerAppointmentPageSearchParams => {
    const params = {} as CustomerAppointmentPageSearchParams;
    //Generate Query string base on state
    Object.entries(form).map(([key, value]) => {
      if (value !== '' && key !== 'isReset') {
        if (key === 'StartDesiredDate' || key === 'EndDesiredDate') {
          value = moment(new Date(value as Date)).format('YYYY-MM-DD');
        }

        // Property StatusList & AdvisorIdentifierList type is equal to Array<{label: string, value:string}>
        // (kendo dropdown value type)
        if (key === 'SelectedStatut') {
          if ((value as Array<string>).length !== 0) {
            params['status'] = (value as Array<{ label: string; value: string }>).map(
              (val) => val.value
            ) as EAppointmentStatus[];
          }
        } else if (key === 'SelectedAdvisor') {
          // For SelectedAdvisor need to check if some advisor was selected
          if ((value as Array<string>).length !== 0) {
            let isNone = (value as Array<any>).find((o) => o.value === 'none');

            if (!isNone) {
              params['advisorAssignmentFilterType'] = 'Selection';
              params['advisorId'] = (
                value as Array<{ label: string; value: string, id: string }>
              ).map((val) => val.value);
            } else {
              // None advisor selected
              params['advisorAssignmentFilterType'] = 'WithoutAdvisorOnly';
            }
          }
        } else {
          // Default params value
          const keyFormated = key.charAt(0).toLowerCase() + key.substring(1);
          (params as any)[keyFormated] = value;
        }
      }
    });
    return params;
  };

  useEffect(() => {
    (async () => {     
      const response = await searchAppointmentCommand({ offset: 0, limit: 10 });
      if (response) {
        setAppointmentsList(response.results);
        setCurrentPage(1);
        setTotalPages(Math.ceil(response.pagination!.totalResultsCount/response.pagination!.resultsCount));
        setIsReloadCustomerCount(true);
      }
    })();
  }, []);


  return (
    <PageWithMenu reloadCounterCustomer={isReloadCustomerCount}>
      <div className={styles.AppointmentBody}>
        <Filter
          applyFilter={handleSearch}
          listOfAdvisors={fetchListOfAdvisors}
          unAssignedAppointment={fetchUnttributeAppointment}
          getUserAppointment={getMyAppointments}
        />

        {appointmentsList.length !== 0 ? (
          <div className={styles.Container}>
            <CustomerAppointmentBody
              listOfAppointments={appointmentsList}
              assignCustomer={assignCustomerToAdvisor}
              unAssignCustomer={unAssignCustomerFromAdvisor}
              changeAppointmentStatut={changeAppointmentStatut}
            />

            <Pagination
              current={currentPage}
              total={totalPages}
              onPageChange={changePaginationIndex}
            />
          </div>
        ) : (
          <div className={styles.Container}>
            <div className={styles.NoResults}>
              <span>{i18n.customerAppointmentPage.labels.notFound}</span>
            </div>
          </div>
        )}
      </div>
    </PageWithMenu>
  );
}

export type CustomerAppointmentPageSearchParams = {
  startDate?: string;
  endDate?: string;
  advisorAssignmentFilterType?: string;
  advisorId?: string[];
  status?: EAppointmentStatus[];
  customerId?: string;
  customerFirstName?: string;
  customerLastName?: string;
};
