import { CustomerStatus } from "models/customer-status";

export const globali18n = {
  title: 'Espace Conseiller',
  labels: {
    back: 'Retour',
    cancel: 'Annuler',
    confirm: "Confirmer",
    continue: 'Continuer',
    connect: "Se connecter",
    next: "Suivant",
    delete: "Supprimer",
    search: 'Rechercher',
    validate: 'Valider',
    resetPassword: 'Réinitialiser votre mot de passe',
    none: 'Aucun',
    euros: '€',
    yes: "Oui",
    no: "Non",
    of: 'de',
    to: 'à',
    day: "jour",
    dataLoading: "Chargement des informations",
    superAdmin: "Super Admin",
    days: "jours",
    years: "ans",
    eurosPerMonth: '€/mois',
    eurosPerYear: '€/an',
    tmi: "TMI",
    realEstate: "Immobilier",
    lifeInsurance: "Assurance vie",
    retirementSavingsPlan: "Plan Epargne Retraite",
    pea: "PEA",
    timePeriodPlural: {
      years: 'ans',
      months: 'mois'
    },
    firstName: "Prénom",
    lastName: "Nom",
    addressMail: "Adresse e-mail",
    email: "Email",
    password: "Mot de passe",
    telephone: "Tél",
    clientBNPP: "Client BNPP",
    postalCode: "Code postal",
    clientBNPPDeclared: "Client BNPP déclare",
    advisor: "Conseiller",
    advisorArea: "Espace Conseiller",
    statut: 'Statut',
    spamWarning: 'Si vous ne recevez pas cet email\u00A0: pensez à vérifier vos spams',
  },
  menu: {
    administration: "Administration",
    logout: "Déconnexion",
    customerMeeting: "RDV clients"
  },
  status: {
    [CustomerStatus.Requested]: "Demandé",
    [CustomerStatus.PendingConfirmation]: "En Attente",
    [CustomerStatus.Confirmed]: "Confirmé",
    [CustomerStatus.Honored]: "Honoré",
    [CustomerStatus.Canceled]: "Annulé",
  }
}

export type GlobalI18nType = typeof globali18n;