import { globali18n } from 'i18n';
import styles from './PatrimonTile.module.scss';
import { number } from 'Utils/Splitter';
import { i18n } from '../../i18n';
import classNames from 'classnames';
interface PatrimonTileProps {
  productName?: string;
  currentAmount?: number;
  monthlySavings?: number;
  type: string;
  isMainResidence?: boolean;
}

export function PatrimonTile(props: PatrimonTileProps) {
  if (props.type === 'savingAccount') {
    return (
      <div className={styles.Tile} key={Math.floor(Math.random() * 956)}>
        <div>{props.productName}</div>
        <div>
          <span className={styles.Value}>
            {number(props.currentAmount)}
            {globali18n.labels.euros}
          </span>
          <span className={styles.Value}>{i18n.monthlyAmount(Number(props.monthlySavings))}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.Tile} key={Math.floor(Math.random() * 956)}>
        <div className={styles.ResidenceName}>{props.productName}</div>
        <div
          className={classNames({
            [styles.RealEstateTags]: props.isMainResidence
          })}
        >
          <span className={styles.Value}>
            {number(props.currentAmount)}
            {globali18n.labels.euros}
          </span>
          {props.isMainResidence && <span className={styles.Value}>{i18n.PatrimonyCard.mainResidence}</span>}
        </div>
      </div>
    );
  }
}
