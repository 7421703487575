import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  ChangeEventHandler,
  CSSProperties,
  KeyboardEventHandler,
} from "react";

import styles from "./Input.module.scss";

export type InputProps = {
  title?: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
  type: string | (string & {}) | undefined;
  value: string | number | readonly string[] | undefined;
  disabled?: boolean | undefined;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string | undefined;
  customInputStyle?: CSSProperties | undefined;
  customLabelStyle?: CSSProperties | undefined;
  hideLabel?: boolean | undefined;
  name?: string | undefined;
  readonly?: boolean | undefined;
  min?: string | undefined;
  max?: string | undefined;
  maxlength?: number | undefined;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
  suffixContent?: any;
};

export function Input(props: InputProps) {
  return (
    <div className={styles.InputContainer}>
      {!props.hideLabel && (
        <span
          className={styles.InputText}
          style={{ ...props.customLabelStyle }}
        >
          {props.title}
        </span>
      )}
      <br />
      <div className={styles.InputGridContainer}>
        <input
          className={styles.Input}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.type !== "date" ? props.placeholder : "dd/mm/yyyy"}
          required
          pattern={props.type === "date" ? "[0-9]{2}/[0-9]{2}/[0-9]{4}" : ""}
          style={{ ...props.customInputStyle }}
          name={props.name}
          readOnly={props.readonly}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          maxLength={props.maxlength}
          onKeyDown={props.onKeyDown}
        />
        {props.suffixContent}
      </div>
    </div>
  );
}
