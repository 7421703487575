import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function PublicGuardedRoute({ children }: any) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isTenantWithAuthenticationModeSSO } = useTenantConfigCommand();

  const ssoLoginRoutes = [internalUrls.authentication.ssoLogin];
  async function checkCanAccessRoute() {
    const isASSOAuthenticationRoute = ssoLoginRoutes.includes(pathname);
    const isTenantSSO = isTenantWithAuthenticationModeSSO();

    if (isASSOAuthenticationRoute) {
      // CASE 1: IF Tenant NOT handle SSO login, then he cannot access to ssoLoginRoutes
      if (!isTenantSSO) {
        navigate({
          pathname: internalUrls.authentication.login
        });
        return;
      }
    } else {
      // CASE 2: Check IF tenant handle SSO, if true he cannot accees to the pathaname enter, because if not a loginSSORoutes
      if (isTenantSSO) {
        navigate({
          pathname: internalUrls.authentication.ssoLogin
        });
        return;
      }
    }
  }

  useEffect(() => {
    checkCanAccessRoute();
  }, [pathname]);

  return children;
}
