import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ResetPasswordPage.module.scss";
import { Button, Input } from "shared";
import { AuthIcon } from "modules/auth/assets";
import { i18n } from "modules/auth/i18n";
import { globali18n } from "i18n";
import { grey100, label, white } from "styles/colors";
import { AxiosOutcome } from "shared/enums/axiosOutcome.enum";
import { useRequestResetPasswordCommand } from "modules/auth/commands/requestResetPasswordCommand";
import classNames from "classnames";
import { SvgWrapperComponent } from "@ifs/libs";
import { useAnalytics } from "shared/Analytics/useAnalytics";
import buttonStyles from 'styles/ButtonStyle.module.scss';

export function ResetPasswordPage() {
  const { simpleEvent } = useAnalytics()
  let navigate = useNavigate();
  const [emailValue, setEmailValue] = useState<string>('');
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(true);
  const { requestResetPasswordCommand } = useRequestResetPasswordCommand();

  const handleResetPassword = async () => {
    const body = {
      email: emailValue,
    };

    const response = await requestResetPasswordCommand(body);
    simpleEvent("password_reseted");

    if (response === AxiosOutcome.Success) {
      navigate('/auth/password-reset/email');
    } else {
      setErrorDisplayed(true);
    }
  };

  const handleRegExEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    setEmailValue(value);

    if (value.match(/^\S+@\S+\.\S+$/)) {
      setButtonIsDisabled(false);
    } else {
      setButtonIsDisabled(true);
    }
  };

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.Header}>
        <h1>{i18n.resetPasswordPage.title}</h1>
        <span>{i18n.resetPasswordPage.subTitle}</span>
      </div>
      <div className={styles.Body}>
        {errorDisplayed ? <span>{i18n.resetPasswordPage.errors.mail}</span> : ''}
        <div className={styles.InputBloc}>
          <div>
            <Input
              title={globali18n.labels.addressMail}
              maxlength={255}
              type={'email'}
              value={emailValue}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                handleRegExEmail(event);
              }}
              customInputStyle={{
                border: `${grey100} 1px solid`,
                width: '720px',
                backgroundColor: white,
                borderRadius: '8px',
                marginLeft: '30px'
              }}
              customLabelStyle={{
                fontSize: '17px',
                fontWeight: 'bold',
                color: label,
                marginLeft: '30px'
              }}
              placeholder={globali18n.labels.addressMail}
              hideLabel={false}
              name={'email'}
            />
          </div>
          <div className={styles.ActionBox}>
            <Button
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
              onClick={(): void => {
                handleResetPassword();
              }}
              disabled={buttonIsDisabled}
            >
              {globali18n.labels.resetPassword}
            </Button>
            <Button
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
              onClick={() => {
                simpleEvent("back_reset");
                navigate('/auth');
              }}
            >
              {globali18n.labels.back}
            </Button>
          </div>
        </div>

        <div className={styles.BackgroundIcon}>
            <SvgWrapperComponent ariaHidden={true}><AuthIcon /></SvgWrapperComponent>
        </div>
      </div>
    </div>
  );
}
