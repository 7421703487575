enum EnvironnementBCEF {
    BCEFRetail = "BCEFRetail",
    BCEFPrivateBank = "BCEFPrivateBank",
}

export const i18n = {
    TenantSelection: {
        title: "Avant de commencer",
        labels: {
            env: "Choisissez votre environnement :"
        },
        Environment: {
            [EnvironnementBCEF.BCEFPrivateBank]: "BCEF Banque Privée",
            [EnvironnementBCEF.BCEFRetail]: "BCEF Retail"
        }
    }
};
