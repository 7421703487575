import { useState, useEffect } from 'react';
import styles from './CustomerCRComponent.module.scss';
import dashedLine from '../assets/dashlane.svg';
import remarkIcon from '../assets/remark.svg';
import moment from 'moment';
import editIcon from '../assets/edit.svg';
import { i18n } from '../../../i18n';
import {
  EAppointmentMotive,
  EPersonalizedFollowup,
  ESubscriptionDecision
} from 'modules/customer-file/enums';
import {
  AdvisorActionAppointmentReport
} from 'modules/customer-file/models/advisorCustomerAction';
import { TextInput } from '@ifs/libs/src/shared';

import { CRActionBox } from './CRActionBox';
import { DateAndReason } from './CRBloc/DateAndReason/DateAndReason';
import { MultiChoisesButton } from './CRBloc/MultiChoisesButton/MultiChoisesButton';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';
import { useGetAdvisorNameCommand } from 'shared/commands/getAdvisorNameCommand';

interface CustomerCRComponentProps {
  onValidate: () => void;
  cancelAddCR: () => void;
  crData?: AdvisorActionAppointmentReport;
}

export function CustomerCRComponent(props: CustomerCRComponentProps) {
  const defaultDate = moment(new Date()).format('YYYY-MM-DD');
  const { getAdvisorId } = useTenantConfigCommand();
  const { getAdvisorName } = useGetAdvisorNameCommand();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [selectedMotive, setSelectedMotive] = useState<EAppointmentMotive>(
    EAppointmentMotive.SavingsSolutionDetail
  );
  const [selectedSubscribe, setSelectedSubscribe] = useState<ESubscriptionDecision>(
    ESubscriptionDecision.NotYet
  );
  const [personalizedFollowup, setPersonalizedFollowup] = useState<EPersonalizedFollowup>(
    EPersonalizedFollowup.Partially
  );
  const [countChars, setCountChars] = useState<number>(0);
  const [crRemark, setCrRemark] = useState<string>('');

  const [date, setDate] = useState<string>(defaultDate);
  const [isDateInFuture, setIsDateInFuture] = useState<boolean>(false);
  const [ disabled, setDisabled ] = useState<boolean>(props.crData !== undefined)

  function handleChangeDate(event: string) {
    setDate(event);
    return;
  }

  function handleChangeIsDateInFuture(isFuture: boolean) {
    setIsDateInFuture(isFuture);
    return;
  }

  function handleChangeMotive(newMotive: EAppointmentMotive) {
    setSelectedMotive(newMotive);
    return;
  }

  function handleChangeSubscribe(choise: ESubscriptionDecision) {
    setSelectedSubscribe(choise);
  }

  function handleChangeFollowUp(choise: EPersonalizedFollowup) {
    setPersonalizedFollowup(choise);
  }

  function handleChangeDisabled() {
    setDisabled(!disabled)
  }

  useEffect(() => {
    if (props.crData) {
      handleChangeMotive(props.crData.motive);
      setSelectedSubscribe(props.crData.subscription);
      setPersonalizedFollowup(props.crData.personalizedFollowup);
      setCrRemark(props.crData.remark || '');
      if (props.crData.appointmentDate !== 'null') {
        handleChangeDate(moment(props.crData.appointmentDate).format('YYYY-MM-DD'));
      } else {
        handleChangeDate(moment(new Date()).format('YYYY-MM-DD'));
      }
      const advisorId = getAdvisorId();
      if (advisorId === props.crData!.advisor?.id) {
        const now = moment(new Date());
        const time = moment(props.crData?.createdAt);
        const minutes = now.diff(time, 'minutes');

        if (minutes >= 10) {
          setIsEditable(false);
          setDisabled(true);
        } else {
          setIsEditable(true);
        }
        let isEdit = setInterval(() => {
          const now = moment(new Date());
          const time = moment(props.crData!.createdAt);
          const minutes = now.diff(time, 'minutes');
          
          if (minutes >= 10) {
            setIsEditable(false);
            // If user launch edit mode and after 10 minutes doesnt complete it 
            // He can still edit it even though the content become backend not editable
            setDisabled(true);
            clearTimeout(isEdit);
          } else {
            setIsEditable(true);
          }

          if (isNaN(minutes)) {
            clearTimeout(isEdit);
            setDisabled(true);
          }
        }, 5000);
      }
    }
  }, [props]);

  return (
    <div>
      {props.crData && (
        <div className={styles.Advisor}>
          <div>
            <img src={remarkIcon} width={'19px'} alt="" />{' '}
            <span className={styles.AdvisorName}>
              {getAdvisorName(props.crData.advisor)}
            </span>{' '}
            {i18n.customerCRComponent.labels.toComplete}{' '}
            <span className={styles.BolderGreen}>{i18n.customerCRComponent.labels.report}</span> de
            RDV
          </div>
          <div className={styles.DateContainer}>
            {moment(props.crData.createdAt).format('DD/MM/YYYY')}{' '}
            {moment(props.crData.createdAt).format('HH:mm').replace(':', 'H')}
          </div>
        </div>
      )}
      <div className={styles.Container}>
        {isEditable ? <img src={editIcon} alt="edit" className={styles.EditIcon} onClick={handleChangeDisabled}/> : null}
        <DateAndReason
          valueDate={date}
          handleSetDate={(event) => handleChangeDate(event)}
          handleChangeIsInFuture={(isInFuture) => handleChangeIsDateInFuture(isInFuture)}
          selectedMotive={selectedMotive}
          handleChangeMotive={(newMotive) => handleChangeMotive(newMotive)}
          disabled={disabled}
        />
        <MultiChoisesButton
          disabled={disabled}
          title={i18n.customerCRComponent.labels.subscription}
          handleChange={(e) => handleChangeSubscribe(e as ESubscriptionDecision)}
          selectedElement={selectedSubscribe}
          dafaultValue={ESubscriptionDecision.No}
        />

        <MultiChoisesButton
          disabled={disabled}
          title={i18n.customerCRComponent.labels.personalizedSolution}
          handleChange={(e) => handleChangeFollowUp(e as EPersonalizedFollowup)}
          selectedElement={personalizedFollowup}
          dafaultValue={EPersonalizedFollowup.Partially}
        />

        <img src={dashedLine} alt="" className={styles.DashLineImg} />
        <div className={styles.CommentTitle}>
          <div className={styles.TitleBox}>
            <span className={styles.Title}>{i18n.customerCRComponent.labels.comment}</span>
            {!disabled && <span className={styles.Counter}>{countChars}/2000</span>}
          </div>

          {!disabled ? (
            <TextInput
              placeholder={i18n.customerCRComponent.labels.addAComment}
              className={styles.Input}
              type="text"
              value={crRemark}
              onChange={(e) => {
                setCountChars(e.target.value.length);
                setCrRemark(e.target.value);
              }}
              maxLength={2000}
            />
          ) : (
            <div className={styles.RemarkContainer}>{props.crData!.remark}</div>
          )}
        </div>
      </div>
          <CRActionBox
          onValidate={props.onValidate}
          crDate={date}
          selectedMotive={selectedMotive}
          selectedSubscribe={selectedSubscribe}
          personalizedFollowup={personalizedFollowup}
          crRemark={crRemark}
          cancelAddCR={props.cancelAddCR}
          setCountChars={(arg) => setCountChars(arg)}
          setCrRemark={(arg) => setCrRemark(arg)}
          isDateInFuture={isDateInFuture}
          editMode={!disabled}
          identifier={props.crData?.id}
          crData={props.crData}
        />
      <div className={styles.Divider}></div>
    </div>
  );
}
