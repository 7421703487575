export enum FooterLink {
  A11yPage = 'A11yPage',
  AboutUsPage = 'AboutUsPage',
  CookiesPage = 'CookiesPage',
  FaqPage = 'FaqPage',
  HomePage = 'HomePage',
  LogoutRedirectUrl = 'LogoutRedirectUrl',
  PartnersPage = 'PartnersPage',
  PrivacyPage = 'PrivacyPage',
  ProjectionExplanationPage = 'ProjectionExplanationPage',
  TermsOfUsePage = 'TermsOfUsePage',
  AdvisorTutorialPage = 'AdvisorTutorialPage'
}

export const i18n = {
  footerLink: {
    [FooterLink.AboutUsPage]: 'À propos',
    [FooterLink.FaqPage]: 'FAQ',
    [FooterLink.PartnersPage]: 'Nos partenaires',
    [FooterLink.TermsOfUsePage]: 'CGU',
    [FooterLink.CookiesPage]: "Politique d'utilisation des cookies",
    [FooterLink.PrivacyPage]: 'Politique de protection des données',
    [FooterLink.A11yPage]: 'Accessibilité\xa0: partiellement conforme'
  },
  footer: {
    aboutUs: 'À propos',
    faq: 'FAQ',
    partners: 'Nos partenaires',
    termsOfUse: 'CGU',
    cookies: "Politique d'utilisation des cookies",
    privacy: 'Politique de protection des données',
    a11y: 'Accessibilité\xa0: partiellement conforme',
    logo: 'BNP Paribas logo'
  }
};
