import { ChangeEvent, useState, useEffect } from 'react';
import styles from './SuperAdmin.module.scss';
import { i18n } from 'modules/administration/i18n';
import { grey100 } from 'styles/colors';
import { useCheckUserIsAdminCommand } from 'modules/administration/commands/checkUserIsAdminCommand';
import { useAccountDisableCommand } from 'modules/administration/commands/accountDisableCommand';
import { useAccountEnableCommand } from 'modules/administration/commands/accountEnableCommand';
import { AxiosOutcome } from 'shared/enums/axiosOutcome.enum';
import classNames from 'classnames';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import { Button, TextInput } from '@ifs/libs';

export function SuperAdmin() {
  const { checkUserIsAdminCommand } = useCheckUserIsAdminCommand();
  const { accountDisableCommand } = useAccountDisableCommand();
  const { accountEnableCommand } = useAccountEnableCommand();

  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    verifyIsAdmin();
  }, []);

  const verifyIsAdmin = async () => {
    await checkUserIsAdminCommand();
  };

  const handleActivateUser = async () => {
    const outcome = await accountEnableCommand({ email });
    setMessage(
      i18n.SuperAdmin.notification[
        outcome === AxiosOutcome.Success ? 'activationSuccess' : 'activationError'
      ](email)
    );
  };

  const handleDisableUser = async () => {
    const outcome = await accountDisableCommand({ email });
    setMessage(
      i18n.SuperAdmin.notification[
        outcome === AxiosOutcome.Success ? 'deactivationSuccess' : 'deactivationError'
      ](email)
    );
  };

  const handleRegExEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    setEmail(value);
  
    if (  (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  return (
    <div className={styles.SuperAdminContainer}>
      <TextInput
        title={i18n.SuperAdmin.labels.addressMail}
        type={'email'}
        value={email}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
          handleRegExEmail(event);
        }}
        placeholder={i18n.SuperAdmin.labels.addressMail}
        name={'mail'}
      />
      {(!emailIsValid && email.length > 1)&& (
        <div className={styles.EmailDomainError}>{i18n.SuperAdmin.errors.emailDomain}</div>
      )}
      <br />
      {message}
      <div className={styles.ActionBox}>
        <Button
        disabled={!emailIsValid}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          onClick={() => {
            handleActivateUser();
          }}
        >
          {i18n.SuperAdmin.labels.activation}
        </Button>
        <Button
        disabled={!emailIsValid}
          onClick={(): void => {
            handleDisableUser();
          }}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
        >
          {i18n.SuperAdmin.labels.deactivation}
        </Button>
      </div>
    </div>
  );
}
