import { axiosRequestValidateStatusConfig } from "@ifs/libs/src/shared/axiosValidateStatusConfig";
import axios from "axios";
import { apiUrls } from "shared/apiUrls";
import { CustomerIdentifierParams, CustomerInformationResponse } from "../models/Customer";

export async function getCustomerInformationtRequest(params: CustomerIdentifierParams): Promise<CustomerInformationResponse | null> {
    const response = await axios.get(apiUrls(params.customerId).customers.getById,
    {...axiosRequestValidateStatusConfig});

    if (response.status === 204, response.status === 404) {
        return null;
    }

    return response.data;
}
