import { useLoader } from "@ifs/libs";
import { useAnalytics } from "shared/Analytics/useAnalytics";
import { RegisterUserBodyModel } from "../models/auth.model";
import { registerUserRequest } from "../requests/registerUserRequest";

export function useRegisterUserCommand() {
    const { showLoaderWhile } = useLoader();
    const { simpleEvent } = useAnalytics() 
    
    async function registerUserCommand(body: RegisterUserBodyModel): Promise<any> {
        return await showLoaderWhile(async () => {
            simpleEvent('create_account')
            return await registerUserRequest(body);
            
        }, {key: "registerUserCommand", value:"API POST - /api/auth/register"})
    }

    return { registerUserCommand };
}
