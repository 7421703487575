import { useLocation, useNavigate } from 'react-router-dom';

import { globali18n } from 'i18n';

import { i18n } from '../../i18n';
import styles from './RegistrationEmailPage.module.scss';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { Container, PageTitle, SvgWrapperComponent } from '@ifs/libs';

import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import { ModuleStyles } from 'modules/auth/styles/ModuleStyles';
import { useResendValidationEmail } from 'modules/auth/commands/resendValidationEmail.command';
import { Button } from 'shared';
import classNames from 'classnames';
import buttonStyles from 'styles/ButtonStyle.module.scss';

export function RegistrationEmailPage() {
  const email = new URLSearchParams(useLocation().search).get('email')!;
  const { resendValidationEmailCommand } = useResendValidationEmail();
  const navigate = useNavigate();

  function resend() {
    resendValidationEmailCommand(email);
  }

  function goToLogin() {
    navigate(internalUrls.authentication.login);
  }

  function cancel() {
    navigate(-1);
  }

  return (
    <div className={styles.RegisterContainer}>
      <div className={styles.Header}></div>
      <Container className={ModuleStyles.Grid}>
        <PageTitle>{i18n.registrationEmailPage.title}</PageTitle>
        <SvgWrapperComponent ariaHidden={true}><SharedAssets.Mailbox className={ModuleStyles.Mailbox} /></SvgWrapperComponent>
        <Button
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
          onClick={goToLogin}
        >
          {i18n.registrationEmailPage.goToLogin}
        </Button>
        <Button
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          onClick={cancel}
        >
          {globali18n.labels.back}
        </Button>
        <div className={styles.ResendGroup}>
          <a className={styles.LoginLink} onClick={resend}>
            {i18n.registrationEmailPage.resend}
          </a>
          <p className={styles.SmallText}>{globali18n.labels.spamWarning}</p>
        </div>
      </Container>
    </div>
  );
}
