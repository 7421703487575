import {
  Button,
  ButtonStyles,
  Container,
  InputGroup,
  SvgWrapperComponent,
  TextInput,
  ValidationMessages
} from '@ifs/libs';
import styles from './CustomerSearchPage.module.scss';
import { LeftIcon } from 'shared/assets';
import { globali18n } from 'i18n';
import classNames from 'classnames';
import { Footer } from 'shared/Footer/Footer';
import { logo } from './images';
import { ModuleStyles } from 'modules/auth/styles/ModuleStyles';
import { Controller, useForm } from 'react-hook-form';
import { getFormConfig } from './formConfig';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';
import { i18n } from './i18n';
import { useSearchCustomerCommand } from './commands/searchCustomerCommand';
import { inputMaxLength } from '@ifs/libs/src/shared/utilities/validation';
import { useResolver } from 'modules/customer-file/resolver/resolver';

interface FormData {
  uid: string;
}

const t = i18n.CustomerSearch;
export function CustomerSearchPage() {
  const { checkConnectionDone } = useResolver(); // Apply route Resolver for this Page
  const { register, handleSubmit, formState, control } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {},
    mode: 'all'
  });
  const { searchCustomer } = useSearchCustomerCommand();

  async function onSubmit(formData: FormData) {
    await searchCustomer(formData.uid.toString());
  }

  const formConfig = getFormConfig(register);
  const errors = formState.errors;

  return (
    <div className={styles.SearchClientContainer}>
      <header className={styles.Header}>
        <img src={logo} alt="logo" />
      </header>
      <div className={styles.Body}>
        <Container className={classNames(ModuleStyles.Grid, styles.BodyGrid)}>
          <div className={styles.FormContainer}>
            <h1> {t.title} </h1>
            <form className={styles.Form} noValidate onSubmit={handleSubmit(onSubmit)}>
              <InputGroup className={styles.InputGroup}>
                <div className={styles.LabelContainer}>
                  <label className={styles.Label}>{t.labels.uid}</label>                  
                </div>
                <Controller
                  control={control}
                  name="uid"
                  rules={formConfig.uid.rules}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      required
                      aria-required={'true'}
                      id="uid"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      min={0}
                      maxLength={inputMaxLength}
                    />
                  )}
                />
                <div className={styles.ValidationMessagesWrapper}>
                  <ValidationMessages
                    messages={getValidationMessages('uid', errors, formConfig.uid.errors)}
                  />
                </div>
              </InputGroup>

              <div className={styles.SubmitFormActionBox}>
                <Button
                  disabled={!formState.isValid}
                  className={classNames(ButtonStyles.PrimaryAction)}
                >
                  {globali18n.labels.next}
                </Button>
              </div>
            </form>
          </div>
          <div className={styles.SVGContainer}>
            <SvgWrapperComponent ariaHidden={true}>
              <LeftIcon className={styles.Icon} />
            </SvgWrapperComponent>
          </div>
        </Container>
      </div>
      <Footer customClass={styles.FooterCustom} />
    </div>
  );
}
