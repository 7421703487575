import axios from "axios";
import { apiUrls } from "shared/apiUrls";
import { EAdvisorAssignmentFilterType, SearchCustomerParams, SearchCustomerResponse } from "../models/searchCustomer.model";

export async function searchCustomerRequest(params: SearchCustomerParams): Promise<SearchCustomerResponse> {
    const response = await axios.get(apiUrls().customers.search, { paramsSerializer:queryParamsSerializer, params });
    return response.data;
}

const queryParamsSerializer = (searchParams: Record<string, number|EAdvisorAssignmentFilterType|string|string[]> ) => {
    let queryParams = ``;
    const params = [''];

    Object.entries(searchParams).forEach(([key, value])=> {
        if(key === "advisorId"){
            // Use the params advisorAssignmentFilterType and the value Selection to only return records where the customer is assigned to one of the advisorIds filter value define
            if((value as string[]).length) {
                params.push(`advisorAssignmentFilterType=${EAdvisorAssignmentFilterType.Selection}`);
            }
            for (const id of value as string[]){
              params.push(`advisorIds=${id}`);
            }
        } else {
            params.push(`${key}=${value}`) 
        }

    })
    // check remove first character
    let paramsJoin = params.join("&");
    if(paramsJoin.charAt(0) === '&'){
        paramsJoin = paramsJoin.substring(1);
    }
    return queryParams + paramsJoin;
  };