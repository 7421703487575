import React, { createContext } from "react";
import useModal from "./useModal";
import { Modal } from "../shared";

interface AppContextInterface {
  isDisplay: boolean;
  handleModal: (p: any, title?:string) => void;
  modalContent: string;
  modalTitle:string;
}

const defaultState = {
    isDisplay: false,
    handleModal : (e:string, title?:string) => {return e},
    modalContent : '',
    modalTitle: "Confirmation"
  };

let ModalContext : React.Context<AppContextInterface>;
let { Provider } = (ModalContext =
  createContext<AppContextInterface>(defaultState));

let ModalProvider = ({ children }: any) => {
  let { isDisplay, handleModal, modalContent, modalTitle } = useModal();
  return (
    <Provider value={{ isDisplay, handleModal, modalContent, modalTitle }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
