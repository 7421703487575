
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useLoader } from "@ifs/libs";
import { InitializeAccountModel, InitializeAccountOutcome, initializeAccountRequest } from "../requests/initializeAccountRequest";

export function useInitializeAccountCommand() {
    const { showLoaderWhile } = useLoader();
    const navigate = useNavigate();

    async function initializeAccountCommand(model: InitializeAccountModel): Promise<InitializeAccountOutcome> {
        return await showLoaderWhile(async () => {
            try {
                const response = await initializeAccountRequest(model);
                if (response.status === 204 || response.status === 200) {
                    navigate(`/customer-file?customerid=${response.data}`);
                }
                return InitializeAccountOutcome.Success;
            }
            catch (err) {
                const error = err as AxiosError;
                const body = error.response?.data as any;
                const isEmailError = body?.error_description?.includes("SmtpException");
                return isEmailError ? InitializeAccountOutcome.EmailError : InitializeAccountOutcome.Error;
            }
        }, {key: "initializeAccountCommand", value:"API POST - /api/auth/initialize_account"})
    }

    return { initializeAccountCommand };
}

