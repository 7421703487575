import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'shared';
import styles from './Header.module.scss';
import { i18n } from "modules/advisor-course/i18n"; 
import { getCustomerInformationtRequest } from 'modules/customer-file/requests/getCustomerInformationRequest';
import classNames from 'classnames';
import buttonStyles from 'styles/ButtonStyle.module.scss';


export default function Header() {
  const { customerid } = useParams();
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState<string>('');

  async function getCustomerName() {
    if(!customerid) return;
    const customerInfos = await getCustomerInformationtRequest({customerId: customerid});
    setCustomerName(`${customerInfos!.firstName} ${customerInfos!.lastName}`);
  }

  useEffect(() => {
    getCustomerName();
  }, []);

  return (
    <div className={styles.Root}>
      <div>
        <Button
          onClick={() =>
            navigate(`/customer-file?customerid=${customerid}&openReportPanel=true&fromClientFlow=true`, { state: { isCustomer: true } })
          }
          className={classNames(buttonStyles.BaseButton, buttonStyles.FlowButton)}
        >{i18n.Header.label.backToAdvisor}</Button>
      </div>
      <div>
        <span>{i18n.Header.label.flowOf}</span> &nbsp;<span className={styles.CustomerName}>{customerName}</span>
      </div>
    </div>
  );
}
