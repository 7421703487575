import { useLoader } from '@ifs/libs';
import { useAnalytics } from '../../../shared/Analytics/useAnalytics';
import { LoginParamsModel } from '../models/auth.model';
import { LoginOutcome, loginRequest } from '../requests/loginRequest';
import { useInitUserReferenceCommand } from './initUserReferenceCommand';

export function useLoginCommand() {
  const { initUserReferenceCommand } = useInitUserReferenceCommand();
  const { showLoaderWhile } = useLoader();
  const { simpleEvent } = useAnalytics();

  async function loginCommand(model: LoginParamsModel): Promise<LoginOutcome> {
    return await showLoaderWhile(
      async () => {
          const request = await loginRequest(model);
          simpleEvent('connected');

          if(request === LoginOutcome.Success) {
            await initUserReferenceCommand();
          }
          return request;
      },
      { key: 'loginCommand', value: 'API POST - /api/auth/login' }
    );
  }

  return { loginCommand };
}
