import { ModalOutcome, useConfirmModal, useLoader } from "@ifs/libs";
import { useNavigate } from "react-router-dom";
import { i18n } from '../pages/CustomerSearchPage/i18n';


export function useCreateNewCustomerCommand(){
    const { showConfirm } = useConfirmModal();

    async function renderModalCustomerUndefinedCommand(customerIdentifier: string): Promise<ModalOutcome> {        
        const result = await showConfirm(
            i18n.noSearchModal.title,
            i18n.noSearchModal.body,
            i18n.noSearchModal.okText
        );

        return result.outcome; 
    }

    return { renderModalCustomerUndefinedCommand };
}