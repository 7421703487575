import { useLoader } from "@ifs/libs";
import { UpdateAppointmentStatusParams } from "../models";
import { apiUrls } from "shared/apiUrls";
import { updateAppointmentStatusRequest } from "../requests/updateAppointmentStatusRequest";

export function useUpdateAppointmentStatusCommand() {
    const { showLoaderWhile } = useLoader();


    async function updateAppointmentStatusCommand(appointmentId: string, params: UpdateAppointmentStatusParams): Promise<void> { 
        return await showLoaderWhile(async () => {
                await updateAppointmentStatusRequest(appointmentId, params);            
        }, {key: "updateAppointmentStatusCommand", value:`API PUT - ${apiUrls().appointments.put(appointmentId)}`})
    }

    return { updateAppointmentStatusCommand };
}