import { Gender } from '@ifs/libs/src/modules/legal-pension/models/Gender';
import styles from './NaturalLanguage.module.scss';

interface NaturalLanguageProps {
  birthDate: string;
  startDateOfActivity: string;
  children: JSX.Element;
  affiliate: string;
  salary: string;
  naturalLangage: JSX.Element;
  gender: Gender;
}

export function NaturalLanguage(props: NaturalLanguageProps) {
  const isMen = props.gender === Gender.Male;
  const menOrWomen = isMen ? "un homme" : "une femme";
  const simpleText = !isMen ? "e" : "" 
  return (
    <div>
      Vous êtes<span className={styles.ColorOrange}> {menOrWomen} </span>, vous êtes né{simpleText} le
      <span className={styles.ColorOrange}> {props.birthDate} </span>. Vous avez commencé à
      travailler le
      <span className={styles.ColorOrange}> {props.startDateOfActivity}</span>. {props.children} et
      vous êtes affilié{simpleText} à<span className={styles.ColorOrange}> {props.affiliate}</span>. Vous gagnez{' '}
      <span className={styles.ColorOrange}>{props.salary}</span>. {props.naturalLangage}
    </div>
  );
}
