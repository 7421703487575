import { globali18n } from 'i18n';
import { AdvisorResponseModel } from 'shared/request/getAdvisorRequest';

export async function fetchAdvisors(
  listOfAdvisors: Promise<AdvisorResponseModel[]>,
  setAdvisorsList: any,
  setAdvisorsFilteredList: any
) {
  const tempAdvisorArray: { label: string; value: string }[] = [];

  const advisors = await listOfAdvisors;
  tempAdvisorArray.push({
    label: globali18n.labels.none,
    value: 'none'
  });
  advisors.map((advisor) => {
    let obj = {
      label: `${advisor.lastName} ${advisor.firstName}`,
      value: advisor.id
    };

    return tempAdvisorArray.push(obj);
  });

  setAdvisorsList(tempAdvisorArray);
  setAdvisorsFilteredList(tempAdvisorArray);
}
