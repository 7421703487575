import { globali18n } from "@ifs/libs/src/i18n";
import { UseFormRegister } from "react-hook-form";
export function getFormConfig(register: UseFormRegister<any>) {

  const formConfig = {
    uid: {      
      rules: {
        required: true,
        validate: {
          // Don't validate the format if the input is empty, let required complain about it
          format: (v: string) => (v == null ? true : /^([A-zÀ-ž0-9\u0370-\u03FF\u0400-\u04FF- ']{0,255})$/i.test(v))
        }   
      },
      errors: {
        required: globali18n.errors.required,
        format: globali18n.errors.pattern
      }
    }
  };

  return formConfig;
}