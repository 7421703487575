import { useNavigate } from "react-router-dom";
import { getUserReferenceRequest } from "shared/request/getUserReferenceRequest"

export function useCheckUserIsAdminCommand(){
    const navigate = useNavigate();

    async function checkUserIsAdminCommand(): Promise<void|null> {
        const userData = await getUserReferenceRequest();
        if (userData.userType !== "Admin") {
            return navigate("/");
        }
        return null;
    }
    return {checkUserIsAdminCommand}

}