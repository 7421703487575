import { useState, useEffect, useContext } from 'react';
import CustomerInformationsCard from './Components/CustomerInformationCard/CustomerInformationsCard';
import CustomerAddEvents from './Components/CustomerAddEvents/CustomerAddEvents';
import CollectionCard from './Components/CollectionCard/CollectionCard';
import CustomerHistoryEvents from './Components/CustomerHistoryEvents/CustomerHistoryEvents';
import styles from './CustomerFilePage.module.scss';
import { LegalPensionModel, LifeProjectsModel } from 'models/customer-situation';
import { useNavigate } from 'react-router-dom';
import { ModalContext } from 'context/modalContext';
import { Button, PageWithMenu } from 'shared';
import { AdvisorButton } from 'modules/appointments/pages/CustomerAppointmentsPage/Component/AdvisorButton/AdvisorButton';
import { i18n } from './i18n';
import { globali18n } from 'i18n';
import { useGlobalCommand } from '@ifs/libs';
import { CustomerInformationResponse } from 'modules/customer-file/models/Customer';
import { assignCustomerToAdvisorRequest } from 'shared/request/assignCustomerToAdvisorRequest';
import { deassignCustomerToAdvisorRequest } from 'shared/request/deassignCustomerToAdvisorRequest';
import { launchJourneyRequest } from 'modules/customer-file/requests/launchJourneyRequest';
import classNames from 'classnames';

import { useGetCustomerInfoCommand } from 'modules/customer-file/commands/getCustomerInfoCommand';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import {
  LegalPensionCard,
  LifeProjectCard,
  PatrimonyCard,
  PensionNeedCard,
  TaxationCard
} from './Components/CollectionCard/components';
import { InvestorProfileCard } from './Components/CollectionCard/components/InvestorProfileCard/InvestorProfileCard';
import { RecommendationOutputModel } from '@ifs/libs/src/modules/recommendation/models/RecommendationModel';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { PropositionCard } from './Components/PropositionCard/PropositionCard';
import { GetFinancialProfiles } from '@ifs/libs/src/modules/investor-profile/models/FinancialProfileModel';
import { SearchRiskProfiles } from 'modules/customer-file/requests/getSearchRiskProfiles';
import { ChosenScenario } from '@ifs/libs/src/shared/models/ChosenScenario';
import { ValidatedPensionNeedModel } from '@ifs/libs/src/modules/pension-need/models/request/validationRequest.model';
import { EAdvisorCustomerActiontype } from './Components/CustomerHistoryEvents/AdvisorCustomerActionType.enum';
import { PatrimonyModel } from '@ifs/libs/src/modules/patrimony/models/PatrimonyModel';
import { AppointmentResult } from 'modules/appointments/models';
import { TaxationOutputModel } from '@ifs/libs/src/modules/taxation/models/TaxationOutputModel';
import { useTenantConfigCommand } from 'modules/auth/commands/tenantConfigCommand';
import { useResolver } from 'modules/customer-file/resolver/resolver';

export function CustomerFilePage() {
  const { simpleEvent, pageView } = useAnalytics();
  const { checkConnectionDone } = useResolver();
  const { isTenantRecommendationModeStatic } = useGlobalCommand();
  const { isTenantWithAuthenticationModeSSO, getAdvisorId } = useTenantConfigCommand();
  const navigate = useNavigate();
  let { handleModal } = useContext(ModalContext);

  const [fromClientFlow, setFromClientFlow] = useState<boolean>(false);
  const [appointmentLoaded, setAppointmentLoaded] = useState<boolean>(false);
  const [customerInformationsLoaded, setCustomerInformationsLoaded] = useState<boolean>(false);
  const [customerInformations, setCustomerInformations] =
    useState<CustomerInformationResponse | null>(null);
  const [customerAppointmentsHistory, setCustomerAppointmentsHistory] = useState<
    AppointmentResult[]
  >([]);
  const [customerID, setCustomerId] = useState<string | null>(null);
  const [currentAdvisorId, setCurrentAdvisorId] = useState<string>('');

  const [handleaddCr, setHandleAddCr] = useState(false);
  const [addRemark, setAddRemark] = useState(false);
  const [isReloadCustomerCount, setIsReloadCustomerCount] = useState<boolean>(false);

  const [taxation, setTaxation] = useState<TaxationOutputModel | null>(null);
  const [pensionNeeds, setPensionNeeds] = useState<ValidatedPensionNeedModel | null>();
  const [legalPensions, setLegalPensions] = useState<LegalPensionModel | null>();
  const [patrimony, setPatrimony] = useState<PatrimonyModel | null>();
  const [lifeProjects, setLifeProjects] = useState<LifeProjectsModel | null>();
  const [recommendation, setRecommendation] = useState<RecommendationOutputModel>();
  const [riskProfile, setRiskProfile] = useState<SearchRiskProfiles>();
  const [financialProfile, setFinancialProfile] = useState<GetFinancialProfiles>();
  const [lifeProjectTotal, setLifeProjectTotal] = useState<number>();
  const [hasReport, setHasReport] = useState<boolean>(false);

  const [age, setAge] = useState<{ month: number; year: number }>({ month: 0, year: 0 });

  const authenticationSSO = isTenantWithAuthenticationModeSSO();
  const recommendationStatic = isTenantRecommendationModeStatic();

  const {
    getCustomerAppointmentCommand,
    getCustomerInformationCommand,
    getCustomerTaxationCommand,
    getCustomerPensionNeedsCommand,
    getCustomerLegalPensionCommand,
    getCustomerPatrimonyCommand,
    getCustomerLifeProjectsCommand,
    getCustomerRecommendationCommand,
    getCustomerRiskProfileCommand,
    getCustomerFinancialProfileProfileCommand,
    getLifeProjectTotalCommand
  } = useGetCustomerInfoCommand();

  useEffect(() => {
    const advisorId = getAdvisorId();
    setCurrentAdvisorId(advisorId || '');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const openReportPanel = urlParams.get('openReportPanel');
    setFromClientFlow(!!urlParams.get('fromClientFlow'));
    setHandleAddCr(!!openReportPanel);
  }, []);

  useEffect(() => {
    fetchStates();
  }, []);

  const { resetAllCommand } = useGlobalCommand();
  const getCustomerHistory = async (customerIdentifier: string) => {
    try {
      const customerAppointments = await getCustomerAppointmentCommand({
        customerId: customerIdentifier
      });
      setCustomerAppointmentsHistory(customerAppointments);
      setAppointmentLoaded(true);
    } catch (error) {
      setAppointmentLoaded(false);
    }
  };

  const getCustomer = async (customerIdentifier: string) => {
    try {
      const customerInfos = await getCustomerInformationCommand({ customerId: customerIdentifier });
      setCustomerInformations(customerInfos);
      setCustomerInformationsLoaded(true);
      return customerInfos;
    } catch (error) {
      setCustomerInformationsLoaded(false);
      return null;
    }
  };

  function sumOfLifeProjects(obj: any) {
    return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
  }

  function getAge(legalPension: LegalPensionModel | null) {
    const computationRest = legalPension!.legalPensionComputation.results;

    const { fullRateAgeResult, legalAgeResult, maxAgeResult } = computationRest;

    if (legalPension!.validatedLegalPension.chosenScenario === ChosenScenario.FullRateAge) {
      return {
        year: fullRateAgeResult!.age.year,
        month: fullRateAgeResult!.age.month
      };
    }

    if (legalPension!.validatedLegalPension.chosenScenario === ChosenScenario.LegalAge) {
      return {
        year: legalAgeResult!.age.year,
        month: legalAgeResult!.age.month
      };
    }

    if (legalPension!.validatedLegalPension.chosenScenario === ChosenScenario.MaxAge) {
      return {
        year: maxAgeResult!.age.year,
        month: maxAgeResult!.age.month
      };
    }
  }

  const getCustomerSituations = async (customerIdentifier: string) => {
    try {
      const taxationResponse = await getCustomerTaxationCommand(customerIdentifier);
      const pensionNeedResponse = await getCustomerPensionNeedsCommand(customerIdentifier);
      const legalPensionResponse = await getCustomerLegalPensionCommand(customerIdentifier);
      const patrimonyResponse = await getCustomerPatrimonyCommand(customerIdentifier);
      let lifeProjectResponse = null;
      let ageFormated = null;
      let sum = 0;
      if (legalPensionResponse) {
        ageFormated = getAge(legalPensionResponse);

        setAge(ageFormated!);
        lifeProjectResponse = await getCustomerLifeProjectsCommand(customerIdentifier);
        if (lifeProjectResponse) {
          sum = sumOfLifeProjects(lifeProjectResponse);
        }
      }

      setTaxation(taxationResponse);
      setPensionNeeds(pensionNeedResponse);
      setLegalPensions(legalPensionResponse);
      setPatrimony(patrimonyResponse);
      setLifeProjects(lifeProjectResponse);

      if (lifeProjectResponse) {
        const totalLifeProjectResponse = await getLifeProjectTotalCommand(customerIdentifier, sum);
        setLifeProjectTotal(totalLifeProjectResponse.monthlyAnnuityAmount);
      }

      // Tenant with Recommendation static, dont hav access to the /risk-profiles pages
      const riskProfileResponse = recommendationStatic
        ? null
        : await getCustomerRiskProfileCommand(customerIdentifier);

      if (riskProfileResponse && riskProfileResponse.results.length !== 0) {
        setRiskProfile(riskProfileResponse);
      }

      // Tenant with Recommendation static, dont have access to the /financial-profile pages
      const financialProfileResponse = recommendationStatic
        ? null
        : await getCustomerFinancialProfileProfileCommand(customerIdentifier);

      if (financialProfileResponse && financialProfileResponse.results.length !== 0) {
        setFinancialProfile(financialProfileResponse);
      }

      const commonModulesCompleted =
        pensionNeedResponse && legalPensionResponse && patrimonyResponse && lifeProjectResponse;

      // For Recommendation static, the module taxation and the module investorProfile are not necessary
      // And we dont render the recommendation card so we dont need to get these informations
      if (isTenantRecommendationModeStatic()) {
        if (commonModulesCompleted) {
          setHasReport(true);
        }
      } else {
        // Recommendation Personalize mode === Recommendation of Internaute tenant
        // Required all module to display the recommendation
        if (
          commonModulesCompleted &&
          taxationResponse &&
          riskProfileResponse &&
          financialProfileResponse &&
          !financialProfileResponse.results[0].isExpired
        ) {
          await catchRecommendationResponse(customerIdentifier, legalPensionResponse);
        }
      }
      setIsReloadCustomerCount(true);
    } catch (error) {}
  };

  const catchRecommendationResponse = async (
    customerIdentifier: string,
    legalPensionResponse: LegalPensionModel
  ) => {
    const recommendationResponse = await getCustomerRecommendationCommand(
      customerIdentifier,
      legalPensionResponse.validatedLegalPension.chosenScenario
    );
    setRecommendation(recommendationResponse);
    setHasReport(true);
  };

  const fetchStates = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const customerid = urlParams.get('customerid');
    const openReportPanel = urlParams.get('openReportPanel');
    setCustomerId(customerid);
    setHandleAddCr(!!openReportPanel);

    if (customerid !== null) {
      const customer = await getCustomer(customerid);
      if (customer) {
        await Promise.all([
          !authenticationSSO && getCustomerHistory(customerid),
          getCustomerSituations(customerid)
        ]);
      }
    }
  };

  const loadCustomerInfos = async () => {
    if (!customerID) return;
    if (!authenticationSSO) {
      const customerAppointments = await getCustomerAppointmentCommand({
        customerId: customerID
      });

      if (customerAppointments) {
        setCustomerAppointmentsHistory(customerAppointments);
        setAppointmentLoaded(true);
      } else {
        setAppointmentLoaded(false);
      }
    }
  };

  const assignCustomerToAdvisor = async () => {
    if (!customerID) return;
    await assignCustomerToAdvisorRequest({ customerId: customerID });
    fetchStates();
  };

  const unAssignCustomer = async () => {
    if (!customerID) return;
    await deassignCustomerToAdvisorRequest({ customerId: customerID });
    fetchStates();
  };

  const handleModalCtxAssign = (): void => {
    return handleModal(
      <div>
        <div className={styles.ModalBody}>
          {i18n.customerFilePage.labels.titleAssign}
          <span
            className={styles.ModalBodyName}
          >{` ${customerInformations?.lastName} ${customerInformations?.firstName} ?`}</span>
        </div>

        <div className={styles.ModalActions}>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            onClick={() => {
              simpleEvent('back_assign');
              handleModal('close');
            }}
          >
            {globali18n.labels.back}
          </Button>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
            onClick={() => {
              assignCustomerToAdvisor();
              simpleEvent('assigned');
              handleModal('close');
            }}
          >
            {globali18n.labels.confirm}
          </Button>
        </div>
      </div>
    );
  };

  const handleModalCtxUnassign = (): void => {
    return handleModal(
      <div>
        <div className={styles.ModalBody}>
          {i18n.customerFilePage.labels.titleUnassign}{' '}
          <span
            className={styles.ModalBodyName}
          >{`${customerInformations?.lastName} ${customerInformations?.firstName}`}</span>{' '}
          {globali18n.labels.of}{' '}
          <span
            className={styles.ModalBodyName}
          >{`${customerInformations?.assignedAdvisor?.lastName} ${customerInformations?.assignedAdvisor?.firstName}`}</span>{' '}
          ?
        </div>

        <div className={styles.ModalActions}>
          <Button
            onClick={() => {
              simpleEvent('back_unassign');
              handleModal('close');
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          >
            {globali18n.labels.back}
          </Button>
          <Button
            onClick={() => {
              simpleEvent('unassigned');
              unAssignCustomer();
              handleModal('close');
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
          >
            {globali18n.labels.confirm}
          </Button>
        </div>
      </div>
    );
  };

  async function goToFlow() {
    try {
      if (!customerID) return;
      simpleEvent('simulation_journey');
      await launchJourneyRequest({
        $type: EAdvisorCustomerActiontype.LaunchJourney,
        customerId: customerID,
        advisorId: currentAdvisorId
      });
      resetAllCommand();
      navigate(`/flow/${customerID}/dashboard`);
    } catch (error) {}
  }

  return (
    <PageWithMenu reloadCounterCustomer={isReloadCustomerCount}>
      <div className={styles.FileContainer}>
        <div className={styles.FileHeader}>
          <div className={styles.FilePageTitle}>
            <span className={styles.FileTitleBold}>{globali18n.labels.advisorArea}</span>
            <span className={styles.FileTitleLight}>
              {i18n.customerFilePage.labels.customerCard}
            </span>
          </div>
          <div className={styles.ActionBox}>
            {!customerInformationsLoaded ? (
              <div />
            ) : customerInformations?.assignedAdvisor !== null ? (
              <div className={styles.AssignedAdvisor}>
                <Button
                  onClick={goToFlow}
                  className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
                >
                  {i18n.customerFilePage.labels.advisorFlow}
                </Button>
                {!authenticationSSO && (
                  <>
                    <span className={styles.Title}>{globali18n.labels.advisor} :</span>
                    <AdvisorButton
                      advisorFirstName={customerInformations?.assignedAdvisor.firstName}
                      advisorLastName={customerInformations?.assignedAdvisor.lastName}
                      unAssignCustomer={() => handleModalCtxUnassign()}
                    />
                  </>
                )}
              </div>
            ) : (
              <div className={styles.AssignedAdvisor}>
                <Button
                  onClick={goToFlow}
                  className={classNames(
                    styles.Larger,
                    buttonStyles.BaseButton,
                    buttonStyles.FilledButtonPrimary
                  )}
                >
                  {i18n.customerFilePage.labels.advisorFlow}
                </Button>
                {!authenticationSSO && (
                  <Button
                    onClick={() => {
                      pageView({
                        page_title: 'Attribution Client',
                        page_path: '/',
                        page_type: 'popin',
                        user_id: customerID as string,
                        visitor_mode: '%visitor_mode'
                      });
                      handleModalCtxAssign();
                    }}
                    className={classNames(
                      buttonStyles.BaseButton,
                      buttonStyles.FilledButtonSecondary
                    )}
                  >
                    {i18n.customerFilePage.labels.customerAssignment}
                  </Button>
                )}
              </div>
            )}

            <Button
              onClick={() => {
                if (fromClientFlow) {
                  navigate('/advisor-course/search-client');
                  return;
                }
                navigate(-1);
              }}
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
              backButton={true}
            >
              Retour
            </Button>
          </div>
        </div>
        {customerInformationsLoaded && (
          <div className={styles.FileBody}>
            <div>
              <CustomerInformationsCard
                appointmentLoaded={appointmentLoaded}
                customerAppointmentsHistory={customerAppointmentsHistory}
                customerInformationsLoaded={customerInformationsLoaded}
                customerInformations={customerInformations}
                hasReport={hasReport}
              />
              {recommendation && !recommendationStatic && (
                <CollectionCard title={true}>
                  <PropositionCard customerData={recommendation} age={age} />
                </CollectionCard>
              )}

              <div>
                <CollectionCard>
                  {pensionNeeds && (
                    <PensionNeedCard
                      monthlyAmount={pensionNeeds.pensionNeed}
                      pensionNeedDetails={pensionNeeds.categoriesDetails}
                    />
                  )}

                  {legalPensions && <LegalPensionCard legalPension={legalPensions} />}

                  {patrimony && (
                    <PatrimonyCard
                      savingAccounts={patrimony.savingsAccounts}
                      realEstates={patrimony.realEstates}
                    />
                  )}

                  {lifeProjects && (
                    <LifeProjectCard lifeProject={lifeProjects} totalAmount={lifeProjectTotal} />
                  )}

                  {taxation && <TaxationCard taxation={taxation} />}

                  {!recommendationStatic && riskProfile && financialProfile && (
                    <InvestorProfileCard
                      riskProfile={riskProfile}
                      financialProfile={financialProfile}
                    />
                  )}
                </CollectionCard>
              </div>
            </div>
            <div>
              <CustomerAddEvents
                handleAddCR={() => setHandleAddCr(!handleaddCr)}
                handleAddRemark={() => setAddRemark(!addRemark)}
              />
              <CustomerHistoryEvents
                addCrDisplayed={handleaddCr}
                addRemark={addRemark}
                cancelAddNote={() => setAddRemark(false)}
                cancelAddCR={() => setHandleAddCr(!handleaddCr)}
                customerInformation={customerInformations}
                refetchValue={() => {
                  fetchStates();
                }}
                reloadCustomerInformations={() => loadCustomerInfos()}
              />
            </div>
          </div>
        )}
      </div>
    </PageWithMenu>
  );
}
