import { MinusGreenIcon } from 'modules/customer-file/assets';
import { PropsWithChildren, useState } from 'react';
import styles from './ModuleCard.module.scss';
import classNames from 'classnames';

interface ModuleCardProps {
  moduleIcon: any;
  title: string;
  headingContainerCustomClass?: string;
  headingCustomClass?: string;
  hideMinusButton?: boolean;
  headingContent: JSX.Element;
}

export function ModuleCard(props: PropsWithChildren<ModuleCardProps>) {
  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  function handleIsDisplay() {
    setIsDisplay(!isDisplay);
  }

  const Icon = props.moduleIcon;

  return (
    <div className={classNames(styles.Card, props.headingContainerCustomClass)}>
      <div className={styles.HeadingContainer} onClick={handleIsDisplay}>
        <div
          className={classNames(styles.Heading, props.headingCustomClass)}          
        >
          <Icon />
          <div className={styles.BolderPrimaryColor}>{props.title}</div>
          {props.headingContent}
        </div>

        {!props.hideMinusButton && (
          <div className={styles.Minus}>
            <MinusGreenIcon />
          </div>
        )}
      </div>

      {isDisplay && props.children}
    </div>
  );
}
