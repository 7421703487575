import { useNavigate } from 'react-router-dom';

import { globali18n } from 'i18n';

import { i18n } from '../../i18n';
import styles from './ResetPasswordEmailPage.module.scss';
import { internalUrls } from '@ifs/libs/src/shared/utilities/urls';
import { Container, PageTitle, SvgWrapperComponent } from '@ifs/libs';

import { assets as SharedAssets } from '@ifs/libs/src/shared/Assets';
import { ModuleStyles } from 'modules/auth/styles/ModuleStyles';
import { Button } from 'shared';
import classNames from 'classnames';
import buttonStyles from 'styles/ButtonStyle.module.scss';

export function RegistrationPasswordEmailPage() {
  const navigate = useNavigate();

  function goToLogin() {
    navigate(internalUrls.authentication.login);
  }

  return (
    <div className={styles.RegisterContainer}>
      <div className={styles.Header}>
        <PageTitle>{i18n.passwordResetEmailPage.title}</PageTitle>
      </div>
      <Container className={ModuleStyles.Grid}>
        <SvgWrapperComponent ariaHidden={true}><SharedAssets.Mailbox className={ModuleStyles.Mailbox} /></SvgWrapperComponent>
        <Button
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
          onClick={goToLogin}
        >
          {i18n.passwordResetEmailPage.labels.goToLogin}
        </Button>
        <div className={styles.ResendGroup}>
          <p className={styles.SmallText}>{globali18n.labels.spamWarning}</p>
        </div>
      </Container>
    </div>
  );
}
