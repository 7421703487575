
import { useNavigate } from 'react-router-dom';
import { useLoader } from "@ifs/libs";
import { resendValidationEmailRequest } from '../requests/resendValidationEmail.request'

export function useResendValidationEmail() {
  const { showLoaderWhile } = useLoader();
  const navigate = useNavigate();

  function resendValidationEmailCommand(email: string) {
    showLoaderWhile(async () => {
      const response = await resendValidationEmailRequest(email);
      if(response.status === 204 || response.status === 200) {
        navigate({
          pathname: '/auth/register/email',
          search: new URLSearchParams({ email }).toString()
        });       
      }
    }, {key: "resendValidationEmailCommand", value:"API POST - /api/auth/resend_validation_email"});
  }

  return { resendValidationEmailCommand };
}
