import { useEffect, useState, useContext, createRef } from 'react';
import styles from './StatutButton.module.scss';
import classNames from 'classnames';
import { ModalContext } from 'context/modalContext';
import { CustomerStatus } from 'models/customer-status';
import { globali18n } from 'i18n';
import { StatutData } from './StatutData';
import { StatutModal } from './components/StatutModal';
import { handleClickOutsideBox } from 'shared/clickOutsite';
import { i18n } from 'modules/appointments/i18n';
import { useAnalytics } from 'shared/Analytics/useAnalytics';

export function StatutButton(props: {
  statut: CustomerStatus;
  customerId: string | null | undefined;
  appointmentId: string | null | undefined;
  changeStatut: any;
  name: string | null | undefined;
  lastname: string | null | undefined;
  isLastElement: boolean;
}) {
  let { handleModal } = useContext(ModalContext);
  const [selectIsDisplay, setSelectIsDisplay] = useState(false);
  const [statutText, setStatutText] = useState('');

  const statut = StatutData[props.statut];
  const { simpleEvent } = useAnalytics();

  const changeCustomerStatut = (newStatut: string): void => {
    props.changeStatut(props.customerId, props.appointmentId, newStatut);
    simpleEvent("back_status");
    handleModal('close');
  };

  useEffect(() => {
    setStatutText(globali18n.status[props.statut]);
  }, [props.statut]);

  const changeStatut = (e: any) => {
    e.stopPropagation();
    setSelectIsDisplay((state) => !state);
  };

  const handleModalCtx = (newStatut: CustomerStatus): void => {
    const propsToPass = {
      name: props.name!,
      lastname: props.lastname!,
      newStatut: newStatut,
      currentStatut: statut.i18n,
      handleModal: handleModal,
      changeCustomerStatut: changeCustomerStatut
    };
    return handleModal(<StatutModal {...propsToPass} />);
  };

  const generateStatutList = (): JSX.Element => {
    return (
      <div
        className={classNames({
          [styles.StatutChangeBoxBottom]: !props.isLastElement,
          [styles.StatutChangeBoxTop]: props.isLastElement
        })}
        ref={boxRef}
      >
        {statut.authorizeStatut.map((element: CustomerStatus) => (
          <div className={styles.StatutButtonChildren} onClick={() => handleModalCtx(element)}>
            {globali18n.status[element]}
          </div>
        ))}
      </div>
    );
  };

  const ref: React.RefObject<HTMLDivElement> = createRef();
  const boxRef: React.RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutsideBox(e, ref, setSelectIsDisplay)
    );
    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutsideBox(e, ref, setSelectIsDisplay)
      );
    };
  });

  return (
    <div
      onClick={(e) => {
        changeStatut(e);
      }}
      ref={ref}
      className={styles.StatutButton}
      style={{ ...statut.style }}
    >
      {statutText}

      {selectIsDisplay && generateStatutList()}
    </div>
  );
}
