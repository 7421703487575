import styles from './AuthPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'modules/auth/i18n';
import { Button, ButtonStyles } from '@ifs/libs';
import classNames from 'classnames';
import { useAnalytics } from 'shared/Analytics/useAnalytics';

const t = i18n.AuthPage;

export function AuthPage() {
  const navigate = useNavigate();
  const { simpleEvent } = useAnalytics()
  return (
    <div className={styles.AuthContainer}>
      <Button
        className={classNames(ButtonStyles.PrimaryAction)}
        onClick={(): void => {
          simpleEvent("signup")
          navigate('/auth/register');
        }}
      >
        {t.register}
      </Button>
      <Button
        className={classNames(ButtonStyles.PrimaryAction)}
        onClick={(): void => {
          simpleEvent("login")
          navigate('/auth/login');
        }}
      >
        {t.signin}
      </Button>
      <Button
        className={classNames(ButtonStyles.PrimaryAction)}
        onClick={(): void => {
          simpleEvent("reset_password")
          navigate('/auth/password-reset');
        }}
      >
        {t.passwordReset}
      </Button>
    </div>
  );
}
