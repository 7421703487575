import { ESubscriptionDecision, EPersonalizedFollowup } from "modules/customer-file/enums";

export const i18n = {
    [ESubscriptionDecision.Yes]: "OUI",
    [ESubscriptionDecision.No]: "NON",
    [ESubscriptionDecision.NotYet]: "Pas encore",
    [EPersonalizedFollowup.Yes]: "OUI",
    [EPersonalizedFollowup.No]: "NON",
    [EPersonalizedFollowup.Partially]: "Pas encore",
    
}