import { ChangeEvent, useEffect, useState } from 'react';
import { i18n } from 'modules/advisor-course/i18n';
import styles from './CustomerCreationForm.module.scss';
import { useNavigate } from 'react-router-dom';
import { CheckboxGroup } from 'shared/CheckboxGroup/CheckboxGroup';
import { condition as Displayed } from './Displayed';
import { useInitializeAccountCommand } from 'modules/advisor-course/commands/initializeAccountCommand';
import { Button, ButtonGroup, ButtonGroupOption, TextInput } from '@ifs/libs';
import classNames from 'classnames';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import buttonStyles from 'styles/ButtonStyle.module.scss';

const t = i18n.CustomerCreationPage.CustomerCreationFormComponent.labels;

export function CustomerCreationForm() {
  const navigate = useNavigate();
  const { createCustomerEvent, simpleEvent } = useAnalytics();
  const { initializeAccountCommand } = useInitializeAccountCommand();
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
  const [isBNPCustomer, setBNPCustomer] = useState<number>();
  const [createAccountButton, setCreateAccountButton] = useState<boolean>(true);

  const handleRegExEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    setEmail(value);

    if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value) && value.length < 51) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  const handleRegExName = (event: ChangeEvent<HTMLInputElement>, isFirstNameFormField: boolean) => {
    const value = event?.target.value;
    isFirstNameFormField ? setFirstname(value) : setLastname(value);
    const valueIsValid = !!value.match(/^([a-zÀ-ž\u0370-\u03FF\u0400-\u04FF- ']{2,50})$/i);
    isFirstNameFormField ? setFirstNameValid(valueIsValid) : setLastNameValid(valueIsValid);
  };

  const yesNoOptions: ButtonGroupOption[] = [
    { label: t.yes, value: 1 },
    { label: t.no, value: 0 }
  ];

  const handleRegister = async () => {
    const body = {
      firstName: firstname,
      lastName: lastname,
      email,
      isBankClient: isBNPCustomer
    };

    if (isBNPCustomer === 0) {
      createCustomerEvent({
        event: 'client_not_created',
        bnp_customer: false
      });
    } else {
      createCustomerEvent({
        event: 'client_created',
        bnp_customer: true
      });
    }

    await initializeAccountCommand(body);
  };

  useEffect(() => {
    const isBNPClientInfoRadioFormFieldMandatory = Displayed.displayIsBnpClientInformation;
    const commonFormFieldIsValid = emailIsValid &&
      firstname.length >= 2 &&
      firstNameValid &&
      lastname.length >= 2 &&
      lastNameValid;
    if (commonFormFieldIsValid) {
      // Check if we have the form field Client Bnp to answer, if not the button creation is available.
      // Otherwise, need to select one of the options (yesNoOptions var)
      setCreateAccountButton(isBNPClientInfoRadioFormFieldMandatory ? typeof isBNPCustomer === 'number' : true);
    } else {
      // Common Form Field not OK      
      setCreateAccountButton(false);
    }
  }, [emailIsValid, firstname, lastname, isBNPCustomer]);

  return (
    <div className={styles.Body}>
      <div className={styles.InputBloc}>
        <label>{t.mail}</label>
        <TextInput
          placeholder={t.mail}
          className={styles.Input}
          onChange={handleRegExEmail}
          value={email}
          maxLength={255}
          type={'email'}
        />
        {!emailIsValid && email.length !== 0 && (
          <div className={styles.PasswordConfirmError}>Cette adresse email n'est pas valide.</div>
        )}
      </div>

      <div className={styles.InputBloc}>
        <label>{t.firstName}</label>
        <TextInput
          placeholder={t.firstName}
          className={styles.Input}
          onChange={(e) => handleRegExName(e, true)}
          value={firstname}
          maxLength={255}
          type={'text'}
        />
        {!firstNameValid && firstname.length !== 0 && (
          <div className={styles.PasswordConfirmError}>Le format est incorrect.</div>
        )}
      </div>

      <div className={styles.InputBloc}>
        <label>{t.name}</label>
        <TextInput
          placeholder={t.name}
          className={styles.Input}
          onChange={(e) => handleRegExName(e, false)}
          value={lastname}
          maxLength={255}
          type={'text'}
        />
        {!lastNameValid && lastname.length !== 0 && (
          <div className={styles.PasswordConfirmError}>Le format est incorrect.</div>
        )}
      </div>
      {Displayed.displayIsBnpClientInformation && (
        <div className={styles.CheckboxContainer}>
          <label className={styles.Label}> {t.bnpCustomerTitle} </label>
          <div>
            <label className={styles.Description} htmlFor={'isBnppClient'}>
              {t.bnpCustomerDescription}
            </label>
          </div>
          <CheckboxGroup>
            <ButtonGroup
              className={styles.ButtonGroup}
              options={yesNoOptions}
              onChange={(value) => {
                setBNPCustomer(value);
              }}
            />
          </CheckboxGroup>
        </div>
      )}

      <div className={styles.ActionBox}>
        <Button
          onClick={(): void => {
            if (createAccountButton) {
              handleRegister();
            }
          }}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          disabled={!createAccountButton}
        >
          {t.createCustomer}
        </Button>
        <Button
          onClick={() => {
            simpleEvent('back_client_creation');
            navigate('/advisor-course/search-client');
          }}
          className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
        >
          {t.back}
        </Button>
      </div>
    </div>
  );
}
