export const i18n = {
    SuperAdmin:{
        labels:{
            addressMail: 'Adresse mail',
            activation: 'Activation',
            deactivation: 'Désactivation',
        },
        errors : {
            emailDomain: 'Le domaine de l’adresse e-mail est invalide.'
        },
        notification:{
            activationSuccess : (email:string)=> `L'activation de : ${email} est un succes`,
            activationError : (email:string)=> `Impossible d'activer : ${email}`,
            deactivationSuccess : (email:string)=> `La désactivation : ${email} est un succes`,
            deactivationError : (email:string)=> `Impossible de désactiver : ${email}`,
        }
    }
};
