import { useState, useEffect } from 'react';
import styles from './CustomerInformationsCard.module.scss';
import moment from 'moment';
import { DashedLineIcon, MinusIcon } from 'shared/assets';
import { i18n } from '../../i18n';
import { globali18n } from 'i18n';
import { condition } from 'modules/customer-file/Displayed';
import { TimeSlotModel } from 'shared/models/TimeSlot.model';
import { getTimeSlotValue } from 'Utils/GetTimeSlotValue';
import { Tooltip } from '@progress/kendo-react-tooltip';
import classNames from 'classnames';
import { CustomerInformationResponse } from 'modules/customer-file/models/Customer';
import { DownloadReportIcon } from 'modules/customer-file/assets';
import { Button } from 'shared';
import { useGetCustomerInfoCommand } from 'modules/customer-file/commands/getCustomerInfoCommand';
import { CustomerStatus } from 'models/customer-status';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { AppointmentResult } from 'modules/appointments/models';
import { useGlobalCommand } from '@ifs/libs';
import { CustomerSSOInformationsData } from './components/CustomerSSOInformationsData/CustomerSSOInformationsData';
interface props {
  appointmentLoaded: boolean;
  customerAppointmentsHistory: AppointmentResult[];
  customerInformationsLoaded: boolean;
  customerInformations: CustomerInformationResponse | null;
  hasReport: boolean;
}

export default function CustomerInformationsCard({
  appointmentLoaded,
  customerAppointmentsHistory,
  customerInformationsLoaded,
  customerInformations,
  hasReport
}: props) {
  const [isDisplay, setIsDisplay] = useState(true);
  const { generateReport } = useGetCustomerInfoCommand();
  const { isAuthenticationModeSSO } = useGlobalCommand();

  const authenticationSSO = isAuthenticationModeSSO();

  function openReport() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const customerid = urlParams.get('customerid');
    generateReport(customerid!);
  }

  return (
    <div className={styles.InformationsCardContainer}>
      <div
        className={styles.InformationsCardHeader}
        onClick={() => setIsDisplay((state: boolean) => !state)}
      >
        <span>{i18n.customerInformationCard.labels.customerInformation}</span>
        <div className={styles.InformationsCardHeaderImage}>
          <MinusIcon />
        </div>
      </div>
      {isDisplay && !authenticationSSO && (
        <div className={styles.InformationsCardBody}>
          {customerInformationsLoaded && customerInformations && (
            <RenderInformationCard
              customerInformations={customerInformations}
              hasReport={hasReport}
              openReport={openReport}
            />
          )}
          <div className={styles.ImageBox}>
            <DashedLineIcon />
          </div>
          {appointmentLoaded && (
            <RenderHistory customerAppointmentsHistory={customerAppointmentsHistory} />
          )}
        </div>
      )}
      {isDisplay && authenticationSSO && (
        <CustomerSSOInformationsData customerInformations={customerInformations!} hasReport={hasReport} openReport={openReport} />       
      )}
    </div>
  );
}

function RenderInformationCard({
  customerInformations,
  hasReport,
  openReport
}: {
  customerInformations: CustomerInformationResponse;
  hasReport: boolean;
  openReport: () => void;
}) {
  function renderEmailTooltip(): boolean {
    return customerInformations?.email?.length > 40;
  }

  function getEmailInformationValue(): string {
    return `${customerInformations?.email.substr(0, 40)}${renderEmailTooltip() ? '...' : ''}`;
  }

  return (
    <Tooltip anchorElement="target" position="top" content={(props) => <span>{props.title}</span>}>
      <div className={styles.DetailLeft}>
        <div>
          <span className={styles.Label}>{globali18n.labels.lastName} :</span>
          <span>{customerInformations.lastName}</span>
        </div>
        <div>
          <span className={styles.Label}>{globali18n.labels.firstName} :</span>
          <span>{customerInformations.firstName}</span>
        </div>
        <div>
          <span className={styles.Label}>{globali18n.labels.email} : </span>
          <span
            className={styles.Content}
            title={renderEmailTooltip() ? customerInformations?.email : ''}
          >
            {getEmailInformationValue()}
          </span>
        </div>
        <div>
          <span className={styles.Label}>{globali18n.labels.telephone} :</span>
          <span>{customerInformations.phoneNumber}</span>
        </div>
        <div>
          <span className={styles.Label}>{globali18n.labels.postalCode} :</span>
          <span
            className={classNames({
              [styles.CursorPointer]: customerInformations?.location?.locality
            })}
            title={customerInformations?.location?.locality || ''}
          >
            {customerInformations?.location?.postalCode}
          </span>
        </div>
        {condition.displayIsBnpClientInformation && (
          <div>
            <span className={styles.Label}>{globali18n.labels.clientBNPPDeclared} :</span>
            <span>
              {customerInformations.isBankClient ? globali18n.labels.yes : globali18n.labels.no}
            </span>
          </div>
        )}
        <div>
          <span className={styles.Label}>
            {i18n.customerInformationCard.labels.informativeContent} :
          </span>
          <span>
            {customerInformations.canReceiveInformativeContent
              ? globali18n.labels.yes
              : globali18n.labels.no}
          </span>
        </div>
        <div>
          <span className={styles.Label}>
            {i18n.customerInformationCard.labels.commercialContent} :
          </span>
          <span>
            {customerInformations.canReceiveCommercialContent
              ? globali18n.labels.yes
              : globali18n.labels.no}
          </span>
        </div>
      </div>
      <Button
        disabled={!hasReport}
        onClick={openReport}
        className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
      >
        <span className={styles.ButtonContainer}>
          <DownloadReportIcon />
          <span>Rapport</span>
        </span>
      </Button>
    </Tooltip>
  );
}

function RenderHistory({ customerAppointmentsHistory }: any) {
  function sortDateAppointment(a: Appointment, b: Appointment) {
    // if desiredDate no need to resort the list
    if (a.desiredDate) {
      return 0;
    }
    // if desiredDate not defined (Matmut case), need sort list by creationDateTime property
    const dateAToSort = new Date(a.createdAt);
    const dateBToSort = new Date(b.createdAt);
    return dateAToSort === dateBToSort ? 0 : dateAToSort < dateBToSort ? -1 : 1;
  }

  function renderDate(appointment: Appointment) {
    if (appointment.desiredDate) {
      return `${moment(new Date(appointment.desiredDate)).format('DD-MM-YYYY')} -  `;
    }
    return `${moment(new Date(appointment.createdAt)).format('DD-MM-YYYY')} - ${moment(
      appointment.createdAt
    )
      .format('HH:mm')
      .replace(':', 'h')}`;
  }

  return (
    <div className={styles.DetailRight}>
      <div>{i18n.customerInformationCard.labels.historicMeeting} :</div>

      {customerAppointmentsHistory.sort(sortDateAppointment).map((item: Appointment) => {
        const { desiredTimeSlot } = item;
        return (
          <div key={Math.floor(Math.random() * 956)}>
            <span className={styles.HistoryDate}>
              {renderDate(item)}
              {!!desiredTimeSlot ? getTimeSlotValue(desiredTimeSlot) : ''}{' '}
              {condition.displayMeetingStatus && <span>:</span>}
            </span>
            {condition.displayMeetingStatus &&
              (() => {
                let formatedStatut = '';
                switch (item.status) {
                  case 'Requested':
                    formatedStatut = globali18n.status[CustomerStatus.Requested];
                    break;
                  case 'PendingConfirmation':
                    formatedStatut = globali18n.status[CustomerStatus.PendingConfirmation];
                    break;
                  case 'Confirmed':
                    formatedStatut = globali18n.status[CustomerStatus.Confirmed];
                    break;
                  case 'Honored':
                    formatedStatut = globali18n.status[CustomerStatus.Honored];
                    break;
                  case 'Canceled':
                    formatedStatut = globali18n.status[CustomerStatus.Canceled];
                    break;

                  default:
                    break;
                }
                return <span>{formatedStatut}</span>;
              })()}
          </div>
        );
      })}
    </div>
  );
}

type Appointment = {
  desiredDate?: any;
  status?: any;
  desiredTimeSlot?: TimeSlotModel;
  createdAt: string;
};
