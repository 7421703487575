import { AxiosResponse } from "axios";
import { useLoader } from "@ifs/libs";
import { RemarkActionPayload } from "../models/advisorCustomerAction";
import { createRemarkRequest } from "../requests/createRemarkRequest";
import { apiUrls } from "shared/apiUrls";

export function useCreateRemarkCommand(){
    const { showLoaderWhile } = useLoader();

    async function createRemarkCommand(payload: RemarkActionPayload): Promise<AxiosResponse<string, any>> {
        return await showLoaderWhile(async () => {
            try{
                return await createRemarkRequest(payload);
            }catch(error){
                throw error;
            }
        }, {key: "authResolver", value:`API POST - ${apiUrls().advisorCustomerActions.post}`})
    }

    return { createRemarkCommand };
}