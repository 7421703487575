import { ProductInterface } from "@ifs/libs/src/modules/recommendation/requests/getProducts";
import { apiUrls } from "@ifs/libs/src/shared/apiUrls";
import { Pagination } from "@ifs/libs/src/shared/models/Pagination";
import axios from "axios";

interface ProductsPaginationResponse {
    pagination: Pagination;
    results: ProductInterface[];
}

export async function getProducts(): Promise<ProductInterface[]> {
    const apiUrl = apiUrls().products;
    const response = await axios.get<ProductsPaginationResponse>(apiUrl);
    return response.data!.results;
}