import { ValidatedPensionNeedModel, ValidatedPensionNeedPaginatedModel } from "@ifs/libs/src/modules/pension-need/models/request/validationRequest.model";
import { apiUrls } from "@ifs/libs/src/shared/apiUrls";
import { axiosRequestValidateStatusConfig } from "@ifs/libs/src/shared/axiosValidateStatusConfig";
import axios from "axios";

export async function getCustomerPensionNeedsRequest(params: string): Promise<ValidatedPensionNeedModel | null> {
    const apiUrl = apiUrls(params).pensionNeed.validations;

    const response = await axios.get<ValidatedPensionNeedPaginatedModel>(apiUrl, {
        params: { limit: 1, sortBy: "CreatedAt Desc" },
        ...axiosRequestValidateStatusConfig
      });

    if (response.status === 204, response.status === 404) {
        return null;
    }
    
    const data = response.data;
    
    return data?.results[0] || null;
}
