import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './LoginPage.module.scss';
import { AuthIcon } from 'modules/auth/assets';
import { i18n } from 'modules/auth/i18n';
import { globali18n } from 'i18n';
import {
  Button,
  EmailInputGroup,
  TextInputPassword,
  useSearchParams,
  ValidationMessages
} from '@ifs/libs/src/shared';
import { useLoginCommand } from 'modules/auth/commands/loginCommand';
import classNames from 'classnames';
import { InputGroup } from '@ifs/libs';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import { Footer } from 'shared/Footer/Footer';
import { useForm } from 'react-hook-form';
import { hasErrors } from '@ifs/libs/src/shared/utilities/hasErrors';
import { LoginOutcome } from 'modules/auth/requests/loginRequest';
import { passwordMaxLength } from '@ifs/libs/src/shared/utilities/validation';
import { getFormConfig } from './formConfig';
import { getValidationMessages } from '@ifs/libs/src/shared/utilities/getValidationMessages';
import buttonStyles from 'styles/ButtonStyle.module.scss';

interface FormData {
  email: string;
  password: string;
}

export function LoginPage() {
  const { simpleEvent, pageView } = useAnalytics();
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const [userLockedOut, setUserLockedOut] = useState(false);
  const searchParams = useSearchParams();
  const { loginCommand } = useLoginCommand();
  const navigate = useNavigate();
  const showAccountActivated = searchParams.get('accountActivated') === 'true';

  const { register, handleSubmit, formState, reset } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {}
  });

  const errors = formState.errors;

  const formConfig = getFormConfig(register);

  async function onSubmit(data: FormData) {
    const model = {
      email: data.email,
      password: data.password
    };
    const outcome = await loginCommand(model);

    if (outcome === LoginOutcome.IncorrectCredentials) {
      pageView({
        page_title: i18n.loginPage.pageTitle,
        page_path: '',
        page_type: 'popin',
        visitor_mode: ''
      });
    }

    setIncorrectCredentials(outcome === LoginOutcome.IncorrectCredentials);
    setUserLockedOut(outcome === LoginOutcome.UserLockedOut);
  }

  function onSubmitFailed() {
    setIncorrectCredentials(false);
    setUserLockedOut(false);
  }

  function cancel() {
    reset({
      email: '',
      password: ''
    });
    navigate("/auth");
  }

  return (
    <div className={styles.LoginContainer}>
      <div className={styles.Header}>
        <h1>{i18n.loginPage.title}</h1>
        <span>{i18n.loginPage.subTitle}</span>
      </div>
      <div className={styles.Body}>
        {showAccountActivated && (
          <h2 className={styles.AccountActivated}>{i18n.AuthPage.accountActivated}</h2>
        )}
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit, onSubmitFailed)}
          className={styles.LoginForm}
        >
          <EmailInputGroup
            className={styles.InputStyle}
            labelClassName={styles.Label}
            name={'email'}
            register={register}
            errors={errors}
          />
          <InputGroup isInvalid={hasErrors('password', errors)}>
            <label htmlFor={'password'} className={styles.Label}>
              {i18n.loginPage.labels.password}
            </label>
            <TextInputPassword
              className={styles.InputStyle}
              inputError={hasErrors('password', errors)}
              maxLength={passwordMaxLength}
              id="password"
              register={formConfig.password.field}
            />
            <ValidationMessages
              messages={getValidationMessages('password', errors, formConfig.password.errors)}
            />
          </InputGroup>

          {incorrectCredentials && (
            <div className={styles.ErrorMessage}>{i18n.loginPage.errors.incorrectCredentials}</div>
          )}
          {userLockedOut && (
            <div className={styles.ErrorMessage}>{i18n.loginPage.errors.userLockedOut}</div>
          )}

          <div className={styles.ActionBox}>
            <Button className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}>
              {i18n.loginPage.labels.connect}
            </Button>
            <Button
              className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
              onClick={cancel}
            >
              {globali18n.labels.cancel}
            </Button>

            <Link
              className={styles.ResetLink}
              to={'/auth/password-reset'}
              onClick={() => simpleEvent('forgot_password')}
            >
              {i18n.loginPage.labels.passwordReset}
            </Link>
          </div>
        </form>
        <div className={styles.BackgroundIcon}>
          <AuthIcon />
        </div>
      </div>
      <Footer />
    </div>
  );
}
