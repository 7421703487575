import { i18n } from "modules/advisor-course/i18n";
import React, { useEffect, useState } from "react";
import { PageWithMenu } from "shared";
import CustomerTile from "./components/CustomerTile/CustomerTile";
import { Filter, SearchParams } from "./components/Filter/Filter";
import styles from "./SearchCustomer.module.scss";
import Pagination from "react-responsive-pagination";
import { useSearchCustomerCommand } from "modules/advisor-course/commands/searchCustomerCommand";
import { assignCustomerToAdvisorRequest } from "shared/request/assignCustomerToAdvisorRequest";
import { deassignCustomerToAdvisorRequest } from "shared/request/deassignCustomerToAdvisorRequest";
import { SearchCustomerParams } from "modules/advisor-course/models/searchCustomer.model";
import { useAnalytics } from "shared/Analytics/useAnalytics";
import { CustomerInformationResponse } from "modules/customer-file/models/Customer";

export function SearchCustomer() {
  const [customersSearchList, setCustomersSearchList] = useState<CustomerInformationResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [searchParams, setSearchParams] = useState({} as SearchParams);
  const [isSearchDone, setIsSearchDone] = useState<boolean>(false);
  const { searchCustomerCommand } = useSearchCustomerCommand();

  const {  simpleEvent } = useAnalytics()

  useEffect(() => {
    handleSearch();
  }, [])

  const applySearch = (params:SearchParams) => {
    setCurrentPage(1);
    handleSearch(params);
  }

  const handleSearch = async (params?: SearchParams) => {
    simpleEvent('search_clients')
    if (params) {
      setSearchParams(params);
    }
    const response = await searchCustomerCommand(createSearchParamsRequest(currentPage, params))  
    if (response) {
      setCustomersSearchList(response.results);
      setTotalPages(Math.ceil(response.pagination!.totalResultsCount/response.pagination!.resultsCount));
      setIsSearchDone(true);
    }
  }

  const changePaginationIndex = async (index: number) => {
    setCurrentPage(index);
    const response = await searchCustomerCommand(createSearchParamsRequest(index, searchParams))  
    if (response) {
      setCustomersSearchList(response.results);
    }
  };

  const assignCustomerToAdvisor = async (customerIdentifier: string) => {
    await assignCustomerToAdvisorRequest({customerId: customerIdentifier});
    handleSearch(searchParams);
  };

  const unAssignCustomerFromAdvisor = async (customerIdentifier: string) => {
    await deassignCustomerToAdvisorRequest({customerId:customerIdentifier});
    handleSearch(searchParams);
  };

  const createSearchParamsRequest = (PageNumber: number, searchParams?: SearchParams): SearchCustomerParams => {
    const limit = 10;
    const offset = (PageNumber - 1 )* limit;
    const params = { limit, offset } as SearchCustomerParams;
    if (searchParams) {
      if (searchParams.lastName) {
        params.customerLastName = searchParams.lastName;
      }
      if (searchParams.firstName) {
        params.customerFirstName = searchParams.firstName;
      }
      if (searchParams.emailAdress) {
        params.customerEmail = searchParams.emailAdress;
      }
      if (searchParams.advisors) {
        params.advisorId = searchParams.advisors.map((advisor) => advisor.value);
      }
    }
    return params;
  }
  
  useEffect(() => {
    if(isSearchDone && customersSearchList.length === 0) {
      simpleEvent("no_results")
    }
  }, [isSearchDone, customersSearchList])
  return (
    <PageWithMenu reloadCounterCustomer>
      <Filter applySearch={applySearch} />
      <div className={styles.Root}>
        {
          isSearchDone && !!customersSearchList.length &&
          <div className={styles.Header}>
            <span>{i18n.Filter.firstName}</span>
            <span>{i18n.Filter.lastName}</span>
            <span>{i18n.Filter.email}</span>
            <span>{i18n.Filter.advisor}</span>
          </div>
        }

        {customersSearchList.length !== 0 ? (
          <div className={styles.Container}>
            {customersSearchList.map((customer, index: number) => (
              <div key={`customer-search-${index}`}>
                <CustomerTile customer={customer}
                  assignCustomer={() => assignCustomerToAdvisor(customer.id)}
                  unAssignCustomer={() => unAssignCustomerFromAdvisor(customer.id)}
                />
              </div>
            ))}
            <Pagination
              current={currentPage}
              total={totalPages}
              onPageChange={changePaginationIndex}
            />
          </div>
        ) : isSearchDone ? (
          <div className={styles.NoResults}>
            <span>Aucun résultat trouvé</span>
          </div>
        ) : <span></span>}
      </div>
    </PageWithMenu>
  );
}
