// import '@progress/kendo-theme-default/dist/all.css';
import styles from './App.module.scss';
import { Provider as ReactReduxProvider } from 'react-redux';

import {
  CustomerAppointmentPage,
  CustomerFilePage,
  SuperAdmin,
  TenantSelectionPage
} from './modules';

import { Route, Routes } from 'react-router-dom';
import { PageWithMenu, SimulationFlowLayout } from 'shared';
import { SearchCustomer } from 'modules/advisor-course/pages/SearchCustomer/SearchCustomer';
import { CustomerCreation } from 'modules/advisor-course/pages/CustomerCreation/CustomerCreation';
import { store } from 'store';
import { globali18n } from 'i18n';

import {
  DashboardModule,
  LegalPensionModule,
  PatrimonyModule,
  PensionNeedModule,
  TaxationModule,
  ModalProvider as LibsModalProvider,
  LifeProjectsModule,
  RecommendationModule,
  Loader,
  ErrorBoundaryFallbackComponent,
  TechnicalErrorPage,
  use401Interceptor,
  InvestorProfileModule,
  CookiesPage,
  ScrollToTop
} from '@ifs/libs';

import {
  IntlProvider as KendoIntlProvider,
  LocalizationProvider as KendoLocalizationProvider
} from '@progress/kendo-react-intl';
import {
  load as kendoIntlLoad,
  loadMessages as kendoLocalizationLoadMessages
} from '@progress/kendo-react-intl';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frCurrencies from 'cldr-numbers-full/main/fr/currencies.json';
import dateFields from 'cldr-dates-full/main/fr/dateFields.json';
import caGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import { useAppResolver } from 'shared/resolvers/app.resolver';
import { ErrorBoundary } from 'react-error-boundary';
import { Cookies } from 'modules/cookies/Cookies';
import { PageRoute } from '@ifs/libs/src/shared/routing/PageRoute';
import { CustomerSearchPage } from 'modules/customer-file/pages/CustomerSearchPage/CustomerSearchPage';
import { PublicGuardedRoute } from 'shared/guards/PublicGuardedRoute';
import { PrivateGuardedRoute } from 'shared/guards/PrivateGuardedRoute';
import { AuthenticationModule } from 'modules/auth/AuthenticationModule';
import { RegistrationResendPage } from 'modules/auth/pages/RegistrationResendPage/RegistrationResendPage';
import { ModalProvider } from 'context/modalContext';
import { AnalyticsComponent } from '@ifs/libs/src/shared/components/datalayer/AnalyticsComponent';

const locale = 'fr';

// Required for NumericTextBox
kendoIntlLoad(frNumbers, frCurrencies);
// Required for DatePicker
kendoIntlLoad(dateFields, caGregorian, weekData);
kendoLocalizationLoadMessages({ datepicker: { toggleCalendar: 'Ouvrir le calendrier' } }, locale);

export default function App() {
  return (
    <div className={styles.App}>
      <ReactReduxProvider store={store}>
        <KendoIntlProvider locale={locale}>
          <KendoLocalizationProvider language={locale}>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorBoundaryFallbackComponent resetErrorBoundary={resetErrorBoundary}>
                  <Main>
                    <TechnicalErrorPage error={error} />
                  </Main>
                </ErrorBoundaryFallbackComponent>
              )}
            >
              <LibsModalProvider>
                <TenantContainer>
                  <ScrollToTop />
                  <Loader />
                  <Routing />
                </TenantContainer>
              </LibsModalProvider>
            </ErrorBoundary>
          </KendoLocalizationProvider>
        </KendoIntlProvider>
      </ReactReduxProvider>
    </div>
  );
}

function TenantContainer(props: React.PropsWithChildren<any>) {
  const { loading } = useAppResolver();
  use401Interceptor();
  if (loading) {
    return null;
  }

  return <>{props.children}</>;
}

function Routing() {
  return (
    <Main>
      <Routes>
        <Route
          path="/"
          element={
            <PageRoute
              pageTitle={globali18n.title}
              pageTitlePropsExclusive={true}
              element={
                <PrivateGuardedRoute>
                  <ModalProvider>
                    <AnalyticsComponent
                      page_title={'Les rendez-vous clients'}
                      page_path={'/'}
                      page_type={'appointment'}
                      simulator_screen={'appointment'}
                      simulator_step={''}
                      simulator_type={'appointment'}
                    >
                      <CustomerAppointmentPage />
                    </AnalyticsComponent>
                  </ModalProvider>
                </PrivateGuardedRoute>
              }
            />
          }
        />
        <Route
          path="/auth/*"
          element={
            <PublicGuardedRoute>
              <ModalProvider>
                <AuthenticationModule />
              </ModalProvider>
            </PublicGuardedRoute>
          }
        />
        <Route
          path="/tenant-selection"
          element={
            <PrivateGuardedRoute>
              <ModalProvider>
                <TenantSelectionPage />
              </ModalProvider>
            </PrivateGuardedRoute>
          }
        />
        <Route
          path="/register/email/resend"
          element={
            <PublicGuardedRoute>
              <RegistrationResendPage />
            </PublicGuardedRoute>
          }
        />

        <Route path="/cookies" element={<Cookies />} />

        <Route
          path="/admin"
          element={
            <PrivateGuardedRoute>
              {' '}
              <PageWithMenu isAdmin={true}>
              <AnalyticsComponent
                      page_title={'Admin'}
                      page_path={'/admin'}
                      page_type={'admin'}
                      simulator_screen={'admin'}
                      simulator_step={''}
                      simulator_type={'admin'}
                    >
                <SuperAdmin />
                </AnalyticsComponent>
              </PageWithMenu>
            </PrivateGuardedRoute>
          }
        />

        <Route
          path="/customer-search"
          element={
            <PageRoute
              pageTitle={globali18n.title}
              pageTitlePropsExclusive={true}
              element={
                <PrivateGuardedRoute>
                  <ModalProvider>
                    <AnalyticsComponent
                      page_title={'Recherche client'}
                      page_path={'/customer-search'}
                      page_type={'customer'}
                      simulator_screen={'customer'}
                      simulator_step={''}
                      simulator_type={'customer'}
                    >
                      <CustomerSearchPage />
                    </AnalyticsComponent>
                  </ModalProvider>
                </PrivateGuardedRoute>
              }
            />
          }
        />
        <Route
          path="/customer-file"
          element={
            <PageRoute
              pageTitle={globali18n.title}
              pageTitlePropsExclusive={true}
              element={
                <PrivateGuardedRoute>
                  <ModalProvider>
                    <AnalyticsComponent
                      page_title={'Fiche client'}
                      page_path={'/customer-file'}
                      page_type={'customer'}
                      simulator_screen={'customer'}
                      simulator_step={''}
                      simulator_type={'customer'}
                    >
                      <CustomerFilePage />
                    </AnalyticsComponent>
                  </ModalProvider>
                </PrivateGuardedRoute>
              }
            />
          }
        />

        <Route
          path="/advisor-course/search-client"
          element={
            <PrivateGuardedRoute>
              <ModalProvider>
                <AnalyticsComponent
                  page_title={'Rendez-vous clients'}
                  page_path={'/advisor-course/search-client'}
                  page_type={'customer'}
                  simulator_screen={'customer'}
                  simulator_step={''}
                  simulator_type={'customer'}
                >
                  <SearchCustomer />
                </AnalyticsComponent>
              </ModalProvider>
            </PrivateGuardedRoute>
          }
        />

        <Route
          path="/advisor-course/client-creation"
          element={
            <PrivateGuardedRoute>
              <ModalProvider>
                <AnalyticsComponent
                  page_title={'Nouveau Client'}
                  page_path={'/advisor-course/client-creation'}
                  page_type={'customer'}
                  simulator_screen={'customer'}
                  simulator_step={''}
                  simulator_type={'customer'}
                >
                  <CustomerCreation />
                </AnalyticsComponent>
              </ModalProvider>
            </PrivateGuardedRoute>
          }
        />

        <Route path="/flow/*" element={<SimulationFlowLayout />}>
          <Route
            path=":customerid/taxation/*"
            element={
              <div>
                <TaxationModule />
              </div>
            }
          />

          <Route
            path=":customerid/pension-need/*"
            element={
              <div>
                <PensionNeedModule />
              </div>
            }
          />

          <Route
            path=":customerid/legal-pension/*"
            element={
              <div>
                <LegalPensionModule />
              </div>
            }
          />

          <Route
            path=":customerid/patrimony/*"
            element={
              <div>
                <PatrimonyModule />
              </div>
            }
          />

          <Route
            path=":customerid/life-projects/*"
            element={
              <div>
                <LifeProjectsModule />
              </div>
            }
          />

          <Route
            path=":customerid/dashboard/*"
            element={
              <div>
                <DashboardModule />
              </div>
            }
          />

          <Route
            path=":customerid/recommendation/*"
            element={
              <div>
                <RecommendationModule />
              </div>
            }
          />
          <Route path=":customerid/investor-profile/*" element={<InvestorProfileModule />} />
          <Route path=":customerid/cookies/*" element={<CookiesPage />} />
        </Route>

        {/* <Route path="*" component={NotFoundRoute} /> */}
      </Routes>
    </Main>
  );
}

function Main(props: React.PropsWithChildren<any>) {
  return (
    <main id="main" className={styles.Main}>
      {props.children}
    </main>
  );
}
