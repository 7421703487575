export const i18n = {
    moduleName: "Recherche client",
    Filter : {
        newCustomer: "Nouveau Client",
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse mail",
        advisor: "Conseiller",
        advisorPlhd: "(Tous)",
        clearFilters: "Effacer les filtres",
    },
    attributeCustomer: "Attribution Client",
    Header: {
        label: {
            backToAdvisor: "Retour à l'espace conseiller",
            flowOf: "Parcours de",
        }
    },
    titleUnassign: "Voulez-vous désattribuer le client",
    titleAssign: "Voulez-vous vous attribuer le client",
    CustomerCreationPage: {
        newCustomer: "Nouveau Client",
        CustomerCreationFormComponent : {
            labels: {
                yes: 'Oui',
                no: 'Non',
                name: "Nom du client",
                firstName: "Prénom du client",
                mail: "Adresse e-mail du client",
                bnpCustomerTitle: "Client BNP Paribas",
                bnpCustomerDescription: "Le client dispose-t-il d’un compte-courant, d’un livret ou d’une assurance vie chez BNP Paribas ?",
                createCustomer: "Créer un client",
                back: "Retour"
            },
        }
    }
}