import { LockIcon } from '../../assets';
import { i18n } from '../../i18n';
import { i18n as componentI18n } from '../../../CollectionCard/components/i18n';
import styles from './Transaction.module.scss';
import { ProductTypeIdentifier } from '@ifs/libs/src/modules/recommendation/models/product-type-identifier.enum';

const t = i18n;

interface TransactionInterfaceProps {
  productTypeIdentifier: ProductTypeIdentifier;
  isFullExclude: boolean;
  isInitialLock: boolean;
  isMonthlyLock: boolean;
  transactionAmount: number | null;
  monthlyTransactionAmount: number | null; 
  noAccessMessage: string;
}

export function Transaction(props: TransactionInterfaceProps) {
  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Product}>
          <div className={styles.ProductName}>
            {componentI18n.RiskProfileCard.productType[props.productTypeIdentifier].productName}
          </div>
          <div className={styles.ProductSubTitle}>
            {componentI18n.RiskProfileCard.productType[props.productTypeIdentifier].productSubTitle}
          </div>
        </div>
        {props.isFullExclude ? (
          <div className={styles.LockContainer}>
            <div>
              <LockIcon />
            </div>
            <div className={styles.NoAcces}>{props.noAccessMessage}</div>
          </div>
        ) : (
          <div className={styles.AmountContainer}>
            <div className={styles.Amount}>
              {props.isInitialLock ? t.minimalAmount : props.transactionAmount + ' €'}
            </div>
            <div className={styles.Amount}>
              {props.isMonthlyLock ? t.minimalAmount : props.monthlyTransactionAmount + ' €'}
            </div>
          </div>
        )}
      </div>
      <div className={styles.Divider}></div>
    </>
  );
}
