export const i18n = {
  headerCookies: (
    <section>
      <p>
        Le Groupe BNP Paribas est particulièrement attentif à vous délivrer le meilleur service possible et nous souhaitons préserver la confiance que vous nous témoignez.
      </p>
      <p>
        Dans ce cadre, nous avons adopté des principes forts afin d’assurer la protection de vos données.
      </p>
      <p>
        C'est pourquoi nous souhaitons vous fournir des informations transparentes sur la manière dont nous plaçons, utilisons et stockons des cookies sur votre appareil lorsque vous consultez nos sites ou applications (ci-après le site « MonDemain») et vous présenter les options qui vous permettent de les gérer et les supprimer.
      </p>
    </section>
  ),
  whatIsACookie: (
    <section>
    <p>
      Les cookies sont de petits fichiers texte, image ou logiciel qui peuvent être déposés et/ou
      lus sur votre appareil lorsque vous accédez à notre Site. Le mot « appareil », lorsqu'il est
      utilisé dans la présente politique cookies, désigne notamment les ordinateurs, les
      téléphones intelligents, les tablettes et tout autre appareil utilisé pour accéder à
      Internet.
    </p>
    <p>
      Les cookies peuvent être (i) de session, ce qui signifie qu’ils sont supprimés de
      votre terminal une fois la session et le navigateur fermés ; ou (ii) persistants, ce qui
      signifie qu’ils seront stockés sur votre terminal jusqu’à leur suppression. Les cookies
      peuvent avoir de nombreuses fonctions et permettent notamment de :
    </p>
      <ul>
        <li>
          <b>vous authentifier et vous identifier</b> sur notre Site afin de vous fournir les
          services que vous avez demandés ;
        </li>
        <li>
          <b>améliorer la sécurité du Site</b> y compris par la prévention de l’usage frauduleux
          d’identifiants de connexion et la protection des données utilisateurs de l’accès par des
          tiers non autorisés (par exemple en limitant les tentatives d’accès robotisé ou
          inattendues) ;
        </li>
        <li>
          <b>suivre votre manière d’utiliser notre Site afin de l’améliorer</b>, par exemple en
          optimisant ses performances techniques ou son ergonomie ;
        </li>
        <li>
          <b>enregistrer vos préférences</b> et vos paramètres d'utilisation de notre Site (par
          exemple langue, fuseau horaire, etc.)
        </li>
      </ul>
    </section>
  ),
  typeOfInformationStored: (
    <section>
      <p>
        Les informations stockées par les cookies déposés sur votre appareil peuvent avoir trait aux
        éléments suivants, dans la limite de leur durée de conservation :
      </p>
      <p>
        <ul>
          <li> les pages web que vous avez visitées en utilisant cet appareil ;</li>
          <li>le type de navigateur que vous utilisez ;</li>
          <li>votre adresse IP ;</li>
          <li>et toute autre information que vous avez fournie sur notre Site.</li>
        </ul>
      </p>
    </section>
  ),
  typeOfUsage: (
    <section>
      <p>
        Les cookies que nous utilisons sur ce Site sont exclusivement des cookies qui ont pour seul
        but de permettre ou faciliter la communication par voie électronique ou qui sont strictement
        nécessaires au fonctionnement du Site.
      </p>
      <p>
        L’utilisation des cookies strictement nécessaires au bon fonctionnement du Site ne requiert
        pas votre consentement. C’est pourquoi aucun module de gestion de vos préférences en matière
        de cookies n’est disponible sur ce Site.
      </p>
      <p>
        <b>Cookies de mesure d'audience</b>
      </p>
    </section>
  ),
  hotJar: (<></>)
};
