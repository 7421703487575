export const isCustom = (item: { value: string }) => {
  return item.value === undefined;
};
export const addKey = (item: { id: number }) => {
  item.id = new Date().getTime();
};

export const handleChangeList = (event: any, setState: any) => {
    const values = event.target.value;
    const lastItem = values[values.length - 1];

    if (lastItem && isCustom(lastItem)) {
      values.pop();
      const sameItem = values.find((v: { text: any }) => v.text === lastItem.text);
      if (sameItem === undefined) {
        addKey(lastItem);
        values.push(lastItem);
      }
    }

    setState(values);

  };
