import axios from "axios";

interface BFFUserConfig {
  type: string;
  value: string
}

interface CheckBFFUserReturn {
  bffUserID: string;
  bffUserLogout: string;
}

export async function checkBFFUserConnectedRequest(): Promise<CheckBFFUserReturn | null> {
    try {
      const response = await axios.get<BFFUserConfig[]>(`/bff/user`); 
      const bffUserLogout = response.data && response.data.find((config) => config.type === "bff:logout_url")?.value || "";
      const preferredUsername = response.data && response.data.find((config) => config.type === "preferred_username")?.value || "";
      const bffUserID = preferredUsername ? preferredUsername : (response.data && response.data.find((config) => config.type === "sub")?.value || "");
      
      return { bffUserID, bffUserLogout };
    } catch (err) {    
      // User No authenticated
      return null;
    }
}
