import { UserType } from '@ifs/libs/src/shared/requests/getUserReferenceRequest';
import { setAuthentication } from 'shared/authenticationSlice';
import { checkBFFUserConnectedRequest } from 'shared/request/checkBFFUserConnectedRequest';
import { useAppDispatch, useAppSelector } from 'store';

export function useCheckSSOAuthenticationCommand() {
  const dispatch = useAppDispatch();
  const renderTenantSelectionPage = useAppSelector(
    (s) => s.authentication.renderTenantSelectionPage
  );

  async function checkSSOAuthentication(): Promise<any> {
    const bffUserAuthenticatedConfig = await checkBFFUserConnectedRequest();
    if (bffUserAuthenticatedConfig) {
      // User is always of type Advisor
      dispatch(
        setAuthentication({
          isAuthenticated: !!bffUserAuthenticatedConfig,
          customerId: bffUserAuthenticatedConfig.bffUserID,
          userType: UserType.Advisor,
          renderTenantSelectionPage,
          bffLogoutUrl: bffUserAuthenticatedConfig.bffUserLogout
        })
      );
    }
  }

  return { checkSSOAuthentication };
}
