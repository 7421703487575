import { useEffect, useState } from 'react';
import { PageWithMenu } from 'shared';
import { FooterLink } from 'shared/Footer/i18n';
import { useAppSelector } from 'store';
import { SectionTitle } from './components/SectionTitle';
import styles from './Cookies.module.scss';
import { i18n } from './i18n';

export function Cookies() {
  const tenant = useAppSelector((s) => s.tenant);

  function getPrivacyPage() {
    const urls = tenant.Urls as FooterLink;

    let url = '';

    Object.keys(urls).map((e) => {
      if (e === FooterLink.PrivacyPage) {
        url = urls[e as any];
      }
    });
    return url;
  }

  const [state, setState] = useState<string>('oui');

  function throwEvent() {
    window.dataLayer?.push({
      event: 'at_consent',
      visitor_mode: 'optout'
    });
  }

  function changeAtInternet(e: any) {
    if (e.target.value === 'oui') {
      sessionStorage.setItem('atInternet', 'true');
      throwEvent();
      setState('oui');
    } else {
      sessionStorage.setItem('atInternet', 'false');
      throwEvent();
      setState('non');
    }
  }

  useEffect(() => {
    const store = sessionStorage.getItem('atInternet');
    if (!store) {
      sessionStorage.setItem('atInternet', 'true');
    } else {
      if (store === 'true') {
        setState('oui');
      } else {
        setState('non');
      }
    }
  });

  const privacyUrl = getPrivacyPage();
  return (
    <PageWithMenu>
      <div className={styles.Container}>
        <h1 className={styles.PageTitle}>Politique d’utilisation des cookies</h1>
        <div className={styles.Card}>
          <div>{i18n.headerCookies}</div>
          <SectionTitle>1. Qu’est-ce qu’un cookie ?</SectionTitle>
          <div>{i18n.whatIsACookie}</div>
          <SectionTitle>
            2. Quels types d’informations peuvent être stockés dans un cookie ?
          </SectionTitle>
          <div>{i18n.typeOfInformationStored}</div>
          <span>
            Les cookies peuvent contenir des données à caractère personnel couvertes par notre{' '}
            <a href={privacyUrl} target="_blank" className={styles.Link}>
              Notice de Protection des Données
            </a>
            .
          </span>
          <br />
          <br />
          <br />
          <SectionTitle>3. Quel type de cookies utilisons nous et à quelles fins ?</SectionTitle>
          <div>{i18n.typeOfUsage}</div>
          <div>
            <p>
              <b>AT Internet</b>
            </p>

            <p>
              Permet la réalisation de statistiques de fréquentation et d’analyser le comportement
              des utilisateurs sur le Site.
            </p>

            <p>
              Vous pouvez obtenir de plus amples informations sur le Site Web de AT Internet en
              suivant ce lien :{' '}
              <a
                style={{ color: 'black' }}
                href="https://www.atinternet.com/rgpd-et-vie-privee/"
                target="_blank"
              >
                https://www.atinternet.com/rgpd-et-vie-privee/
              </a>
            </p>
            <p>
              Liste des cookies du partenaire utilisés sur le Site et durée de conservation de ces
              cookies par le partenaire :<br />
              pa_vid : expire après 13 mois
              <br />
              pa_user : expire après 13 mois
              <br />
              pa_privacy : expire après 13 mois
            </p>

            <p>
            Nous utilisons des outils de mesure d’audience qui permettent de bénéficier de l’exemption de votre consentement afin d’évaluer la performance de contenu du Site conformément aux conditions précisées par la CNIL.
              <br />
              Vous avez la possibilité de vous opposer au dépôt de ces cookies ci-après :
            </p>
            <p className={styles.InputGroup}>
              <div>
                <input
                  type="radio"
                  value="oui"
                  checked={state === 'oui'}
                  onChange={changeAtInternet}
                />
                <label htmlFor="oui" className={styles.Labels}>
                  Oui
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  value="non"
                  checked={state === 'non'}
                  onChange={changeAtInternet}
                />
                <label htmlFor="non" className={styles.Labels}>
                  Non
                </label>
              </div>
              <div className={styles.ATDisclaimert}>
                Je comprends qu’après l’activation de cette option, je serai comptabilisé comme un
                internaute refusant d’être audité et que mes données de navigation seront exclues
                des données restituées par AT Internet.
              </div>
            </p>
          </div>
          <div>{i18n.hotJar}</div>
        </div>
      </div>
    </PageWithMenu>
  );
}
