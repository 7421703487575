import { Pagination } from "@ifs/libs/src/shared/models/Pagination";
import { CustomerInformationResponse } from "modules/customer-file/models/Customer";

export type SearchCustomerParams = {
    offset: number;
    limit: number;
    advisorAssignmentFilterType?: EAdvisorAssignmentFilterType,
    advisorId?: string[];
    customerFirstName?: string;
    customerLastName?: string;
    customerEmail?: string;
    sortBy?: string,
}

export type SearchCustomerResponse = {
    results: CustomerInformationResponse[];
    pagination: Pagination;
}

export enum EAdvisorAssignmentFilterType {
    Selection = "Selection", 
    WithAdvisorOnly = "WithAdvisorOnly",
    WithoutAdvisorOnly = "WithoutAdvisorOnly",
}