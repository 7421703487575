import { AxiosOutcome } from "shared/enums/axiosOutcome.enum";
import { useLoader } from "@ifs/libs";
import { AccountDisableModel, accountDisableRequest } from "../requests/accountDisableRequest";

export function useAccountDisableCommand(){
    const { showLoaderWhile } = useLoader();

    async function accountDisableCommand(model: AccountDisableModel): Promise<AxiosOutcome> {
        return await showLoaderWhile(async () => {
          return  await accountDisableRequest(model);
        }, {key: "accountDisableCommand", value:"API GET - /api/auth/accounts/disable"})
    }

    return { accountDisableCommand };
}