import { UserType } from "@ifs/libs/src/shared/requests/getUserReferenceRequest";
import axios from "axios";

export async function getUserReferenceRequest(): Promise<UserReferenceModel> {
    const response = await axios.get(`/api/auth/user_reference`);
    return response.data
}

export type UserReferenceModel = {
    identifier: string;
    userType: UserType;
    firstName: string;
    lastName: string;
}
