import axios, { AxiosResponse } from "axios";
import { apiUrls } from "shared/apiUrls";

export async function putCustomer(customerId: string, model: SetCustomerInformationModel): Promise<AxiosResponse> {
    return await axios.put(apiUrls(customerId).customers.put, model);
}

export interface SetCustomerInformationModel {
    firstName: string| null;
    lastName: string| null;
    email: string | null;
    phoneNumber: string | null,
    isBankClient: boolean | null;
    canReceiveInformativeContent: boolean | null;
    canReceiveCommercialContent: boolean | null;
}