import { useNavigate } from "react-router-dom";
import { setAuthentication } from "shared/authenticationSlice";
import { getUserReferenceRequest } from "shared/request/getUserReferenceRequest";
import { useAppDispatch } from "store";

export function useInitUserReferenceCommand() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    async function initUserReferenceCommand(): Promise<any> {
        const userData = await getUserReferenceRequest();
        sessionStorage.setItem("identifier", userData.identifier);
        sessionStorage.setItem(
            "username",
            `${userData.firstName} ${userData.lastName}`
        );

        if (userData.userType === "Advisor") {
            dispatch(
                setAuthentication({
                    isAuthenticated: false,
                    customerId: null,
                    userType: userData.userType,
                    renderTenantSelectionPage: false
                })
            );
            return navigate("/");
        }
        if (userData.userType === "Admin") {
            return navigate("/admin");
        }
    }

    return { initUserReferenceCommand };
}