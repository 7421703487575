import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ValidationMessages, InputGroup, TextInput } from "../..";
import { i18n } from "../../i18n";
import { getValidationMessages } from "../../utilities/getValidationMessages";
import { hasErrors } from "../../utilities/hasErrors";
import { getEmailConfig } from "./formConfig";

interface EmailInputGroupProps {
  name: string;
  isSubmitted?: boolean;
  labelClassName?: string;
  errors: FieldErrors<{ email: string }>;
  register: UseFormRegister<any>;
  className?: string;
}

export function EmailInputGroup(props: EmailInputGroupProps) {
  const formConfig = getEmailConfig(props.register);

  return (
    <InputGroup
      isInvalid={hasErrors(props.name, props.errors)}
      isValid={props.isSubmitted && !hasErrors(props.name, props.errors)}
    >
      <label htmlFor={props.name} className={props.labelClassName}>
        {i18n.emailInputGroup.label}
      </label>
      <TextInput
        type="email"
        id={props.name}
        register={formConfig.email.field}
        className={props.className}
      />
      <ValidationMessages
        messages={getValidationMessages(
          props.name,
          props.errors,
          formConfig.email.errors
        ).filter((value, index, array) => array.indexOf(value) === index)}
      />
    </InputGroup>
  );
}
