import axios from "axios";
import { apiUrls } from "shared/apiUrls";

export async function deassignCustomerToAdvisorRequest(params: DeassignCustomerToAdvisorParams): Promise<void> {
    const response = await axios.put(apiUrls(params.customerId).customers.deassign, null, { params });
    return response.data;
}


export type DeassignCustomerToAdvisorParams = {
    customerId: string;
}