import { PensionNeedDetails } from 'models/customer-situation';
import { MoneyIcon } from 'modules/customer-file/assets';
import { PensionNeedDetailsValue } from 'modules/customer-file/models/Customer';
import { i18n } from '../i18n';
import styles from './PensionNeedCard.module.scss';
import { ModuleCard } from 'shared/ModuleCard/ModuleCard';
import { AdvancedComputationCategoryDetails } from '@ifs/libs/src/modules/pension-need/models/request/common';

const t = i18n.PensionNeedCard;

interface PensionNeedCardProps {
  monthlyAmount: number;
  pensionNeedDetails: AdvancedComputationCategoryDetails[];
}

interface PensionNeedKeys {
  label: string;
  value: number;
}

export function PensionNeedCard({ monthlyAmount, pensionNeedDetails }: PensionNeedCardProps) {
  let pensionNeedsValues : number[];
  let pensionNeedKeys : PensionNeedKeys[] = [];

  if (pensionNeedDetails) {
    pensionNeedsValues = pensionNeedDetails.map((e) => e.value);
    pensionNeedKeys = pensionNeedDetails.map((e, i) => {
      return {
        label: t.pensionNeedsLabel[e.categoryId as PensionNeedDetailsValue],
        value: pensionNeedsValues[i]
      };
    });
  }

  return (
    <ModuleCard
      moduleIcon={MoneyIcon}
      title={t.title}
      headingContent={
        <>
          <div className={styles.MediumPrimaryColor}>{i18n.monthlyAmount(monthlyAmount)} </div>
        </>
      }
    >
      <div className={styles.GridNeedsRetirement}>
        {pensionNeedKeys.map((e, i) => (
          <div className={styles.AmountTile} key={`${e}_${i}`}>
            <div>{e.label}</div>
            <div className={styles.ItemMonthly}>{i18n.monthlyAmount(e.value)}</div>
          </div>
        ))}
      </div>
    </ModuleCard>
  );
}