import { Scenario } from '@ifs/libs/src/modules/investor-profile/models/RiskProfileEnum';
import { ProfessionalStatus } from '@ifs/libs/src/modules/legal-pension/models/ProfessionalStatus';
import { MaritalStatus } from '@ifs/libs/src/modules/taxation/models/MaritalStatus';
import { ChosenScenario } from '@ifs/libs/src/shared/models/ChosenScenario';
import {
  Evolution,
  LifeProjectType,
  PensionNeedDetailsValue,
  ProductType,
  SalaryFrequency
} from 'modules/customer-file/models/Customer';
import { number } from 'Utils/Splitter';
import { ProductsType } from '../../PropositionCard/ProductsType';
import styles from './LegalPensionCard/LegalPensionCard.module.scss';
import { SalaryPeriodicity } from '@ifs/libs';
import classNames from 'classnames';

export const periodicityTrad = {
  [SalaryPeriodicity.Monthly]: 'mois',
  [SalaryPeriodicity.Yearly]: 'an'
};

export const i18n = {
  monthlyAmount: (amount: number) => `${number(amount)}€/mois`,
  LegalPensionCard: {
    title: 'Retraite légale',
    chosenScenario: {
      [ChosenScenario.LegalAge]: 'Âge légal',
      [ChosenScenario.FullRateAge]: 'Taux plein',
      [ChosenScenario.MaxAge]: 'Taux plein automatique'
    },
    naturalLangage: {
      careerProgression: (before: string, after: string) => {
        return (
          <span>
            Votre progression de revenu était <span className={styles.ColorOrange}> {before} </span>
            et sera
            <span className={styles.ColorOrange}> {after} </span>dans le futur.
          </span>
        );
      }
    },
    summary: {
      title: 'Récapitulatif',
      retirementAge: (year: number, month: number) => {
        return (
          <div className={classNames(styles.Border, styles.ResumeValue)}>
            <span>Âge sélectionné par le client :{' '}</span>
            <div className={styles.AgeValueSection}>
              <span className={styles.GreenBold}>{year} ans </span>
              {month !== 0 && (
                <span>
                  <span className={styles.GreenBold}>{month} mois </span>
                </span>
              )}
            </div>
          </div>
        );
      },
      lossPercentage: (lossPercentage: number) => {
        return (
          <div className={classNames(styles.Border, styles.ResumeValue)}>
            <span> Perte de salaire :{' '}</span>
            <div className={styles.GreenBold}>{Math.floor(lossPercentage * 100).toFixed(2)}%</div>
          </div>
        );
      },
      lastSalaryAmount: (lastSalaryAmount: number) => {
        return (
          <div className={styles.ResumeValue}>
            <span> Dernier revenu estimé :{' '}</span>
            <div className={styles.GreenBold}>{number(lastSalaryAmount)}€/mois </div>
          </div>
        );
      }
    },
    evolution: {
      [Evolution.Large]: 'forte',
      [Evolution.Medium]: 'moyenne',
      [Evolution.Small]: 'stable',
      [Evolution.Unknown]: 'inconnue'
    },
    maritalStatus: {
      [MaritalStatus.Single]: 'célibataire',
      [MaritalStatus.Married]: 'marié(e)',
      [MaritalStatus.CivilPartnership]: 'pacsé(e)',
      [MaritalStatus.Divorced]: 'divorcé(e)',
      [MaritalStatus.Widower]: 'veuf(ve)'
    },
    professionalStatus: {
      [ProfessionalStatus.Salarie]: 'la Caisse des salariés du privé',
      [ProfessionalStatus.Artisan]: 'la Caisse des artisans',
      [ProfessionalStatus.Commercant]: 'la Caisse des commerçants',
      [ProfessionalStatus.CIPAV]: 'la CIPAV',
      [ProfessionalStatus.Fonctionnaire]: 'la Caisse des fonctionnaires',
      [ProfessionalStatus.CARPIMKO]: 'la CARPIMKO',
      [ProfessionalStatus.MSANonSalarie]: 'la MSA',
      [ProfessionalStatus.Other]: 'une autre Caisse de retraite'
    },
    children: (numberOfChildren: number) => {
      if (numberOfChildren === 0) {
        return (
          <span>
            Vous n'avez <span className={styles.ColorOrange}>pas d'enfant</span>
          </span>
        );
      } else if (numberOfChildren === 1) {
        return (
          <span>
            Vous avez <span className={styles.ColorOrange}>{numberOfChildren} enfant</span>
          </span>
        );
      } else {
        return (
          <span>
            Vous avez <span className={styles.ColorOrange}>{numberOfChildren} enfants</span>
          </span>
        );
      }
    },
    salaryFrequency: {
      [SalaryFrequency.Yearly]: (amount: string) => `${amount}/an`,
      [SalaryFrequency.Monthly]: (amount: string) => `${amount}/mois`
    }
  },
  PensionNeedCard: {
    title: 'Besoin retraite',
    pensionNeedsLabel: {
      [PensionNeedDetailsValue.housing]: 'LOGEMENT',
      [PensionNeedDetailsValue.transport]: 'TRANSPORTS',
      [PensionNeedDetailsValue.supply]: 'ALIMENTATION',
      [PensionNeedDetailsValue.other]: 'LOISIRS',
      [PensionNeedDetailsValue.health]: 'AUTRES'
    }
  },
  PatrimonyCard: {
    title: 'Patrimoine',
    savingAccount: 'PATRIMOINE FINANCIER',
    realEstate: 'PATRIMOINE IMMOBILIER',
    mainResidence: 'Résidence Principale',
    productType: {
      [ProductType.CompteCourant]: 'Compte courant',
      [ProductType.LivretEpargne]: 'Livret d’épargne',
      [ProductType.PEL]: 'Plan d’Epargne Logement (PEL)',
      [ProductType.AssuranceVie]: 'Assurance vie',
      [ProductType.CompteTitres]: 'Comptes titres',
      [ProductType.Immobilier]: 'Immobilier',
      [ProductType.PEA]: 'PEA',
      [ProductType.PERIndividuel]: 'PER individuel',
      [ProductType.PERP]: 'PERP',
      [ProductType.Madelin]: 'Madelin',
      [ProductType.PEREntrepriseOrPERCO]: 'PER d’Entreprise Collectif / PERCO',
      [ProductType.PEROEntrepriseOrArticle83]: 'PERO d’Entreprise obligatoire / Article 83',
      [ProductType.PEE]: 'PEE'
    }
  },
  LifeProjectCard: {
    title: 'Projets de vie',
    lifeProject: {
      [LifeProjectType.realEstateAssets]: 'IMMOBILIER',
      [LifeProjectType.pleasurePurchases]: 'ACHATS PLAISIRS',
      [LifeProjectType.family]: 'FAMILLE',
      [LifeProjectType.anticipation]: 'ANTICIPATION',
      [LifeProjectType.philanthropy]: 'PHILANTHROPIE',
      [LifeProjectType.other]: 'AUTRES PROJETS'
    }
  },
  TaxationCard: {
    title: 'Fiscalité',
    max: 'Maximum déductible',
    tmi: 'TMI',
    maxTaxSavings: "Économie d'impôt max",
    maritalStatus: {
      [MaritalStatus.Single]: 'célibataire',
      [MaritalStatus.Married]: 'marié(e)',
      [MaritalStatus.CivilPartnership]: 'pacsé(e)',
      [MaritalStatus.Divorced]: 'divorcé(e)',
      [MaritalStatus.Widower]: 'veuf(ve)'
    },
    children: (numberOfChildren: number) => {
      if (numberOfChildren === 0) {
        return (
          <span>
            vous n'avez <span className={styles.ColorOrange}>pas d'enfant à charge</span>.
          </span>
        );
      } else if (numberOfChildren === 1) {
        return (
          <span>
            vous avez <span className={styles.ColorOrange}>{numberOfChildren} enfant à charge</span>
            .
          </span>
        );
      } else {
        return (
          <span>
            vous avez{' '}
            <span className={styles.ColorOrange}>{numberOfChildren} enfants à charge</span>.
          </span>
        );
      }
    }
  },

  RiskProfileCard: {
    title: 'Profil investisseur',
    scenario: {
      [Scenario.Conservateur]: 'Conservateur',
      [Scenario.Prudent]: 'Prudent',
      [Scenario.Equilibre]: 'Équilibré',
      [Scenario.Dynamique]: 'Dynamique',
      [Scenario.Offensif]: 'Offensif'
    },
    productType: {
      [ProductsType.CompteCourant]: {
        productName: 'Compte Courant',
        productSubTitle: 'CompteCourantSubTitle',
        restricted: ''
      },
      [ProductsType.LivretEpargne]: {
        productName: 'Livret Epargne',
        productSubTitle: 'LivretEpargneSubTitle',
        restricted: ''
      },
      [ProductsType.PEL]: {
        productName: 'PEL',
        productSubTitle: 'PELSubTitle',
        restricted: ''
      },
      [ProductsType.AssuranceVie]: {
        productName: 'Assurance vie',
        productSubTitle: 'Disponibilité du capital',
        restricted: 'Assurance vie'
      },
      [ProductsType.CompteTitres]: {
        productName: 'CompteTitres',
        productSubTitle: 'CompteTitresSubTitle',
        restricted: ''
      },
      [ProductsType.PEA]: {
        productName: "Plan d'Epargne en Actions (PEA)",
        productSubTitle: 'Diversification',
        restricted: 'PEA'
      },
      [ProductsType.PERIndividuel]: {
        productName: "Plan d'Epargne Retraite (PER)",
        productSubTitle: 'Défiscalisation',
        restricted: 'PER'
      },
      [ProductsType.PERP]: {
        productName: 'PERP',
        productSubTitle: 'PERPSubTitle',
        restricted: ''
      },
      [ProductsType.Madelin]: {
        productName: 'Madelin',
        productSubTitle: 'MadelinSubTitle',
        restricted: ''
      },
      [ProductsType.PEREntrepriseOrPERCO]: {
        productName: 'PEREntrepriseOrPERCO',
        productSubTitle: 'PEREntrepriseOrPERCOSubTitle',
        restricted: ''
      },
      [ProductsType.PEROEntrepriseOrArticle83]: {
        productName: 'PEROEntrepriseOrArticle83',
        productSubTitle: 'PEROEntrepriseOrArticle83SubTitle',
        restricted: ''
      },
      [ProductsType.PEE]: {
        productName: 'PEE',
        productSubTitle: 'PEESubTitle',
        restricted: ''
      },
      [ProductsType.Immobilier]: {
        productName: 'Société Civile de Placement Immobilier (SCPI)',
        productSubTitle: 'Immobilier',
        restricted: 'Immobilier'
      }
    }
  }
};
