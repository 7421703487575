import { AxiosOutcome } from "shared/enums/axiosOutcome.enum";
import { useLoader } from "@ifs/libs";
import { RequestResetPasswordBodyModel } from "../models/auth.model";
import { requestResetPasswordRequest } from "../requests/requestResetPasswordRequest";

export function useRequestResetPasswordCommand() {
    const { showLoaderWhile } = useLoader();

    async function requestResetPasswordCommand(body: RequestResetPasswordBodyModel): Promise<AxiosOutcome> {
        return await showLoaderWhile(async () => {
            return await requestResetPasswordRequest(body);
        }, {key: "requestResetPasswordCommand", value:"API POST - /api/auth/request_reset_password"})
    }

    return { requestResetPasswordCommand };
}
