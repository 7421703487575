import { EUserStatus } from "@ifs/libs/src/shared";
import { AdvisorResponseModel } from "shared/request/getAdvisorRequest";

export enum ProductType {
  CompteCourant = "CompteCourant",
  LivretEpargne = "LivretEpargne",
  PEL = "PEL",
  AssuranceVie = "AssuranceVie",
  CompteTitres = "CompteTitres",
  PEA = "PEA",
  PERIndividuel = "PERIndividuel",
  PERP = "PERP",
  Madelin = "Madelin",
  PEREntrepriseOrPERCO = "PEREntrepriseOrPERCO",
  PEROEntrepriseOrArticle83 = "PEROEntrepriseOrArticle83",
  Immobilier = "Immobilier",
  PEE = "PEE",
}

export enum PensionNeedDetailsValue {
  housing = "Housing",
  transport = "Transport",
  supply = "Supply",
  other = "Other",
  health = "Health",
}

export enum SalaryFrequency {
  Yearly = "Yearly",
  Monthly = "Monthly",
}

export enum Evolution {
  Unknown = "Unknown",
  Large = "Large",
  Medium = "Medium",
  Small = "Small",
}

export enum LifeProjectType {
  realEstateAssets = "realEstateAssets",
  pleasurePurchases = "pleasurePurchases",
  family = "family",
  anticipation = "anticipation",
  philanthropy = "philanthropy",
  other = "other",
}

export type CustomerIdentifierParams = {
  customerId: string;
}

export type RemarkIdentifierParams = {
  remarkIdentifier: string;
}

export type AppointmentIdentifierParams = {
  appointmentReportIdentifier: string;
}

export type CustomerInformationResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  birthDate: string;
  isBankClient: boolean;
  location: CustomerLocationModel | null;
  canReceiveInformativeContent: boolean;
  canReceiveCommercialContent: boolean;
  assignedAdvisor: AdvisorResponseModel | null;
  status: EUserStatus;
}

type CustomerLocationModel = {
  postalCode: string;
  locality?: string;
}
