import axios, { AxiosError } from 'axios';
import { LoginParamsModel } from '../models/auth.model';

export enum LoginOutcome {
  Success = "Success",
  IncorrectCredentials = "IncorrectCredentials",
  UserLockedOut = "UserLockedOut"
}

export async function loginRequest(model: LoginParamsModel): Promise<LoginOutcome> {
  try {
    let body = new URLSearchParams();
    body.set('username', model.email);
    body.set('password', model.password);
    await axios.post(`/api/auth/login`, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    return LoginOutcome.Success;
  } catch (err) {
    const error = err as AxiosError;
    const body = error.response?.data as any;
    if (
      error.response?.status === 400 &&
      body.error === 'invalid_grant' &&
      body.error_description === 'The user name is locked out.'
    ) {
      return LoginOutcome.UserLockedOut;
    }

    if (error.response?.status === 400 && body.error === 'invalid_grant') {
      return LoginOutcome.IncorrectCredentials;
    }

    throw err;
  }
}
