import { useLoader } from "@ifs/libs";
import { AxiosOutcome } from "shared/enums/axiosOutcome.enum";
import { AccountEnableModel, accountEnableRequest } from "../requests/accountEnableRequest";

export function useAccountEnableCommand(){
    const { showLoaderWhile } = useLoader();

    async function accountEnableCommand(model: AccountEnableModel): Promise<AxiosOutcome> {
        return await showLoaderWhile(async () => {
          return  await accountEnableRequest(model);
        }, {key: "accountEnableCommand", value:"API GET - /api/auth/accounts/enable"})
    }

    return { accountEnableCommand };
}