import { number } from '@ifs/libs/src/shared/utilities/intl';
import { globali18n } from 'i18n';
import { LifeProjectsModel } from 'models/customer-situation';
import { FamilyIcon } from 'modules/customer-file/assets';
import { LifeProjectType } from 'modules/customer-file/models/Customer';
import { i18n } from '../i18n';
import styles from './LifeProjectCard.module.scss';
import { ModuleCard } from 'shared/ModuleCard/ModuleCard';

const t = i18n.LifeProjectCard;

interface LifeProjectCardProps {
  lifeProject: LifeProjectsModel;
  totalAmount?: number;
}

export function LifeProjectCard(props: LifeProjectCardProps) {

  const lifeProjectValues = Object.values(props.lifeProject).map((e) => e);
  const lifeProjectKeys = Object.keys(props.lifeProject).map((e, i) => {
    return {
      label: t.lifeProject[e as LifeProjectType],
      value: lifeProjectValues[i]
    };
  });

  const finalLifeProject = lifeProjectKeys.filter((e) => e.label !== undefined);

  return (
    <div className={styles.NeedsCard}>
      <ModuleCard
        moduleIcon={FamilyIcon}
        title={t.title}
        headingContent={
          <>
            <div className={styles.MediumPrimaryColor}>
              {i18n.monthlyAmount(props.totalAmount || 0)}
            </div>
          </>
        }
      >
        <div className={styles.GridNeedsLifeProject}>
          {finalLifeProject.map((e, i) => (
            <div className={styles.AmountTile} key={`${e}_${i}`}>
              <div>{e.label}</div>
              <div className={styles.ItemMonthly}>
                {number(e.value)}
                {globali18n.labels.euros}
              </div>
            </div>
          ))}
        </div>
      </ModuleCard>
    </div>
  );
}
