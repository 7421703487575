import axios, { AxiosResponse } from "axios";

export async function initializeAccountRequest(model: InitializeAccountModel): Promise<AxiosResponse> {
    return await axios.post(`/api/auth/initialize_account`, model);
}

export type InitializeAccountModel = {
    firstName: string;
    lastName: string;
    email: string;
    isBankClient?: number;
}

export enum InitializeAccountOutcome {
    Success,
    Error,
    EmailError
}