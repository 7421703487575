import { useState } from "react";

export default () => {
  const defaultModalTitle = "Confirmation";
  let [isDisplay, setIsDisplay] = useState(false);
  let [modalContent, setModalContent] = useState("I'm the Modal Content");
  let [modalTitle, setModalTitle] = useState(defaultModalTitle);

  let handleModal = (content: any, title?:string) => {
    setIsDisplay(true);
    if (content === "close") {
      setIsDisplay(false);
    }
    if (content) {
      setModalContent(content);
    }
    setModalTitle(title || defaultModalTitle);
  };

  return { isDisplay, handleModal, modalContent, modalTitle };
};
