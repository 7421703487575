import { useState, useEffect } from 'react';

import { MultiSelect, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import styles from './Filter.module.scss';
import buttonStyles from 'styles/ButtonStyle.module.scss';

import moment from 'moment';
import { FilterInterface } from 'models/filter';
import { CustomerStatus } from 'models/customer-status';
import { Input } from 'shared';
import { globali18n } from 'i18n';
import { i18n } from 'modules/appointments/i18n';
import classNames from 'classnames';
import { useAnalytics } from 'shared/Analytics/useAnalytics';
import { Button } from '@ifs/libs';
import { fetchAdvisors } from './fetchAdvisors';
import { TextInput } from '@ifs/libs/src/shared';
import { handleChangeList } from 'shared/IsCustom';
import { AdvisorResponseModel } from 'shared/request/getAdvisorRequest';

const defaultValues = {
  CustomerFirstName: '',
  CustomerLastName: '',
  StartDesiredDate: '',
  EndDesiredDate: '',
  AdvisorAssignmentFilterType: '',
  StatusList: '',
  SelectedStatut: [],
  SelectedAdvisor: [],
  isReset: false
};

export function Filter(props: {
  listOfAdvisors: () => Promise<AdvisorResponseModel[]>;
  unAssignedAppointment: () => void;
  applyFilter: (arg0: FilterInterface) => void;
  getUserAppointment: () => void;
}) {
  const { simpleEvent, filterEvent } = useAnalytics();
  const [selectedStatut, setSelectedStatut] = useState([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState([]);
  const [advisorsList, setAdvisorsList] = useState([] as MultiSelectValueType[]);
  const [advisorsListFiltered, setAdvisorsListFiltered] = useState([] as MultiSelectValueType[]);
  const [statusListFiltered, setStatusListFiltered] = useState([] as MultiSelectValueType[]);
  const [form, setForm] = useState(defaultValues);
  const optionStatut = [
    { label: globali18n.status[CustomerStatus.Requested], value: CustomerStatus.Requested },
    {
      label: globali18n.status[CustomerStatus.PendingConfirmation],
      value: CustomerStatus.PendingConfirmation
    },
    { label: globali18n.status[CustomerStatus.Confirmed], value: CustomerStatus.Confirmed },
    { label: globali18n.status[CustomerStatus.Honored], value: CustomerStatus.Honored },
    { label: globali18n.status[CustomerStatus.Canceled], value: CustomerStatus.Canceled }
  ];

  useEffect(() => {
    setStatusListFiltered(optionStatut);
  }, []);

  const handleChange = (event: { target: { value: string; name: string } }) => {
    let { value, name } = event.target;

    if (name === 'StartDesiredDate' || name === 'EndDesiredDate') {
      if (moment(value).year().toString().length <= 4) {
        return setForm((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    }
    return setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleResetForm = () => {
    simpleEvent('reset_filters');
    setForm({
      ...defaultValues,
      isReset: true
    });
    setSelectedStatut([]);
    setSelectedAdvisor([]);
    form['isReset'] = true;
    preApplyFilter();
    setForm(defaultValues);
  };

  const preApplyFilter = () => {
    form['SelectedAdvisor'] = selectedAdvisor;
    form['SelectedStatut'] = selectedStatut;
    props.applyFilter(form);
  };

  useEffect(() => {
    const advisor = props.listOfAdvisors();
    fetchAdvisors(advisor, setAdvisorsList, setAdvisorsListFiltered);
  }, []);

  const filterChange = (
    event: MultiSelectFilterChangeEvent,
    filterDataType: 'advisor' | 'status'
  ) => {
    const filterValue = event.filter.value;
    const dataToFilter = (filterDataType === 'advisor' ? advisorsList : optionStatut).filter(
      ({ label, value }) =>
        value === 'none' || label.toLowerCase().startsWith(filterValue.toLowerCase())
    );
    filterDataType === 'advisor'
      ? setAdvisorsListFiltered(dataToFilter)
      : setStatusListFiltered(dataToFilter);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.PageTitle}>
          <span className={styles.Title}>{globali18n.labels.advisorArea}</span>
          <span className={styles.SubTitle}>{i18n.filterComponent.labels.customerMeeting}</span>
        </div>
        <div className={styles.ExtendFilter}>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.GhostedButton)}
            onClick={handleResetForm}
          >
            {i18n.filterComponent.labels.clearFilters}
          </Button>
          <Button
            onClick={() => {
              simpleEvent('my_meetings');
              props.getUserAppointment();
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
          >
            {i18n.filterComponent.labels.meeting}
          </Button>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonPrimary)}
            onClick={() => {
              simpleEvent('non_assigned_meetings');
              props.unAssignedAppointment();
            }}
          >
            {i18n.filterComponent.labels.meetingUnassigned}
          </Button>
        </div>
      </div>
      <section className={styles.Body}>
        <div className={styles.FilterInputs}>
          <div className={styles.InnerContainer}>
            <div className={styles.InnerSplit}>
              <div>
                <label className={styles.Label}>{globali18n.labels.firstName}</label>
                <TextInput
                  onChange={handleChange}
                  value={form.CustomerFirstName}
                  name="CustomerFirstName"
                  className={styles.BaseInput}
                />
              </div>
              <div>
                <label className={styles.Label}>{globali18n.labels.lastName}</label>
                <TextInput
                  onChange={handleChange}
                  value={form.CustomerLastName}
                  name={'CustomerLastName'}
                  className={styles.BaseInput}
                />
              </div>
            </div>
            <div className={styles.MultiSelectBloc}>
              <span className={styles.SelectAdvisorTitle}>{globali18n.labels.statut}</span>
              <MultiSelect
                className={styles.MultiSelectStatut}
                data={statusListFiltered}
                value={selectedStatut}
                onChange={(e) => handleChangeList(e, setSelectedStatut)}
                textField="label"
                dataItemKey="value"
                placeholder={i18n.filterComponent.placeholder.all}
                filterable={true}
                onFilterChange={(event) => filterChange(event, 'status')}
              />
            </div>
          </div>
          <div className={styles.InnerContainer}>
            <div className={styles.InnerSplit}>
              <Input
                title={i18n.filterComponent.labels.startMeetingDate}
                onChange={(e) => handleChange(e)}
                value={form.StartDesiredDate}
                type="date"
                placeholder={i18n.filterComponent.placeholder.start}
                name={'StartDesiredDate'}
                customInputStyle={{ width: '250px' }}
              />
              <Input
                title={i18n.filterComponent.labels.endMeetingDate}
                onChange={(e) => handleChange(e)}
                value={form.EndDesiredDate}
                type="date"
                placeholder={i18n.filterComponent.placeholder.end}
                name={'EndDesiredDate'}
                customInputStyle={{ width: '250px' }}
              />
            </div>
            <div className={styles.MultiSelectBloc}>
              <span className={styles.SelectAdvisorTitle}>{globali18n.labels.advisor}</span>
              <MultiSelect
                className={styles.MultiSelectStatut}
                data={advisorsListFiltered}
                value={selectedAdvisor}
                onChange={(e) => handleChangeList(e, setSelectedAdvisor)}
                textField="label"
                dataItemKey="value"
                placeholder={i18n.filterComponent.placeholder.all}
                filterable={true}
                onFilterChange={(event) => filterChange(event, 'advisor')}
              />
            </div>
          </div>
        </div>
        <div className={styles.ButtonBloc}>
          <Button
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
            onClick={() => {
              preApplyFilter();
              const listOfStatus: string[] = [];
              form.SelectedStatut.map((e: { label: string }) => {
                listOfStatus.push(e.label);
              });

              filterEvent({
                status: listOfStatus.length > 0 ? listOfStatus.toString() : '',
                beg_meeting_date: form.StartDesiredDate,
                end_meeting_date: form.EndDesiredDate
              });
            }}
          >
            {globali18n.labels.search}
          </Button>
        </div>
      </section>
    </div>
  );
}

type MultiSelectValueType = {
  value: any;
  label: string;
};
