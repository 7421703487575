import { TenantLoader } from '@ifs/libs/src/TenantLoader';
import { TenantLoader as NonSharedModuleTenantLoader } from 'TenantLoader';
import { Tenant, TenantModelResponse } from '@ifs/libs/src/shared/models/TenantModel';
import { loadTenantRequest } from 'shared/request/loadTenant.request';
import { useDispatch } from 'react-redux';
import { setModuleSettings, setTenantState } from '@ifs/libs/src/shared/slices/tenantSlice';
import { setRenderTenantSelectionPage } from 'shared/authenticationSlice';

export function useLoadTenant() {
  const dispatch = useDispatch();

  async function loadTenantCommand(): Promise<TenantModelResponse | null> {
    const response = await loadTenantRequest();
    if (response && (response.status === 204 || response.status === 200)) {
      const data = response.data as TenantModelResponse;
      const identifier = data.TenantIdentifier;
      
      NonSharedModuleTenantLoader(identifier);
      TenantLoader(identifier);       
      dispatch(setTenantState(response.data));
      dispatch(setRenderTenantSelectionPage(false));      

      return data;
    }
    if(response === "NoTenantNeedTenantSelection") {
      // Specific case, user must be redirect to the tenant selection page to select a tenant
      dispatch(setRenderTenantSelectionPage(true));
    }
    return null;
  }

  return { loadTenantCommand };
}
