import { BankIcon } from 'modules/customer-file/assets';
import { number } from 'Utils/Splitter';
import { i18n } from '../i18n';
import styles from './TaxationCard.module.scss';
import { ModuleCard } from 'shared/ModuleCard/ModuleCard';
import { TaxationOutputModel } from '@ifs/libs/src/modules/taxation/models/TaxationOutputModel';

const t = i18n.TaxationCard;

interface TaxationCardProps {
  taxation: TaxationOutputModel;
}

export function TaxationCard(props: TaxationCardProps) {
  return (
    <ModuleCard
      title={t.title}
      moduleIcon={BankIcon}
      hideMinusButton={true}
      headingContainerCustomClass={styles.HeadingContainerCustom}
      headingContent={
        <div className={styles.TaxationHeadingContainer}>
          <div className={styles.TaxationHeadingInformation}>
            <span>{t.max} :{' '}</span>
            <span className={styles.MediumPrimaryColor}>
              {props.taxation.householdMaximumTaxDeduction} €
            </span>
          </div>
          <div className={styles.TaxationHeadingInformation}>
            <span>{t.tmi} :{' '}</span>
            <span className={styles.MediumPrimaryColor}>{props.taxation.selectedTmi * 100}%</span>
          </div>
          <div className={styles.TaxationHeadingInformation}>
            <span>{t.maxTaxSavings} :{' '}</span>
            <span className={styles.MediumPrimaryColor}>{props.taxation.maximumTaxSaving} €</span>
          </div>
        </div>
      }
      headingCustomClass={styles.HeadingCustom}
    ></ModuleCard>
  );
}
