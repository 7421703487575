import { TaxationOutputModel } from "@ifs/libs/src/modules/taxation/models/TaxationOutputModel";
import { apiUrls } from "@ifs/libs/src/shared/apiUrls";
import { axiosRequestValidateStatusConfig } from "@ifs/libs/src/shared/axiosValidateStatusConfig";
import axios from "axios";

export async function getCustomerTaxationRequest(params: string): Promise<TaxationOutputModel | null> {

    const response = await axios.get(apiUrls(params).taxation.get, {...axiosRequestValidateStatusConfig});

    if (response.status === 204, response.status === 404) {
        return null;
    }
    return response.data;
}
