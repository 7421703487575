import { RiskProfile } from "@ifs/libs/src/modules/recommendation/models/risk-profile.enum";

export const i18n = {
  title: 'Proposition',
  labels: {
    retirementGoal: "Atteinte de l'objectif retraite",
    additionalIncome: 'Complément de revenus',
    retirementCapital: 'Capital pour la retraite',
    taxSavings: `Economie d’impôt estimée`,
    firstMonth: 'Le 1er mois',
    nextMonths: 'Les mois suivants',
    monthlyPayments: 'Mensualités',
    firstPayement: '1er versement',
    preferences: 'Préférences',
    reduceMyTaxes: 'Réduire mes impôts dès aujourd’hui',
    recoverMyCapital: 'Pouvoir récupérer mon capital',
    transmitHeritage: 'Transmettre mon patrimoine',
    riskPreference: 'Préférence de risque',
    retirementAge: 'Âge de départ à la retraite'
  },
  minimalAmount: 'Minimum non atteint',
  noAccess: 'Cette enveloppe n’est pas adaptée à votre projet.',
  riskProfile: {
    [RiskProfile.Conservateur]: 'Conservateur',
    [RiskProfile.Prudent]: 'Prudent',
    [RiskProfile.Equilibre]: 'Equilibré',
    [RiskProfile.Dynamique]: 'Dynamique',
    [RiskProfile.Offensif]: 'Offensif'
  }
};
