import classNames from 'classnames';
import { ModalContext } from 'context/modalContext';
import { i18n } from 'modules/advisor-course/i18n';
import { AdvisorButton } from 'modules/appointments/pages/CustomerAppointmentsPage/Component/AdvisorButton/AdvisorButton';
import { AssignCustomerModal } from 'modules/appointments/pages/CustomerAppointmentsPage/Component/AppointmentTile/components/AssignCustomerModal';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared';

import styles from './CustomerTile.module.scss';
import buttonStyles from 'styles/ButtonStyle.module.scss';
import { CustomerInformationResponse } from 'modules/customer-file/models/Customer';

interface CustomerTileProps {
  customer: CustomerInformationResponse;
  assignCustomer: () => void;
  unAssignCustomer: () => void;
}

export default function CustomerTile(props: CustomerTileProps) {
  let { handleModal } = useContext(ModalContext);
  let navigate = useNavigate();

  const handleDisplayModal = (type: string) => {
    const preTitle = i18n;
    const title = type === 'unassign' ? preTitle.titleUnassign : preTitle.titleAssign;
    const event = type === 'unassign' ? 'unassigned' : 'assigned';
    const action =
      type === 'unassign'
        ? props.unAssignCustomer
        : props.assignCustomer;
    const data = {
      title: title,
      lastName: props.customer.firstName!,
      name: props.customer.lastName!,
      event: event,
      customerId: props.customer.id!,
      action: action,
      advisor: `${props.customer.assignedAdvisor?.firstName?.charAt(0)}.${props.customer.assignedAdvisor?.lastName}`,
      handleModal: handleModal
    };
    return handleModal(<AssignCustomerModal {...data} />);
  };

  function goToCustomerFile(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    navigate(`/customer-file?customerid=${props.customer.id}`);
  }

  return (
    <div className={styles.Tile} onClick={goToCustomerFile}>
      <div className={styles.Greyout}>{props.customer?.firstName}</div>
      <div className={styles.Greyout}>{props.customer?.lastName}</div>
      <div>{props.customer?.email}</div>
      {props.customer && props.customer.assignedAdvisor ? (
        <div>
          <AdvisorButton
            advisorLastName={props.customer.assignedAdvisor!.lastName}
            advisorFirstName={props.customer.assignedAdvisor!.firstName}
            unAssignCustomer={() => handleDisplayModal('unassign')}
          />
        </div>
      ) : (
        <div>
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              handleDisplayModal('assign');
            }}
            className={classNames(buttonStyles.BaseButton, buttonStyles.FilledButtonSecondary)}
          >
            {i18n.attributeCustomer}
          </Button>
        </div>
      )}
    </div>
  );
}
