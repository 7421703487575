import { AdvisorActionAssignement, AdvisorActionUnassignement, AdvisorCustomerHistory } from 'modules/customer-file/models/advisorCustomerAction';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { EAdvisorCustomerActiontype } from '../../AdvisorCustomerActionType.enum';
import { CustomerCRComponent } from '../CustomerCRComponent';
import { CustomerNoteComponent } from '../CustomerNoteComponent';
import EventBloc from '../EventBloc/EventBloc';
import { useGetAdvisorNameCommand } from 'shared/commands/getAdvisorNameCommand';

interface EventHistoriesProps {
  listOfEvents: AdvisorCustomerHistory[];
  addRemark: boolean;
  addCrDisplayed: boolean;
  cancelAddCR: () => void;
  reloadEvents: () => void;
  cancelAddNote: () => void;
  reloadCustomerInformations: () => void;
}

export function EventHistories(props: EventHistoriesProps) {
  const [displayedEvents, setDisplayedEvents] = useState<Array<any>>([]);
  const { getAdvisorName } = useGetAdvisorNameCommand();

  useEffect(() => {
    generateEventVue();
  }, [props]);

  const generateEventVue = () => {
    setDisplayedEvents([]);
    const eventsList: JSX.Element[] = [];

    props.listOfEvents.map((event: AdvisorCustomerHistory, index: number) => {
      const advisorName = {
        [EAdvisorCustomerActiontype.Assignment]: `${(event as AdvisorActionAssignement).assignedAdvisor?.firstName} ${(event as AdvisorActionAssignement).assignedAdvisor?.lastName}`,
        [EAdvisorCustomerActiontype.Unassignment]: `${(event as AdvisorActionUnassignement).unassignedAdvisor?.firstName} ${(event as AdvisorActionUnassignement).unassignedAdvisor?.lastName}`,
        [EAdvisorCustomerActiontype.LaunchJourney]: getAdvisorName(event.advisor),
        [EAdvisorCustomerActiontype.Remark]: getAdvisorName(event.advisor),
        [EAdvisorCustomerActiontype.AppointmentReport]: getAdvisorName(event.advisor)
      };
      if (event.$type === EAdvisorCustomerActiontype.Remark) {
        eventsList.push(
          <CustomerNoteComponent
            key={`${event.id}_${event.$type}_CustomerNote_${index}`}
            addRemark={null}
            creationDate={`${moment(event.createdAt).format('DD/MM/YYYY')} ${moment(
              event.createdAt
            )
              .format('HH:mm')
              .replace(':', 'H')}`}
            advisorName={advisorName[event.$type]}
            date={event.createdAt}
            remark={event.text}
            identifier={event.id}
            advisorIdentifier={event.advisor?.id}
            onValidate={() => {
              props.reloadEvents();
            }}
          />
        );
      }

      if (event.$type === EAdvisorCustomerActiontype.AppointmentReport) {
        eventsList.push(
          <CustomerCRComponent
            key={`${event.id}_${event.$type}_CustomerCR_${index}`}
            crData={event}
            cancelAddCR={props.cancelAddCR}
            onValidate={() => {
              props.reloadEvents();
              props.reloadCustomerInformations();
            }}
          />
        );
      }

      return eventsList.push(
        <EventBloc
          key={`${event.id}_${event.$type}_EVENT_${index}`}
          event={event.$type}
          advisorName={advisorName[event.$type]}
          eventDate={event.createdAt}
        />
      );
    });

    setDisplayedEvents(eventsList);
  };

  return (
    <div>
      {props.addRemark && (
        <CustomerNoteComponent
          addRemark={props.addRemark}
          advisorName={'null'}
          date={new Date()}
          remark={'null'}
          advisorIdentifier={'null'}
          onValidate={() => {
            return props.reloadEvents();
          }}
          creationDate={'null'}
          cancelAddNote={() => {
            return props.cancelAddNote();
          }}
        />
      )}

      {props.addCrDisplayed && (
        <CustomerCRComponent
          onValidate={() => {
            props.reloadCustomerInformations();
            return props.reloadEvents();
          }}
          cancelAddCR={() => {
            props.cancelAddCR();
          }}
        />
      )}
      {displayedEvents}
    </div>
  );
}
