
import { useLoader } from "@ifs/libs";
import { apiUrls } from "shared/apiUrls";
import { SearchCustomerParams, SearchCustomerResponse } from "../models/searchCustomer.model";
import { searchCustomerRequest } from "../requests/searchCustomerRequest";

export function useSearchCustomerCommand() {
    const { showLoaderWhile } = useLoader();

    async function searchCustomerCommand(params: SearchCustomerParams): Promise<SearchCustomerResponse> {
        return await showLoaderWhile(async () => {
            return await searchCustomerRequest(params);
        }, {key: "searchCustomerCommand", value:`API GET - ${apiUrls().customers.search}`})
    }

    return { searchCustomerCommand };
}

