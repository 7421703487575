import { RecommendationOutputModel } from '@ifs/libs/src/modules/recommendation/models/RecommendationModel';
import { apiUrls } from '@ifs/libs/src/shared/apiUrls';
import { ChosenScenario } from '@ifs/libs/src/shared/models/ChosenScenario';
import axios from 'axios';

export async function getCustomerRecommendationRequest(
  params: string,
  chosenScenario: ChosenScenario
): Promise<RecommendationOutputModel> {
  const recommendation = await axios.post(apiUrls(params).recommendation.compute, {
    overrideGamificationParameters: false,
    initialInvestmentAmount: null,
    recurringInvestmentAmount: null,
    retirementScenario: chosenScenario
  });
  return recommendation.data;
}
