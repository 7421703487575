import { LegalPensionModel, LifeProjectsModel } from 'models/customer-situation';
import { useLoader } from '@ifs/libs';
import { CustomerIdentifierParams, CustomerInformationResponse } from '../models/Customer';
import { getCustomerAppointmentRequest } from '../requests/getCustomerAppointmentRequest';
import { getCustomerInformationtRequest } from '../requests/getCustomerInformationRequest';
import { apiUrls } from 'shared/apiUrls';
import { getCustomerTaxationRequest } from '../requests/getCustomerTaxationRequest';
import { getCustomerPensionNeedsRequest } from '../requests/getCustomerPensionNeedsRequest';
import { getCustomerLegalPensionRequest } from '../requests/getCustomerLegalPensionRequest';
import { getCustomerPatrimonyRequest } from '../requests/getCustomerPatrimonyRequest';
import { getCustomerLifeMonthly, getCustomerLifeProjectsRequest } from '../requests/getCustomerLifeProjectsRequest';
import { RecommendationOutputModel } from '@ifs/libs/src/modules/recommendation/models/RecommendationModel';
import { apiUrls as libsSharedApiUrls } from '@ifs/libs/src/shared/apiUrls';
import { getCustomerRecommendationRequest } from '../requests/getCustomerRecommendationRequest';
import { getSearchFinancialProfileRequest } from '../requests/getSearchFinancialProfileRequest';
import { getSearchRiskProfilesRequest, SearchRiskProfiles } from '../requests/getSearchRiskProfiles';
import { GetFinancialProfiles } from '@ifs/libs/src/modules/investor-profile/models/FinancialProfileModel';
import { ChosenScenario } from '@ifs/libs/src/shared/models/ChosenScenario';
import { ValidatedPensionNeedModel } from '@ifs/libs/src/modules/pension-need/models/request/validationRequest.model';
import { PatrimonyModel } from '@ifs/libs/src/modules/patrimony/models/PatrimonyModel';
import { AppointmentResult } from 'modules/appointments/models';
import { TaxationOutputModel } from '@ifs/libs/src/modules/taxation/models/TaxationOutputModel';

export function useGetCustomerInfoCommand() {
  const { showLoaderWhile } = useLoader();

  async function getCustomerAppointmentCommand(
    params: CustomerIdentifierParams
  ): Promise<AppointmentResult[]> {
    return await showLoaderWhile(
      async () => {
        return await getCustomerAppointmentRequest(params);
      },
      {
        key: 'getCustomerAppointmentCommand',
        value: `API GET - ${apiUrls().appointments.get}`
      }
    );
  }

  async function getCustomerInformationCommand(
    params: CustomerIdentifierParams
  ): Promise<CustomerInformationResponse | null> {
    return await showLoaderWhile(
      async () => {
        return await getCustomerInformationtRequest(params);
      },
      {
        key: 'getCustomerInformationCommand',
        value: `API GET - ${apiUrls(params.customerId).customers.getById}`
      }
    );
  }

  async function getCustomerTaxationCommand(params: string): Promise<TaxationOutputModel | null> {
    return await showLoaderWhile(
      async () => {
        return await getCustomerTaxationRequest(params)
      },
      {
        key: 'getCustomerTaxationCommand',
        value: `API GET - ${libsSharedApiUrls(params).taxation.get}`
      }
    );
  }

  async function getCustomerPensionNeedsCommand(params: string): Promise<ValidatedPensionNeedModel | null> {
    return await showLoaderWhile(
        async () => {
          return await getCustomerPensionNeedsRequest(params)
        },
        {
          key: 'getCustomerPensionNeedsCommand',
          value: `API GET - ${libsSharedApiUrls(params).pensionNeed.validations}`
        }
      );
  };

  async function getCustomerLegalPensionCommand(params: string): Promise<LegalPensionModel | null> {
    return await showLoaderWhile(
        async () => {
          return await getCustomerLegalPensionRequest(params)
        },
        {
          key: 'getCustomerLegalPensionCommand',
          value: `API GET - ${libsSharedApiUrls(params).legalPension.validations}`
        }
      );
  };

  async function getCustomerPatrimonyCommand(params: string): Promise<PatrimonyModel | null> {
    return await showLoaderWhile(
        async () => {
          return await getCustomerPatrimonyRequest(params)
        },
        {
          key: 'getCustomerPatrimonyCommand',
          value: `API GET - Real Estates & Savings Accounts datas`
        }
      );
  };

  
  async function getCustomerLifeProjectsCommand(params: string): Promise<LifeProjectsModel | null> {
    return await showLoaderWhile(
        async () => {
          return await getCustomerLifeProjectsRequest(params)
        },
        {
          key: 'getCustomerLifeProjectsCommand',
          value: `API GET - ${libsSharedApiUrls(params).lifeProjects.get}`
        }
      );
  };

  async function getLifeProjectTotalCommand(params: string, sum: number): Promise<{monthlyAnnuityAmount: number}> {
    return await showLoaderWhile(
      async () => {
        return await getCustomerLifeMonthly(params, sum)
      },
      {
        key: 'getLifeProjectTotalCommand',
        value: `API GET - ${libsSharedApiUrls(params).lifeProjects.get}`
      }
    );
  }

  
  
  async function getCustomerRecommendationCommand(params: string, chosenScenario : ChosenScenario): Promise<RecommendationOutputModel> {
    return await showLoaderWhile(
        async () => {
          return await getCustomerRecommendationRequest(params, chosenScenario)
        },
        {
          key: 'getCustomerLifeProjectsCommand',
          value: `API GET - ${libsSharedApiUrls(params).recommendation.compute}`
        }
      );
  };

  async function getCustomerRiskProfileCommand(params: string): Promise<SearchRiskProfiles> {
    return await showLoaderWhile(
        async () => {
          return await getSearchRiskProfilesRequest(params)
        },
        {
          key: 'getCustomerRiskProfileCommand',
          value: `API GET - ${libsSharedApiUrls(params).investorProfile.riskProfile.searchRiskProfile} - ${libsSharedApiUrls(params).investorProfile.profileScale}`
        }
      );
  };

  async function getCustomerFinancialProfileProfileCommand(params: string): Promise<GetFinancialProfiles> {
    return await showLoaderWhile(
        async () => {
          return await getSearchFinancialProfileRequest(params)
        },
        {
          key: 'getCustomerFinancialProfileProfileCommand',
          value: `API GET - ${libsSharedApiUrls(params).investorProfile.financialProfile.searchFinancialProfile} - ${libsSharedApiUrls(params).investorProfile.profileScale}`
        }
      );
  };
  
  async function generateReport(params: string) {
    return await showLoaderWhile(
        async () => {
          return window.open(apiUrls(params).customerFile.reportUrl)
        },
        {
          key: 'generateReport',
          value: `API GET - ${apiUrls(params).customerFile.reportUrl}`
        }
      );
  }

  return {
    getCustomerAppointmentCommand,
    getCustomerInformationCommand,
    getCustomerTaxationCommand,
    getCustomerPensionNeedsCommand,
    getCustomerLegalPensionCommand,
    getCustomerPatrimonyCommand,
    getCustomerLifeProjectsCommand,
    getCustomerRecommendationCommand,
    getCustomerRiskProfileCommand,
    getCustomerFinancialProfileProfileCommand,
    getLifeProjectTotalCommand,
    generateReport
  };
}
