import { globali18n } from 'i18n';
import { CustomerStatus } from 'models/customer-status';
import {
  grey10,
  grey50,
  pending,
  pending_10,
  secondaryaubergine,
  secondaryaubergine_10,
  secondarymoutarde,
  secondarymoutarde_10,
  secondaryturquoise,
  secondaryturquoise_10
} from 'styles/colors';

export interface StatusDataModel {
  style: {
    backgroundColor: string;
    color: string;
  };
  i18n: string;
  authorizeStatut: CustomerStatus[];
}

export const StatutData = {
  [CustomerStatus.Requested]: {
    style: {
      backgroundColor: secondaryaubergine_10,
      color: secondaryaubergine
    },
    i18n: globali18n.status[CustomerStatus.Requested],
    authorizeStatut: [
      CustomerStatus.PendingConfirmation,
      CustomerStatus.Confirmed,
      CustomerStatus.Canceled
    ]
  },
  [CustomerStatus.PendingConfirmation]: {
    style: {
      backgroundColor: pending_10,
      color: pending
    },
    i18n: globali18n.status[CustomerStatus.PendingConfirmation],
    authorizeStatut: [CustomerStatus.Requested, CustomerStatus.Confirmed, CustomerStatus.Canceled]
  },
  [CustomerStatus.Confirmed]: {
    style: {
      backgroundColor: secondarymoutarde_10,
      color: secondarymoutarde
    },
    i18n: globali18n.status[CustomerStatus.Confirmed],
    authorizeStatut: [
      CustomerStatus.Requested,
      CustomerStatus.PendingConfirmation,
      CustomerStatus.Canceled
    ]
  },
  [CustomerStatus.Honored]: {
    style: {
      backgroundColor: secondaryturquoise_10,
      color: secondaryturquoise
    },
    i18n: globali18n.status[CustomerStatus.Honored],
    authorizeStatut: [
      CustomerStatus.Requested,
      CustomerStatus.PendingConfirmation,
      CustomerStatus.Confirmed
    ]
  },
  [CustomerStatus.Canceled]: {
    style: {
      backgroundColor: grey10,
      color: grey50
    },
    i18n: globali18n.status[CustomerStatus.Canceled],
    authorizeStatut: [
      CustomerStatus.Requested,
      CustomerStatus.PendingConfirmation,
      CustomerStatus.Confirmed
    ]
  }
};
