import { Module } from "@ifs/libs";
import { PatrimonyModel } from "@ifs/libs/src/modules/patrimony/models/PatrimonyModel";
import { RealEstatesPaginatedResponseModel } from "@ifs/libs/src/modules/patrimony/models/RealEstateModel";
import { SavingsAccountsPaginated } from "@ifs/libs/src/modules/patrimony/models/SavingsAccountModel";
import { apiUrls } from "@ifs/libs/src/shared/apiUrls";
import { getModuleCompletionRequest } from "@ifs/libs/src/shared/requests/getModuleCompletionRequest";
import axios from "axios";

export async function getCustomerPatrimonyRequest(params: string): Promise<PatrimonyModel | null> {
    // Patrimony is special case
    // Backend service now return always an empty table for an uncomplete patrimony of the user
    // To be sure, that empty table is due to the uncompletion of the module. We need to check also the completion value of patrimony module.    
    const patrimonyModuleCompletion = await getModuleCompletionRequest(params, Module.Patrimony);
    const isPatrimonyCompleted = patrimonyModuleCompletion[0].isCompleted;

    const responseRealEstates = await axios.get<RealEstatesPaginatedResponseModel>(apiUrls(params).patrimony.getRealsEstates);
    const responseSavingsAccounts = await axios.get<SavingsAccountsPaginated>(apiUrls(params).patrimony.getSavingsAccounts);

    if(!isPatrimonyCompleted) return null;
    

    const realEstates = responseRealEstates.data!.results;
    const savingsAccounts = responseSavingsAccounts.data!.results;
    
    return {savingsAccounts, realEstates};
}
